import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { StatusEnum } from './../../enums/status';
import { action, makeObservable, observable } from 'mobx';
import { AdvertisersApi } from '../../apis/advertisers.api';
import { AdvertiserModel, ICategory } from '../../models/advertiser.model';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { AdvertiserCrudStore } from './advertiser-crud.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { IPerformanceOptions, PerformancesColumns } from '../../hooks/apis/use-reports-list';
import { PerformancePayload, PerformanceModel } from '../../models/performance.model';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { filterPrototypesByProp } from '../../components/filters';
import { FilterModel } from '@monorepo/controlled/src/models/filter.model';
import { capitalCase } from 'change-case';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { StatusFilterPrototype } from '../../components/filters/status.filter';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { Row } from '@tanstack/react-table';

export enum AdvertiserFiltersStoreKeys {
	Advertisers = 'advertisers',
	Accounts = 'accountsadvertisers',
	Clicks = 'clicksadvertisers',
}

export class AdvertiserStore {
	crud = new AdvertiserCrudStore();
	list = new BaseListStore<AdvertiserModel>({
		listFunc: AdvertisersApi.list,
		model: AdvertiserModel,
	});
	reports = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: AdvertisersApi.reports,
		model: PerformancePayload,
	});
	metrics = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: AdvertisersApi.metrics,
		model: PerformancePayload,
	});
	categories = new HttpStore<IRequestOptions, ICategory[]>({
		httpFunc: AdvertisersApi.categories,
	});
	segments: Set<ISegment<AdvertiserModel, PerformanceModel, PerformancesColumns>>;

	sortedRows: Row<AdvertiserModel>[] = [];

	settingsStore: SettingsStore;

	constructor(settingsStore: SettingsStore) {
		makeObservable(this, {
			crud: observable,
			list: observable,
			reports: observable,
			segments: observable,
			categories: observable,
			setSegments: action.bound, // https://mobx.js.org/actions.html#actionbound prevents cannot set property of undefined
		});

		this.segments = new Set<ISegment<AdvertiserModel, PerformanceModel, PerformancesColumns>>([]);
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		// set filters for all campaigns pages
		const defaultFilters = new Map([
			[
				1,
				new FilterModel({
					index: 1,
					label: `Status is ${capitalCase(StatusEnum.ACTIVE)}`,
					value: StatusEnum.ACTIVE,
					prototype: StatusFilterPrototype,
				}),
			],
		]);

		Object.values(AdvertiserFiltersStoreKeys).map((key: AdvertiserFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const advertiserFilterByKey = new FilterStore({
				key,
				currentFilters: defaultFilters,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, advertiserFilterByKey);
		});
	}

	getListStore() {
		return this.list;
	}

	getCrud() {
		return this.crud;
	}

	getReportsStore() {
		return this.reports;
	}

	getMetricsStore() {
		return this.metrics;
	}
	getCategoriesStore() {
		return this.categories;
	}

	setSegments(segments: Set<ISegment<AdvertiserModel, PerformanceModel, PerformancesColumns>>): void {
		this.segments = segments;
	}

	getSegments(): Set<ISegment<AdvertiserModel, PerformanceModel, PerformancesColumns>> {
		return this.segments;
	}

	getSortedRows(): Row<AdvertiserModel>[] {
		return this.sortedRows;
	}

	setSortedRows(sortedRows: Row<AdvertiserModel>[]): void {
		this.sortedRows = sortedRows;
	}
}
