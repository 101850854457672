import { makeAutoObservable } from 'mobx';

export enum OptimizationAlgoVersionEnum {
	TargetRoas = 'TARGET_ROAS',
	TargetCPA = 'TARGET_CPA',
}
export interface OptimizerConfigurationInterface {
	algoVersion?: OptimizationAlgoVersionEnum;
	targetRoi?: number;
	targetCpa?: number;
	learningBudgetPerSource?: number;
	learningClicksPerHqSource?: number;
	learningClicksPerRqSource?: number;
	maximumLearningBid?: number;
	maximumOptimizingBid?: number;
	campaignLevelOptimization?: boolean;
	statisticsWindow1?: number;
	statisticsWindow2?: number;
	maxFirstConversions?: number;
	minSecondFirstRate?: number;
	minRoi?: number;
	maxCpa?: number;
}

export class OptimizerConfiguration implements OptimizerConfigurationInterface {
	algoVersion?: OptimizationAlgoVersionEnum;
	targetRoi?: number;
	targetCpa?: number;
	learningBudgetPerSource?: number;
	learningClicksPerHqSource?: number;
	learningClicksPerRqSource?: number;
	maximumLearningBid?: number;
	maximumOptimizingBid?: number;
	campaignLevelOptimization?: boolean;
	statisticsWindow1?: number;
	statisticsWindow2?: number;
	maxFirstConversions?: number;
	minSecondFirstRate?: number;
	minRoi?: number;
	maxCpa?: number;

	optimizerConfigurationDefaults: OptimizerConfigurationInterface = {
		algoVersion: OptimizationAlgoVersionEnum.TargetCPA,
		learningBudgetPerSource: 10,
		learningClicksPerHqSource: 58,
		learningClicksPerRqSource: 80,
		maximumOptimizingBid: 3,
		campaignLevelOptimization: true,
		statisticsWindow1: 7,
		statisticsWindow2: 30,
		maxFirstConversions: 0,
		minSecondFirstRate: 0,
	};
	constructor(config?: OptimizerConfigurationInterface) {
		this.algoVersion = config?.algoVersion || OptimizationAlgoVersionEnum.TargetCPA;
		this.targetRoi = config?.targetRoi;
		this.targetCpa = config?.targetCpa;
		this.learningBudgetPerSource = config?.learningBudgetPerSource;
		this.learningClicksPerHqSource = config?.learningClicksPerHqSource;
		this.learningClicksPerRqSource = config?.learningClicksPerRqSource;
		this.maximumLearningBid = config?.maximumLearningBid;
		this.maximumOptimizingBid = config?.maximumOptimizingBid;
		this.campaignLevelOptimization = config?.campaignLevelOptimization;
		this.statisticsWindow1 = config?.statisticsWindow1;
		this.statisticsWindow2 = config?.statisticsWindow2;
		this.maxFirstConversions = config?.maxFirstConversions;
		this.minSecondFirstRate = config?.minSecondFirstRate;
		this.minRoi = config?.minRoi;
		this.maxCpa = config?.maxCpa;
		makeAutoObservable(this);
	}

	getData(): OptimizerConfigurationInterface {
		return {
			algoVersion: this.algoVersion,
			targetRoi: this.targetRoi,
			targetCpa: this.targetCpa,
			learningBudgetPerSource: this.learningBudgetPerSource,
			learningClicksPerHqSource: this.learningClicksPerHqSource,
			learningClicksPerRqSource: this.learningClicksPerRqSource,
			maximumLearningBid: this.maximumLearningBid,
			maximumOptimizingBid: this.maximumOptimizingBid,
			campaignLevelOptimization: this.campaignLevelOptimization,
			statisticsWindow1: this.statisticsWindow1,
			statisticsWindow2: this.statisticsWindow2,
			maxFirstConversions: this.maxFirstConversions,
			minSecondFirstRate: this.minSecondFirstRate,
			minRoi: this.minRoi,
			maxCpa: this.maxCpa,
		};
	}

	resetToDefaults() {
		this.learningBudgetPerSource = undefined;
		this.learningClicksPerHqSource = undefined;
		this.learningClicksPerRqSource = undefined;
		this.maximumLearningBid = undefined;
		this.maximumOptimizingBid = undefined;
		this.campaignLevelOptimization = undefined;
		this.statisticsWindow1 = undefined;
		this.statisticsWindow2 = undefined;
		this.maxFirstConversions = undefined;
		this.minSecondFirstRate = undefined;
		this.minRoi = undefined;
		this.maxCpa = undefined;
	}
	getAlgoVersion() {
		return this.algoVersion;
	}
	getTargetRoi() {
		return this.targetRoi;
	}
	getTargetCpa() {
		return this.targetCpa;
	}
	getLearningBudgetPerSource() {
		return this.learningBudgetPerSource;
	}
	getLearningClicksPerHqSource() {
		return this.learningClicksPerHqSource;
	}
	getLearningClicksPerRqSource() {
		return this.learningClicksPerRqSource;
	}
	getMaximumLearningBid() {
		return this.maximumLearningBid;
	}
	getMaximumOptimizingBid() {
		return this.maximumOptimizingBid;
	}
	getCampaignLevelOptimization() {
		return this.campaignLevelOptimization;
	}
	getStatisticsWindow1() {
		return this.statisticsWindow1;
	}
	getStatisticsWindow2() {
		return this.statisticsWindow2;
	}
	getMaxFirstConversions() {
		return this.maxFirstConversions;
	}
	getMinSecondFirstRate() {
		return this.minSecondFirstRate;
	}
	getMinRoi() {
		return this.minRoi;
	}
	getMaxCpa() {
		return this.maxCpa;
	}
	setAlgoVersion(algoVersion?: OptimizationAlgoVersionEnum) {
		this.algoVersion = algoVersion;
	}
	setTargetRoi(targetRoi: number) {
		this.targetRoi = targetRoi;
	}
	setTargetCpa(targetCpa: number) {
		this.targetCpa = targetCpa;
	}
	setLearningBudgetPerSource(learningBudgetPerSource?: number) {
		this.learningBudgetPerSource = learningBudgetPerSource;
	}
	setLearningClicksPerHqSource(learningClicksPerHqSource?: number) {
		this.learningClicksPerHqSource = learningClicksPerHqSource;
	}
	setLearningClicksPerRqSource(learningClicksPerRqSource?: number) {
		this.learningClicksPerRqSource = learningClicksPerRqSource;
	}
	setMaximumLearningBid(maximumLearningBid?: number) {
		this.maximumLearningBid = maximumLearningBid;
	}
	setMaximumOptimizingBid(maximumOptimizingBid?: number) {
		this.maximumOptimizingBid = maximumOptimizingBid;
	}
	setCampaignLevelOptimization(campaignLevelOptimization?: boolean) {
		this.campaignLevelOptimization = campaignLevelOptimization;
	}
	setStatisticsWindow1(statisticsWindow1?: number) {
		this.statisticsWindow1 = statisticsWindow1;
	}
	setStatisticsWindow2(statisticsWindow2?: number) {
		this.statisticsWindow2 = statisticsWindow2;
	}
	setMaxFirstConversions(maxFirstConversions?: number) {
		this.maxFirstConversions = maxFirstConversions;
	}
	setMinSecondFirstRate(minSecondFirstRate?: number) {
		this.minSecondFirstRate = minSecondFirstRate;
	}
	setMinRoi(minRoi?: number) {
		this.minRoi = minRoi;
	}
	setMaxCpa(maxCpa?: number) {
		this.maxCpa = maxCpa;
	}

	setOptimizationOptimizerValue(optimizationOptimizerValue: number) {
		if (this.algoVersion === OptimizationAlgoVersionEnum.TargetCPA) {
			this.setMinRoi(optimizationOptimizerValue);
		} else {
			this.setMaxCpa(optimizationOptimizerValue);
		}
	}

	getOptimizationOptimizerValue() {
		if (this.algoVersion === OptimizationAlgoVersionEnum.TargetCPA) {
			return this.getMinRoi();
		} else {
			return this.getMaxCpa();
		}
	}

	getDefaults = () => this.optimizerConfigurationDefaults;
	setDefaults = (defaults: OptimizerConfigurationInterface) => {
		this.optimizerConfigurationDefaults = defaults;
	};

	isDefaults(): boolean {
		if (
			this.learningBudgetPerSource ||
			this.learningClicksPerHqSource ||
			this.learningClicksPerRqSource ||
			this.maximumOptimizingBid ||
			this.campaignLevelOptimization ||
			this.statisticsWindow1 ||
			this.statisticsWindow2 ||
			this.minRoi ||
			this.maxCpa
		) {
			return false;
		}

		return true;
	}
}
