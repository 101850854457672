import { ReviewCampaignsApi } from '../../../apis/review-campaigns.api';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { ErrorPayloadType, HttpError, IHttpError } from '../../../models/http-error.model';
import { IReviewCampaignsCreateForm, IReviewCampaignsEditForm, ReviewCampaignsModel } from '../../../models/review-campaigns.model';

export class ReviewCampaignsCrudStore extends BaseCrudStore<
	ReviewCampaignsModel,
	IReviewCampaignsCreateForm,
	IReviewCampaignsEditForm,
	HttpError
> {
	constructor() {
		super({
			apiLayer: ReviewCampaignsApi,
			model: ReviewCampaignsModel,
			errorModel: HttpError,
		});
	}

	/**
	 * Must call isValidCampaign before calling this function
	 * @returns
	 */
	public getCreateFormData(): IReviewCampaignsCreateForm {
		const reviewCampaigns = this.getData();

		return {
			rowId: reviewCampaigns.getId(),
		};
	}

	/**
	 * Must call isValidCampaign before calling this function
	 * @returns
	 */
	public getEditFormData(): IReviewCampaignsEditForm {
		const reviewCampaigns = this.getData();

		return {
			rowId: reviewCampaigns.getId(),
		};
	}

	public isValid(): boolean {
		this.formStore.reset();

		return this.formStore.getIsValid();
	}

	public onError(error: IHttpError) {
		if (error.errorPayload?.type === ErrorPayloadType.Properties) {
			Object.keys(error.errorPayload?.data).forEach(key => {
				this.formStore.addError(new FormError(key, error.errorPayload?.data[key] as string));
			});
		}
	}
}
