import dayjs from 'dayjs';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import { getFilterObject, IPerformanceOptions, preparePerformanceRequest } from '../apis/use-reports-list';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { useLocation } from 'react-router-dom';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { useEffect } from 'react';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { IPerformancePayload } from '../../models/performance.model';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useDebug } from './use-debug';
import { IQueryParams } from '@monorepo/tools/src/lib/interfaces/url';

export const useMetricsGet = (
	httpStore: HttpStore<IPerformanceOptions, IPerformancePayload>,
	options: IPerformanceOptions = {},
	hookOptions: BetweenPagesStateProps = {}
) => {
	const isBlockRequest = hookOptions.isBlockRequest || false;
	const location = useLocation();
	const { startDate, endDate } = useDates();
	const { tableStore } = usePageSettings();
	const primaryFromDate = dayjs(startDate).format('YYYY-MM-DD');
	const primaryToDate = dayjs(endDate).format('YYYY-MM-DD');
	const didMount = useDidMount(); // fetch data after did mount, solve problem of navigating between performance pages before fetch is resolved
	const { currentRouteWithoutSpecialChars } = useRoute();
	const orderByColumns = options.sortBys ? options.sortBys[0].column : 'date';
	const isDebug = useDebug();

	useEffect(() => {
		fetchMetrics();
		return () => {
			if (!isBlockRequest) {
				// in case of block request no request happend so no need to abort
				httpStore.abort();
			}
		};
	}, [location, primaryFromDate, primaryToDate, didMount, orderByColumns]);

	const fetchMetrics = () => {
		httpStore.reset();
		const { periodStart, periodEnd } = options;
		if (isBlockRequest || !didMount) {
			return;
		}

		const filters = tableStore?.getFiltersStore(currentRouteWithoutSpecialChars)?.getCurrentFilters();
		const filtersArray = getFilterObject(filters || new Map());

		const params = preparePerformanceRequest({
			...options,
			periodStart: periodStart || primaryFromDate,
			periodEnd: periodEnd || primaryToDate,
			filters: filtersArray,
		});

		const extraQueryParams: IQueryParams = {};
		if (isDebug) {
			extraQueryParams.m = JSON.stringify(params);
		}

		return httpStore.fetch(params, { queryParams: extraQueryParams });
	};

	return { metricsError: httpStore.getHttpError(), isLoading: httpStore.getIsLoading(), fetchMetrics };
};
