import { ElementType } from 'react';
import { IButton, SecondaryLink, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { route } from '@monorepo/tools/src/lib/types/url';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { DeleteModal } from '../../components/modals/delete-modal';
import { IMFPStore } from '../../stores';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { PerformanceModel } from '../../models/performance.model';
import { PerformancesColumns } from '../apis/use-reports-list';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import styles from './default.module.scss';
import { Id } from '@monorepo/controlled/src/stores/base-crud.store';
import { Row } from '@tanstack/react-table';
import { HttpError } from '../../models/http-error.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';

export interface IUseEntityActions<T> {
	onEdit?: (id: id) => string;
	createRoute?: route;
	availableFilters?: IFilterProto[];
	currentSegments?: Set<ISegment<T, PerformanceModel, PerformancesColumns>>;
	debugProps?: IDebugProps;
}

export interface ITableActions<T> {
	rows: Row<T>[];
}

export interface IEntityActions<T> {
	entity: T;
}

interface IDefaultActionsProps<T> {
	onDelete?: (id: id) => Promise<void | HttpError>;
	onEditRoute?: (id: id) => string;
	entities: T[];
}

interface IDefaultActionsReturnProps {
	EditAction: ElementType;
	DeleteAction: ElementType;
	buttonClassName?: string;
}

const theme = getTheme();

export function useDefaultActions<T extends Id>(props: IDefaultActionsProps<T>): IDefaultActionsReturnProps {
	const { onDelete, onEditRoute, entities } = props;
	const { modalsStore } = useStores<IMFPStore>();
	const { dispatchLog } = useLogs();
	const navigate = useNavigateParams();

	const _onDelete = () => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Delete'}
					onAction={() => {
						const deleteId = entities[0]?.getId();
						if (!onDelete || !deleteId) {
							return;
						}
						onDelete(deleteId).then((error?: HttpError | void) => {
							if (error) {
								dispatchLog({
									msg: `Delete ${entities[0]?.getId()} Failed`,
									type: DispatchLogsTypes.Error,
								});
								return;
							}
							dispatchLog({
								msg: `${entities[0]?.getId()} ${'Deleted'}`,
								type: DispatchLogsTypes.Success,
							});
							setTimeout(() => {
								navigate(0);
							}, 500);
						});
					}}>
					<DeleteModal />
				</ActionModal>
			),
		});
	};

	const EditAction = (props: IButton<HTMLAnchorElement>) => {
		const { textColor = theme.actionLinerIconTextColor, iconColor = theme.actionLinerIconColor, className } = props;
		const editId = entities[0]?.getId();
		if (!onEditRoute || !editId) {
			return null;
		}
		return (
			<SecondaryLink
				disabled={entities.length !== 1}
				text={true}
				icon={'edit-02'}
				font={IconFonts.Outlined}
				to={onEditRoute(editId)}
				textColor={textColor}
				iconColor={iconColor}
				className={className}
				{...props}>
				Edit
			</SecondaryLink>
		);
	};

	const DeleteAction = (props: IButton<HTMLButtonElement>) => {
		const { textColor = theme.actionLinerIconTextColor, iconColor = theme.actionLinerIconColor, className } = props;
		if (!onDelete) {
			return null;
		}
		return (
			<SecondaryText
				font={IconFonts.Outlined}
				icon={'trash-02'}
				onClick={_onDelete}
				textColor={textColor}
				className={className}
				iconColor={iconColor}
				{...props}>
				Delete
			</SecondaryText>
		);
	};

	return {
		EditAction,
		DeleteAction,
		buttonClassName: styles.quickAction,
	};
}
