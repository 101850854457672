import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './page-layout.module.scss';
import { MainNavbar } from '../../main-navbar/main-navbar';
import { IMFPStore } from '../../../stores';
import { ControlledSidebar } from '../../sidebar/sidebar';

interface IPageLayout {
	isSidebar?: boolean;
}

export const PageLayout = observer((props: IPageLayout) => {
	const { sidebarStore } = useStores<IMFPStore>();
	const { isSidebar = true } = props;

	return (
		<main className={`${styles.main} ${isSidebar ? styles.sidebar : ''}`}>
			{isSidebar ? <ControlledSidebar /> : null}
			<div className={sidebarStore.getIsSideBarOpen() ? styles.minimize : styles.maximize}>
				<MainNavbar />
				<Outlet />
			</div>
		</main>
	);
});
