import { InputDesc, InputInfo, InputItem, InputTitle } from '@monorepo/base/src/components/form/input-item/input-item';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { IMFPStore } from '../../../../stores';
import styles from '../campaign-form.module.scss';
import { SpaceBetweenInputControls } from '../campaign.form';
import { Fragment, useEffect } from 'react';
import { Checkbox } from '@monorepo/base/src/components/form/checkbox/checkbox';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { CampaignDomIds } from '../../../../hooks/toc/campaign.toc';
import { BudgetInput } from '../campaign-common.form';
import { Directions, PrimaryText } from '@monorepo/base/src/components/buttons/buttons';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { OptimizationAlgoVersionEnum, OptimizerConfigurationInterface } from '../../../../models/strategies/advanced-bidding';
import { CampaignsApi } from '../../../../apis/campaigns.api';

interface IResetToDefaultButtonProps {
	value?: number;
	setterToDefault?: () => void;
}

const ResetToDefaultButton = ({ value, setterToDefault }: IResetToDefaultButtonProps) => {
	return (
		<Fragment>
			<div>&nbsp;</div>
			<div>&nbsp;</div>
			<PrimaryText
				disabled={!value || !setterToDefault}
				width="min-content"
				icon={'refresh-ccw-01'}
				onClick={() => setterToDefault && setterToDefault()}
			/>
		</Fragment>
	);
};

export const AdvancedBidding = observer(() => {
	const { campaignStore } = useStores<IMFPStore>();
	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();
	const defaultBid = campaign?.getDefaultBid();
	const optimizerConfiguration = campaignCrudStore.getData().getOptimizerConfiguration();
	const optimizerConfigurationDefaults: OptimizerConfigurationInterface = optimizerConfiguration?.getDefaults() || {};
	const isRoasOptimized = optimizerConfiguration?.getAlgoVersion() === OptimizationAlgoVersionEnum.TargetRoas;
	const optimizationTarget = isRoasOptimized ? optimizerConfiguration.getTargetRoi() : optimizerConfiguration?.getTargetCpa();
	const formStore = campaignCrudStore.getFormStore();

	useEffect(() => {
		CampaignsApi.optimizationDefaults().then(optimizerConfiguration?.setDefaults);
	}, []);

	if (!campaign) {
		return <Fragment />;
	}

	return (
		<div>
			<InputItem maxWidth={'944px'}>
				<Flex alignItems={'center'} justifyContent={'flex-start'}>
					<InputTitle>Advanced</InputTitle>
					<Checkbox
						id={CampaignDomIds.AdvancedBiddingCheckbox}
						className={styles.checkbox}
						onChange={() => {
							const nextState = !campaign.getOptimizationOn();
							campaign.setOptimizationOn(nextState);
						}}
						checked={campaign.getOptimizationOn()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'advanced_bidding_available')] }}
					/>
				</Flex>
			</InputItem>
			<SpaceBetweenInputControls />
			<div style={{ height: campaign.getOptimizationOn() ? 'min-content' : '0px' }} className={`${styles.collapsed}`}>
				<InputItem maxWidth={'620px'}>
					<PrimaryText
						disabled={campaign.getOptimizerConfiguration()?.isDefaults()}
						width="min-content"
						icon={'refresh-ccw-01'}
						onClick={() => campaign.getOptimizerConfiguration()?.resetToDefaults()}>
						Reset all Params To defaults
					</PrimaryText>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>Bid</InputTitle>
					</InputInfo>
					<BudgetInput
						value={campaign?.getDefaultBid()?.toString()}
						id={CampaignDomIds.DefaultBid}
						error={formStore.getErrors().get(CampaignDomIds.DefaultBid)?.getMsg()}
						setValue={value => {
							campaign?.setDefaultBid(parseFloat(value));
							formStore.clearError(CampaignDomIds.DefaultBid);
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'defaultBid')] }}
					/>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.sectionTitle}>
						<InputDesc>Learning</InputDesc>
					</InputInfo>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>Learning Budget Per Source</InputTitle>
					</InputInfo>
					<BudgetInput
						value={optimizerConfiguration?.getLearningBudgetPerSource()?.toString()}
						placeholder={optimizerConfigurationDefaults.learningBudgetPerSource?.toString()}
						id={CampaignDomIds.LearningBudgetPerSource}
						error={formStore.getErrors().get(CampaignDomIds.LearningBudgetPerSource)?.getMsg()}
						setValue={value => {
							optimizerConfiguration?.setLearningBudgetPerSource(parseFloat(value));
							formStore.clearError(CampaignDomIds.LearningBudgetPerSource);
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'learning-budget-for-source-debug')] }}
					/>
					<ResetToDefaultButton
						setterToDefault={() => {
							optimizerConfiguration?.setLearningBudgetPerSource();
							formStore.clearError(CampaignDomIds.LearningBudgetPerSource);
						}}
						value={optimizerConfiguration?.getLearningBudgetPerSource()}
					/>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>Learning Clicks Per HQ Source</InputTitle>
					</InputInfo>
					<BudgetInput
						before={' '}
						after={'Clicks'}
						step={1}
						placeholder={optimizerConfigurationDefaults.learningClicksPerHqSource?.toString()}
						value={optimizerConfiguration?.getLearningClicksPerHqSource()?.toString()}
						id={CampaignDomIds.LearningClicksPerHqSource}
						error={formStore.getErrors().get(CampaignDomIds.LearningClicksPerHqSource)?.getMsg()}
						setValue={value => {
							optimizerConfiguration?.setLearningClicksPerHqSource(Math.floor(parseFloat(value)));
							formStore.clearError(CampaignDomIds.LearningClicksPerHqSource);
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'learning-clicks-per-hq-source-debug')] }}
					/>
					<ResetToDefaultButton
						setterToDefault={() => {
							optimizerConfiguration?.setLearningClicksPerHqSource();
							formStore.clearError(CampaignDomIds.LearningClicksPerHqSource);
						}}
						value={optimizerConfiguration?.getLearningClicksPerHqSource()}
					/>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>Learning Clicks Per RQ Source</InputTitle>
					</InputInfo>
					<BudgetInput
						before={' '}
						placeholder={optimizerConfigurationDefaults.learningClicksPerRqSource?.toString()}
						after={'Clicks'}
						step={1}
						value={optimizerConfiguration?.getLearningClicksPerRqSource()?.toString()}
						id={CampaignDomIds.LearningClicksPerRqSource}
						error={formStore.getErrors().get(CampaignDomIds.LearningClicksPerRqSource)?.getMsg()}
						setValue={value => {
							optimizerConfiguration?.setLearningClicksPerRqSource(Math.floor(parseFloat(value)));
							formStore.clearError(CampaignDomIds.LearningClicksPerRqSource);
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'learning-clicks-per-rq-source-debug')] }}
					/>
					<ResetToDefaultButton
						setterToDefault={() => {
							optimizerConfiguration?.setLearningClicksPerRqSource();
							formStore.clearError(CampaignDomIds.LearningClicksPerRqSource);
						}}
						value={optimizerConfiguration?.getLearningClicksPerRqSource()}
					/>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>Maximum Learning Bid</InputTitle>
					</InputInfo>
					<BudgetInput
						value={optimizerConfiguration?.getMaximumLearningBid()?.toString()}
						placeholder={defaultBid ? (defaultBid * 1.3).toFixed(2) : undefined}
						id={CampaignDomIds.MaximumLearningBid}
						error={formStore.getErrors().get(CampaignDomIds.MaximumLearningBid)?.getMsg()}
						setValue={value => {
							optimizerConfiguration?.setMaximumLearningBid(parseFloat(value));
							formStore.clearError(CampaignDomIds.MaximumLearningBid);
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'maximum-learning-bid-debug')] }}
					/>
					<ResetToDefaultButton
						setterToDefault={() => {
							optimizerConfiguration?.setMaximumLearningBid();
							formStore.clearError(CampaignDomIds.MaximumLearningBid);
						}}
						value={optimizerConfiguration?.getMaximumLearningBid()}
					/>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.sectionTitle}>
						<InputDesc>Optimization</InputDesc>
					</InputInfo>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>Maximum Optimizing Bid</InputTitle>
					</InputInfo>
					<BudgetInput
						value={optimizerConfiguration?.getMaximumOptimizingBid()?.toString()}
						placeholder={optimizerConfigurationDefaults.maximumOptimizingBid?.toString()}
						id={CampaignDomIds.MaximumOptimizingBid}
						error={formStore.getErrors().get(CampaignDomIds.MaximumOptimizingBid)?.getMsg()}
						setValue={value => {
							optimizerConfiguration?.setMaximumOptimizingBid(parseFloat(value));
							formStore.clearError(CampaignDomIds.MaximumOptimizingBid);
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign-maximum-optimizing-bid-debug')] }}
					/>
					<ResetToDefaultButton
						setterToDefault={() => {
							optimizerConfiguration?.setMaximumOptimizingBid();
							formStore.clearError(CampaignDomIds.MaximumOptimizingBid);
						}}
						value={optimizerConfiguration?.getMaximumOptimizingBid()}
					/>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>Campaign Level Optimization</InputTitle>
					</InputInfo>
					<Toggle
						label={''}
						labelDirection={Directions.FromRight}
						onChange={status => optimizerConfiguration?.setCampaignLevelOptimization(status)}
						defaultValue={optimizerConfiguration?.getCampaignLevelOptimization()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign-campaign-level-optimization-debug')] }}
					/>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>Statistics Window - Primary</InputTitle>
					</InputInfo>
					<BudgetInput
						before={' '}
						after={'Days'}
						value={optimizerConfiguration?.getStatisticsWindow1()?.toString()}
						placeholder={optimizerConfigurationDefaults.statisticsWindow1?.toString()}
						id={CampaignDomIds.StatisticsWindow1}
						error={formStore.getErrors().get(CampaignDomIds.StatisticsWindow1)?.getMsg()}
						setValue={value => {
							optimizerConfiguration?.setStatisticsWindow1(parseFloat(value));
							formStore.clearError(CampaignDomIds.StatisticsWindow1);
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign-statistics-window-1-debug')] }}
					/>
					<ResetToDefaultButton
						setterToDefault={() => {
							optimizerConfiguration?.setStatisticsWindow1();
							formStore.clearError(CampaignDomIds.StatisticsWindow1);
						}}
						value={optimizerConfiguration?.getStatisticsWindow1()}
					/>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>Statistics Window - Secondary</InputTitle>
					</InputInfo>
					<BudgetInput
						before={' '}
						after={'Days'}
						value={optimizerConfiguration?.getStatisticsWindow2()?.toString()}
						placeholder={optimizerConfigurationDefaults.statisticsWindow2?.toString()}
						id={CampaignDomIds.StatisticsWindow2}
						error={formStore.getErrors().get(CampaignDomIds.StatisticsWindow2)?.getMsg()}
						setValue={value => {
							optimizerConfiguration?.setStatisticsWindow2(parseFloat(value));
							formStore.clearError(CampaignDomIds.StatisticsWindow2);
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign-statistics-window-2-debug')] }}
					/>
					<ResetToDefaultButton
						setterToDefault={() => {
							optimizerConfiguration?.setStatisticsWindow2();
							formStore.clearError(CampaignDomIds.StatisticsWindow2);
						}}
						value={optimizerConfiguration?.getStatisticsWindow2()}
					/>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>{isRoasOptimized ? 'Min ROI' : 'Max CPA'}</InputTitle>
					</InputInfo>
					<BudgetInput
						before={isRoasOptimized ? '%' : '$'}
						after={isRoasOptimized ? ' ' : 'USD'}
						value={(isRoasOptimized ? optimizerConfiguration?.getMinRoi() : optimizerConfiguration?.getMaxCpa())?.toString()}
						placeholder={
							optimizationTarget ? (isRoasOptimized ? optimizationTarget / 3 : optimizationTarget * 3).toFixed(2) : undefined
						}
						id={CampaignDomIds.OptimizationModeOptimizerValue}
						error={formStore.getErrors().get(CampaignDomIds.OptimizationModeOptimizerValue)?.getMsg()}
						setValue={value => {
							if (isRoasOptimized) {
								optimizerConfiguration?.setMinRoi(parseFloat(value));
							} else {
								optimizerConfiguration?.setMaxCpa(parseFloat(value));
							}
							formStore.clearError(CampaignDomIds.OptimizationModeOptimizerValue);
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign-optimization-mode-optimizer-value-debug')] }}
					/>
					<ResetToDefaultButton
						setterToDefault={() => {
							optimizerConfiguration?.setMinRoi();
							optimizerConfiguration?.setMaxCpa();
							formStore.clearError(CampaignDomIds.OptimizationModeOptimizerValue);
						}}
						value={isRoasOptimized ? optimizerConfiguration?.getMinRoi() : optimizerConfiguration?.getMaxCpa()}
					/>
				</InputItem>
				<SpaceBetweenInputControls />
				{/* <InputItem maxWidth={'620px'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>Max First Conversions</InputTitle>
					</InputInfo>
					<BudgetInput
						value={optimizerConfiguration?.getMaxFirstConversions()?.toString()}
						id={CampaignDomIds.MaxFirstConversions}
						error={formStore.getErrors().get(CampaignDomIds.MaxFirstConversions)?.getMsg()}
						setValue={value => {
							optimizerConfiguration?.setMaxFirstConversions(parseFloat(value));
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign-max-first-conversions-debug')] }}
					/>
					<ResetToDefaultButton
						defaultValue={optimizerConfigurationDefaults.maxFirstConversions}
						setterToDefault={value => optimizerConfiguration?.setMaxFirstConversions(value)}
						value={optimizerConfiguration?.getMaxFirstConversions()}
					/>
				</InputItem>
				<SpaceBetweenInputControls />
				<InputItem maxWidth={'620px'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>Min Second First Rate</InputTitle>
					</InputInfo>
					<BudgetInput
						value={optimizerConfiguration?.getMinSecondFirstRate()?.toString()}
						id={CampaignDomIds.MinSecondFirstRate}
						error={formStore.getErrors().get(CampaignDomIds.MinSecondFirstRate)?.getMsg()}
						setValue={value => {
							optimizerConfiguration?.setMinSecondFirstRate(parseFloat(value));
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign-min-second-first-rate-debug')] }}
					/>
					<ResetToDefaultButton
						defaultValue={optimizerConfigurationDefaults.minSecondFirstRate}
						setterToDefault={value => optimizerConfiguration?.setMinSecondFirstRate(value)}
						value={optimizerConfiguration?.getMinSecondFirstRate()}
					/>
				</InputItem> */}
				<SpaceBetweenInputControls />
			</div>
		</div>
	);
});
