import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AccountModel } from '../../models/account.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { BreadcrumbMenu } from '@monorepo/base/src/components/breadcrumb-menu/breadcrumb-menu';
import styles from './main-breadcrumbs.module.scss';
import { IMFPStore } from '../../stores';
import { useAccountGet } from '../../hooks/apis/use-account-get';
import { SkeletonBreadCrumb } from './main-breadcrumbs';
import { Select } from '@monorepo/base/src/components/select/select';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { AdvertiserBreadcrumb } from './advertiser-page.breadcrumbs';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

interface IAccountBreadcrumb {
	account?: AccountModel;
	isActive?: boolean;
}

const All = 'All';

export const AccountBreadcrumb = observer((props: IAccountBreadcrumb) => {
	const { account, isActive = false } = props;
	const { accountStore } = useStores<IMFPStore>();
	const accountListStore = accountStore.getListStore();
	const isLoadingAccounts = accountListStore.getIsLoading();
	const isError = accountListStore.getError();
	const accounts = accountListStore.getList();
	const accountName = account?.getName();

	const navigate = useNavigateParams();

	const onListItemAccount = (accountName: string, e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();

		if (accountName === All) {
			navigate(`${routes.accounts.index()}`);
			return;
		}
		const clickedAccount = accounts.find(_account => _account.getName() === accountName);
		if (!clickedAccount) {
			// TODO - log
			return null;
		}
		const clickedAccountId = clickedAccount.getId();
		if (!clickedAccountId) {
			// TODO - log
			return null;
		}
		navigate(`${routes.accounts.advertisers(clickedAccountId)}`);
	};

	const onBreadcrumbLink = () => {
		const accountId = account?.getId();
		if (accountId) {
			navigate(`${routes.accounts.advertisers(accountId)}`);
		}
	};

	return (
		<BreadcrumbMenu
			label={'Account'}
			title={<span className={isActive ? styles.active : ''}>{accountName || All}</span>}
			isArrow={false}
			navigateToModel={onBreadcrumbLink}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'account_breadcrumbs')] }}
			isBarLoader={isLoadingAccounts}
			isLoading={isLoadingAccounts && accounts.length === 0}
			isError={Boolean(isError)}>
			<div className={styles.menuWrapper}>
				{accounts.length > 0 ? (
					<Select
						placeholder={'Search Account'}
						listItemClassName={styles.selectItem}
						values={accounts.length > 0 ? [All, ...accounts.map(account => account.getName())] : []}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'account_breadcrumbs_select')] }}
						onClick={onListItemAccount}
						disabled={isLoadingAccounts}
						defaultValue={accountName}
					/>
				) : null}
			</div>
		</BreadcrumbMenu>
	);
});

interface IAccountPageBreadcrumbs {
	accountId: id;
}

export const AccountPageBreadcrumbs = observer((props: IAccountPageBreadcrumbs) => {
	const { accountId } = props;
	const { accountStore } = useStores<IMFPStore>();
	const account = accountStore.getCrud().getData();

	useAccountGet({ id: accountId });

	return (
		<div className={styles.wrapper}>
			{account?.getName() ? <AccountBreadcrumb account={account} isActive={true} /> : <SkeletonBreadCrumb />}
			{account?.getName() ? <AdvertiserBreadcrumb account={account} isActive={true} /> : <SkeletonBreadCrumb />}
		</div>
	);
});
