import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useState, useEffect } from 'react';
import { IMFPStore } from '../../stores';
import { UserModel } from '../../models/user.model';
import { PermissionActions } from '../../enums/permissions';

// export const PERMISSIONS_NAMESPACE = 'mfa';
export type permissionsDomain = `mfa/${number | string}`;
export type validateGuard = (user: UserModel) => boolean;

// export const validateAccount: validateGuard = (user, options) => {
// 	const { params } = options;
// 	const { accountId } = params;
// 	const permissions = user?.getPermissions();
// 	if (!permissions || !accountId) {
// 		return false;
// 	}
//
// 	const isAccountValidate = Object.keys(permissions).includes(`${PERMISSIONS_NAMESPACE}/${accountId}`);
// 	mfpLogger.log(`TCL Account validated, ${isAccountValidate} ${accountId}`);
// 	return isAccountValidate;
// };
//
// TODO - this validate actions is the one that should be on production, for each account will check against all its permissions
// export const validateAction: (action: PermissionActions) => validateGuard = (action: PermissionActions) => (user, options) => {
// 	const { params } = options;
// 	const { accountId } = params;
// 	const permissions = user?.getPermissions();
// 	if (!permissions || !accountId) {
// 		return false;
// 	}
//
// 	if (!validateAccount(user, options)) {
// 		return false;
// 	}
//
// 	const accountPermissions = permissions[`${PERMISSIONS_NAMESPACE}/${accountId}`];
//
// 	const isValidateAdvertiserRead = accountPermissions.includes(action);
// 	mfpLogger.log(`TCL Action Validated ${isValidateAdvertiserRead} ${accountId} ${action}`);
// 	return isValidateAdvertiserRead;
// };
//
// TODO: currently this validate action will aggregate and check is actions in all permissions from accounts of the user
// Will need to be changed to the validateAction above this function
type ValidateActionType = (permissionsAction: PermissionActions[]) => validateGuard;
// const isGuard = true;
export const validateAction: ValidateActionType = (permissionsAction: PermissionActions[]) => {
	return user => {
		return Array.isArray(permissionsAction) ? permissionsAction.every(action => user?.permissionsSet?.has(action)) : false;
	};
};

// Will get validate functions and will determine if the user is permitted or not
interface IUseGuard {
	validate: validateGuard | validateGuard[];
}
export const useGuard = (props: IUseGuard) => {
	const { validate } = props;
	const { userStore } = useStores<IMFPStore>();

	const user = userStore.getUser();
	const validators = () => {
		if (!user) {
			return false;
		}
		if (!validate) {
			return true;
		}
		return (Array.isArray(validate) ? validate : [validate]).every(valFunc => valFunc(user));
	};
	const [isPermitted, setIsPermitted] = useState<boolean>(validators());

	useEffect(() => {
		setIsPermitted(true);
	}, [user]);

	return isPermitted;
};
