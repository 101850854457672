import { observer } from 'mobx-react';
import { stringAction, stringFuncs } from '@monorepo/tools/src/lib/utils/string';
import { FilterModel, IFilterComponentProps, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { FilterStringMenu } from '@monorepo/base/src/components/filters/filter-string-menu/filter-string-menu';
import { FilterOptions } from '@monorepo/base/src/components/filters/filter-options/filter-options';
import { capitalCase, snakeCase } from 'change-case';
import { StatusEnum } from '../../enums/status';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

interface IStringFilterProto {
	cellKey: string;
	columnName: string;
	enumOptions?: string[];
	entity?: string;
}

interface IStringFilterComponentProps extends IFilterComponentProps {
	cellKey: string;
	columnName: string;
	enumOptions?: string[];
	entity?: string;
}

const StringsFilter = (props: IStringFilterComponentProps) => {
	const { filter, onCancel, onApply, addFilter, editFilter, columnName, enumOptions, cellKey, entity } = props;
	const protoObj = StringFilterPrototype({ columnName, cellKey, entity });

	if (typeof filter?.value === 'number') {
		return <div></div>;
	}

	const onAddStringFilter = (stringFilterProps: { _value: string | string[]; _action?: stringAction }) => {
		const { _value, _action } = stringFilterProps;
		const filterLabel = _action
			? `${columnName} ${_action} ${_value}`
			: `${columnName} is ${typeof _value === 'string' ? _value : _value.join(', ')}`;

		const stringsFilter = new FilterModel({
			label: filterLabel,
			action: _action,
			value: _value,
			prototype: protoObj,
		});

		if (filter?.index && editFilter) {
			editFilter(filter.index, stringsFilter);
		} else {
			if (protoObj?.multi && addFilter) {
				addFilter(stringsFilter);
			}
		}
		onApply();
	};

	if (!enumOptions || enumOptions.length === 0) {
		return (
			<FilterStringMenu
				title={columnName}
				defaultValue={typeof filter?.value === 'string' ? filter?.value : ''}
				defaultAction={filter?.action}
				onApply={(_action: stringAction, _value: string) => onAddStringFilter({ _action, _value })}
				onCancel={onCancel}
				debugProps={{ dataAttrs: [new DataAttribute('id', `${snakeCase(columnName)}_menu`)] }}
			/>
		);
	}

	return (
		<FilterOptions
			placeholder={`Search ${columnName}`}
			value={typeof filter?.value === 'string' ? capitalCase(filter?.value) : filter?.value?.join(', ') || ''}
			onFilterOption={_value => onAddStringFilter({ _value })}
			onCancel={onCancel}
			options={Object.values(StatusEnum).map(_status => capitalCase(_status))}
			isSelectAll={true}
		/>
	);
};

export const StringFilterPrototype = (props: IStringFilterProto): IFilterProto => {
	const { columnName, enumOptions, cellKey, entity } = props;
	return {
		MenuComponent: columnName,
		FilterComponent: observer((props: IFilterComponentProps) => StringsFilter({ ...props, columnName, enumOptions, cellKey, entity })),
		prop: `${entity}${cellKey}`,
		deletable: true,
		multi: true,
		filterFunc<T>(models: Array<any>, filter: FilterModel): Array<T> {
			const { value, action } = filter;
			return models.filter(model => {
				if (!action) {
					return true;
				}
				const actionFunc = stringFuncs[action as stringAction];
				if (!actionFunc) {
					return true;
				}
				if (Array.isArray(model[cellKey])) {
					return model[cellKey].some((val: string) => actionFunc(val || '', typeof value === 'string' ? value : ''));
				}

				return actionFunc(model[cellKey] || '', typeof value === 'string' ? value : '');
			});
		},
	};
};
