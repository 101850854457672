import { NavigateOptions, useNavigate, To, useSearchParams, NavigateFunction } from 'react-router-dom';

let globalIncludeParams: string[] = [];
export const declareIncludeParams = (knownSearchParmas: string[]) => {
	globalIncludeParams = knownSearchParmas;
};

export const getParamsFromUrl = (params: URLSearchParams): string => {
	let searchParams = '';
	globalIncludeParams.forEach(key => {
		if (params.get(key)) {
			searchParams += `${key}=${params.get(key)}`;
		}
	});
	if (searchParams.length > 0) {
		searchParams = '?' + searchParams;
	}
	return searchParams;
};

export const useNavigateParams = () => {
	const navigate = useNavigate();
	const [params] = useSearchParams();

	const navigateWithParams: NavigateFunction = (to: To | number, options?: NavigateOptions) => {
		if (typeof to === 'number') {
			navigate(to);
		} else {
			const searchParams = getParamsFromUrl(params);
			navigate(to + searchParams, options);
		}
	};
	return navigateWithParams;
};
