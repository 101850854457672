import { IReviewCampaignsCreateForm, IReviewCampaignsEditForm, ReviewCampaignsModel } from '../models/review-campaigns.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';

export const ReviewCampaignsApi = {
	list: async (params?: URLSearchParams): Promise<ReviewCampaignsModel[]> => {
		console.log('TCL ~ ~ list: ~ params:', params);
		return [];
	},
	create: (reviewCampaigns: IReviewCampaignsCreateForm, options?: IRequestOptions): Promise<ReviewCampaignsModel> => {
		return ask
			.post<IReviewCampaignsCreateForm>(endpoints.reviewCampaigns.create(), reviewCampaigns, options)
			.then(res => res?.payload?.item);
	},
	edit: (reviewCampaignsId: id, reviewCampaigns: IReviewCampaignsEditForm, options?: IRequestOptions): Promise<ReviewCampaignsModel> => {
		return ask
			.patch<IReviewCampaignsEditForm>(endpoints.reviewCampaigns.edit(reviewCampaignsId), reviewCampaigns, options)
			.then(res => res?.payload?.item);
	},
	get: (reviewCampaignsId: id, options?: URLSearchParams): Promise<ReviewCampaignsModel> => {
		return ask.get(endpoints.reviewCampaigns.get(reviewCampaignsId), options).then(res => res?.payload?.item);
	},
	delete: (reviewCampaignsId: id) => {
		return ask.delete(endpoints.reviewCampaigns.delete(reviewCampaignsId)).then(res => res?.payload?.ids);
	},
};
