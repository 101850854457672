import { ReactNode } from 'react';
import { MainBreadcrumbs } from '../main-breadcrumbs/main-breadcrumbs';
import { NotificationsMenu } from '../notifications-menu/notifications-menu';
import { SettingsMenu } from '../settings-menu/settings-menu';
import { UserMenu } from '../user-menu/user-menu';
import styles from './main-navbar.module.scss';

interface IMainNavbar {
	BeginNavbar?: ReactNode;
}

export const MainNavbar = (props: IMainNavbar) => {
	const { BeginNavbar } = props;

	return (
		<nav className={styles.navBar}>
			<div className={styles.begin}>{BeginNavbar || <MainBreadcrumbs />}</div>
			<div className={styles.end}>
				<SettingsMenu />
				<NotificationsMenu />
				<UserMenu />
			</div>
		</nav>
	);
};
