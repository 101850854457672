import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import routes from '../../routes/index.routes';
import { ColumnDef, createColumnHelper, sortingFns } from '@tanstack/react-table';
import { isStringInfinityOrNaN } from '@monorepo/tools/src/lib/utils/number';
import { IAccount } from '../../models/account.model';
import { IAdvertiser } from '../../models/advertiser.model';

const columnHelper = createColumnHelper<ISupportedColumns>();

type SupportedColumns = 'accountName' | 'accountId' | 'advertiserName' | 'advertiserId' | 'campaignName' | 'campaignId';
interface ISupportedColumns {
	accountName: string;
	accountId: string;
	advertiserName: string;
	advertiserId: string;
	campaignName: string;
	campaignId: string;
	account: IAccount;
	advertiser: IAdvertiser;
}

export const useSpecificHeaders = (props: { specificColumns: SupportedColumns[] }) => {
	const { specificColumns } = props;

	const specificColumnsMapper = {
		accountName: columnHelper.accessor('accountName', {
			header: 'Account',
			cell: ({ row }) => {
				const account: IAccount = row.original.account || row.original.advertiser?.account;
				const displayName: string = account?.name || '';
				return <TableLink to={account.id ? routes.accounts.advertisers(account.id) : undefined}>{displayName}</TableLink>;
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		accountId: columnHelper.accessor('accountId', {
			header: 'Account ID',
			cell: ({ row }) => {
				const account: IAccount = row.original.account || row.original.advertiser?.account;
				const displayName: string = account?.id?.toString() || '';
				return <TableLink to={account.id ? routes.accounts.advertisers(account.id) : undefined}>{displayName}</TableLink>;
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		advertiserName: columnHelper.accessor('advertiserName', {
			header: 'Advertiser',
			cell: ({ row }) => {
				const adveriser: IAdvertiser = row.original.advertiser;
				const displayName: string = adveriser?.name || '';
				return <TableLink to={adveriser.id ? routes.advertisers.campaigns(adveriser.id) : undefined}>{displayName}</TableLink>;
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		advertiserId: columnHelper.accessor('advertiserId', {
			header: 'Advertiser ID',
			cell: ({ row }) => {
				const adveriser: IAdvertiser = row.original.advertiser;
				const displayName: string = adveriser?.id?.toString() || '';
				return <TableLink to={adveriser.id ? routes.advertisers.campaigns(adveriser.id) : undefined}>{displayName}</TableLink>;
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		campaignName: columnHelper.accessor('campaignName', {
			header: 'Campaign',
			cell: ({ row }) => {
				const displayName: string = !isStringInfinityOrNaN(row.getValue('campaignName')) ? row.getValue('campaignName') ?? '' : '';
				return <TableLink to={routes.campaigns.channels(row.getValue('campaignId'))}>{displayName}</TableLink>;
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		campaignId: columnHelper.accessor('campaignId', {
			header: 'Campaign ID',
			cell: ({ row }) => {
				const displayName: string = !isStringInfinityOrNaN(row.getValue('campaignId')) ? row.getValue('campaignId') ?? '' : '';
				return <TableLink to={routes.campaigns.channels(row.getValue('campaignId'))}>{displayName}</TableLink>;
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
	};

	const columns: ColumnDef<ISupportedColumns, string>[] = [];
	specificColumns.forEach(col => {
		if (specificColumnsMapper[col]) {
			columns.push(specificColumnsMapper[col]);
		}
	});

	return {
		columns,
		columnVisibility: specificColumns.reduce((m, v) => ((m[v] = false), m), Object()),
	};
};
