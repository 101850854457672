// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Chart as ChartJS, ArcElement, Tooltip, Legend, TooltipModel, TooltipItem, ChartOptions } from 'chart.js';
import { Fragment, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useDebug } from '../../../../manifesto-platform/src/modules/hooks/tools/use-debug';
import { ChartTooltip, ITooltipData } from '../chart/chart-tooltip/chart-tooltip';
import { DonutLegends } from './donut-legends/donut-legends';
import styles from './donut.module.scss';

export interface IDonut {
	labels: string[];
	label: string;
	data: number[];
	backgroundColor?: string[];
	borderColor?: string[];
	borderWidth?: number;
	startDate?: string;
	endDate?: string;
	isLoading?: boolean;
	isError?: boolean;
}

export const Donut = (props: IDonut) => {
	const {
		labels = [],
		label = '',
		data = [],
		backgroundColor = ['#7F56D9', '#9E77ED', '#B692F6', '#D6BBFB', '#E9D7FE'],
		borderColor = ['white', 'white', 'white', 'white', 'white'],
		borderWidth = 1,
		startDate,
		endDate,
		isLoading,
		isError,
	} = props;

	const isDebug = useDebug();

	if (isDebug) {
		console.log('dount says hi');
	}

	const datasets = [
		{
			label,
			data,
			backgroundColor,
			borderColor,
			borderWidth,
		},
	];

	const legends = data.map((item, index) => {
		return {
			value: !isNaN(item) ? item : 0,
			color: backgroundColor[index],
			label,
			entityName: labels[index],
		};
	});

	const [tooltipData, setTooltipData] = useState<ITooltipData>({
		opacity: 0,
		top: 0,
		left: 0,
		dataPoints: [],
	});

	const isDataReady = () => !isLoading && data.length !== 0 && !isError;

	const chartRef = useRef<ChartJS<'doughnut', number[], string>>(null);
	ChartJS.register(ArcElement, Tooltip, Legend);
	const donutData = isDataReady()
		? {
				labels,
				datasets,
		  }
		: {
				labels: [],
				datasets: [{ label: 'label', data: [1], backgroundColor: ['#f5f5f5'], borderColor, borderWidth }],
		  };

	const optionsObj: ChartOptions = isDataReady()
		? {
				plugins: {
					tooltip: {
						enabled: false,
						external: context => {
							const tooltipModel = context.tooltip as TooltipModel<'doughnut'>;

							if (tooltipModel.opacity === 0) {
								if (tooltipData.opacity !== 0) {
									//&& !tooltipHover
									setTooltipData(prev => ({ ...prev, opacity: 0 }));
								}
								return;
							}

							const { dataPoints } = tooltipModel;

							const newDataPoints = dataPoints.map((point: TooltipItem<'doughnut'>) => {
								const { label, formattedValue, dataset } = point;
								return {
									value: formattedValue,
									date: `${startDate} - ${endDate}`,
									color: backgroundColor[point.dataIndex],
									label: `${dataset.label}` || '',
									entityName: label,
								};
							});

							const newTooltipData = {
								opacity: 1,
								left: 0,
								top: -100,
								dataPoints: newDataPoints,
							};

							if (JSON.stringify(tooltipData) !== JSON.stringify(newTooltipData)) {
								// Only rerender when we have new data to show
								setTooltipData(newTooltipData);
							}
						},
					},
					legend: {
						display: false,
						position: 'bottom',
						align: 'start',
						labels: {
							padding: 50,
							boxWidth: 7,
							boxHeight: 7,
							usePointStyle: true,
							font: {
								size: 18,
								family: '"Inter", sans-serif',
							},
						},
					},
				},
		  }
		: {
				animation: false,
				plugins: {
					tooltip: {
						enabled: false,
					},
					legend: {
						display: false,
						position: 'bottom',
						align: 'start',
						labels: {
							padding: 50,
							boxWidth: 7,
							boxHeight: 7,
							usePointStyle: true,
							font: {
								size: 18,
								family: '"Inter", sans-serif',
							},
						},
					},
				},
		  };

	return (
		<div className={styles.grid}>
			{isDataReady() ? (
				<Fragment>
					<div className={styles.donutSize}>
						<ChartTooltip isDonutTooltip data={tooltipData} chartWidth={chartRef?.current?.width} />
						<Doughnut options={optionsObj} data={donutData} ref={chartRef} />
					</div>

					<DonutLegends legends={legends} />
				</Fragment>
			) : (
				<Fragment>
					<div className={styles.donutSize}>
						<ChartTooltip isDonutTooltip data={tooltipData} chartWidth={chartRef?.current?.width} />
						<Doughnut options={optionsObj} data={donutData} ref={chartRef} />
					</div>
					<DonutLegends isLoading={isLoading || isError || data.length === 0} legends={legends} />
					{isError ? <span className={styles.error}>Oops! Something went wrong...</span> : null}
					{!isError && !isLoading ? <span className={styles.noResults}>No Results Found</span> : null}
				</Fragment>
			)}
		</div>
	);
};
