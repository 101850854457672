import { FormStore } from '@monorepo/controlled/src/stores/form.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { makeAutoObservable } from 'mobx';
import { CampaignDomIds } from '../../hooks/toc/campaign.toc';

export interface ISmartStrategyModel {
	dailyBudget?: number;
	defaultBid?: number;
	optimizationModeValue?: number;
}

export interface ISmartStrategyForm {
	dailyBudget?: number;
	defaultBid?: number;
	optimizationModeValue?: number;
}

export class SmartStrategyModel implements ISmartStrategyModel {
	dailyBudget?: number;
	defaultBid?: number;
	optimizationModeValue?: number;

	constructor(strategy?: ISmartStrategyModel) {
		this.dailyBudget = strategy?.dailyBudget;
		this.defaultBid = strategy?.defaultBid;
		this.optimizationModeValue = strategy?.optimizationModeValue;

		makeAutoObservable(this);
	}

	public isValid(formStore: FormStore) {
		if (!this.optimizationModeValue) {
			formStore.addError(new FormError(CampaignDomIds.OptimizationModeValue, 'Please provide optimization bid'));
		}
	}

	public getFormData(): ISmartStrategyForm {
		return {
			dailyBudget: this.dailyBudget,
			defaultBid: this.defaultBid,
		};
	}

	public getDailyBudget(): number | undefined {
		return this.dailyBudget;
	}

	public setDailyBudget(dailyBudget: number): void {
		this.dailyBudget = dailyBudget;
	}

	public getOptimizationModeValue() {
		return this.optimizationModeValue;
	}

	public setOptimizationModeValue(optimizationModeValue?: number) {
		this.optimizationModeValue = optimizationModeValue;
	}

	public getDefaultBid() {
		return this.defaultBid;
	}

	public setDefaultBid(defaultBid?: number) {
		this.defaultBid = defaultBid;
	}
}
