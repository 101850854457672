import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { FormControl, FormTitle, FormDesc } from '@monorepo/base/src/components/form/form-control/form-control';
import { InputItem, InputInfo, InputTitle, InputDesc } from '@monorepo/base/src/components/form/input-item/input-item';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { Strategies } from '../../../../enums/strategies';
import { CampaignDomIds } from '../../../../hooks/toc/campaign.toc';
import { IMFPStore } from '../../../../stores';
import { ManifestoGButton } from '../../../base-wrappers/g-button/g-button';
import { BudgetInput } from '../campaign-common.form';
import styles from '../campaign-form.module.scss';
import { SpaceBetweenFormControls, SpaceBetweenInputControls } from '../campaign.form';
import { ManualStrategy } from './manual-strategy';
import { SmartStrategy } from './smart-strategy';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { PrimaryText } from '@monorepo/base/src/components/buttons/buttons';
import { ChannelIds, ChannelModel, ChannelsEnums } from '../../../../models/channel.model';
import { useEffect, useMemo, useState } from 'react';
import { randomNumber } from '@monorepo/tools/src/lib/utils/number';

const campaignStrategyDebugProps = { dataAttrs: [new DataAttribute('id', 'campaign_strategy')] };
const ChannelSuggestedBids = parseFloat(randomNumber(0.05, 0.5));

export const CampaignStrategy = observer(() => {
	const { campaignStore, accountStore } = useStores<IMFPStore>();
	const campaignCrud = campaignStore.getCrud();
	const campaign = campaignCrud.getData();
	const campaignAccount = campaign.getAdvertiser()?.getAccount();
	const formStore = campaignCrud.getFormStore();

	const [channelOptions, setChannelOptions] = useState<string[]>([]);
	const [defaultChannels, setDefaultChannels] = useState<Set<ChannelsEnums>>(new Set());
	const campaignStrategy = campaign.getStrategyType();

	const CampaignStrategiesOptions = {
		[Strategies.Manual]: <ManualStrategy />,
		[Strategies.Smart]: <SmartStrategy />,
	};

	useMemo(() => {
		const channelsArr: string[] = [];
		campaign?.getChannels()?.forEach(channel => {
			if (channel.getName()) {
				channelsArr.push(channel.getName() as string);
			}
		});
		setChannelOptions([...channelsArr]);
	}, [campaign?.getChannels()]);

	useEffect(() => {
		if (!campaignAccount) {
			return;
		}
		const accountDefaultChannels = accountStore
			.getListStore()
			.getList()
			.filter(acc => acc.getId() === campaignAccount.getId())?.[0]
			?.getInventoryChannels();
		if (!accountDefaultChannels || accountDefaultChannels.length === 0) {
			const allChannels = new Set(Object.values(ChannelsEnums));
			setDefaultChannels(allChannels);
			onSelectChannel(allChannels);
			return;
		}
		setDefaultChannels(new Set(accountDefaultChannels));
		onSelectChannel(new Set(accountDefaultChannels));
	}, [campaignAccount]);

	const onSelectChannel = (channels: Set<ChannelsEnums>) => {
		const channelsMap = new Map<ChannelsEnums, ChannelModel>();
		channels.forEach(channel => {
			channelsMap.set(
				channel,
				new ChannelModel({
					channelId: ChannelIds[channel],
					name: channel,
					suggestedBid: ChannelSuggestedBids,
				})
			);
		});
		campaign.setChannels(channelsMap);
		formStore.clearError(CampaignDomIds.TrafficChannels);
	};

	const onResetSelectionToDefault = () => {
		onSelectChannel(new Set(defaultChannels));
	};

	return (
		<div {...generateDataAttrs([new DataAttribute('id', 'campaign_strategy_section')])}>
			<FormControl>
				<FormTitle id={CampaignDomIds.Strategy}>Budget and Bidding Strategy</FormTitle>
				<FormDesc>Select the budget and bidding strategy that best suits your goals</FormDesc>
			</FormControl>

			<SpaceBetweenFormControls />

			<InputItem maxWidth={'620px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Total Budget</InputTitle>
					<InputDesc>Enter the total amount you have to spend</InputDesc>
				</InputInfo>
				<BudgetInput
					value={campaign.getTotalBudget()?.toString()}
					setValue={value => {
						campaign.setTotalBudget(parseFloat(value));
						formStore.clearError(CampaignDomIds.TotalBudget);
					}}
					error={formStore.getErrors().get(CampaignDomIds.TotalBudget)?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'total_budget')] }}
				/>
			</InputItem>

			<SpaceBetweenInputControls />

			<InputItem id={CampaignDomIds.TotalBudget} maxWidth={'620px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Daily Budget</InputTitle>
					<InputDesc>Enter the maximum amount you can spend per day</InputDesc>
				</InputInfo>
				<BudgetInput
					value={campaign.getDailyBudget()?.toString()}
					id={CampaignDomIds.DailyBudget}
					error={formStore.getErrors().get(CampaignDomIds.DailyBudget)?.getMsg()}
					setValue={value => {
						campaign.setDailyBudget(parseFloat(value));
						formStore.clearError(CampaignDomIds.DailyBudget);
					}}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'smart_daily_budget')] }}
				/>
			</InputItem>

			<SpaceBetweenInputControls />

			<Flex maxWidth={'830px'} gap={'25px'}>
				<Flex justifyContent={'flex-start'}>
					<InputInfo>
						<InputTitle>Traffic Channels</InputTitle>
						<InputDesc>Choose the traffic channels you want the campaign to run on</InputDesc>
					</InputInfo>
					<Dropdown
						label={'Select Traffic Channels'}
						options={Object.values(ChannelsEnums)}
						defaultOptions={new Set([...channelOptions])}
						onSelect={(channels: Set<option> | undefined) =>
							onSelectChannel(channels ? (channels as Set<ChannelsEnums>) : new Set([]))
						}
						autocomplete={true}
						multi={true}
						autocompletePlaceholder={'Search Channels'}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'channels')] }}
						error={formStore.getErrors().get(CampaignDomIds.TrafficChannels)?.getMsg()}
						id={CampaignDomIds.TrafficChannels}
						className={styles.channelsDropdown}
						showAllTag
						showClearAllButton={false}
					/>
				</Flex>
				<PrimaryText
					disabled={
						!campaignAccount ||
						(defaultChannels.size === (campaign.getChannels()?.size || 0) &&
							[...defaultChannels].every(c => campaign.getChannels()?.has(c)))
					}
					width="min-content"
					icon={'refresh-ccw-01'}
					onClick={onResetSelectionToDefault}>
					Reset Selection to default
				</PrimaryText>
			</Flex>

			<SpaceBetweenInputControls />

			<InputItem flex={false} max={true}>
				<ButtonGroup
					required={true}
					defaultValue={campaign.getStrategyType()}
					error={formStore.getErrors().get(CampaignDomIds.Strategy)?.getMsg()}
					onChange={setValues => {
						const strategyType = setValues.values().next().value as Strategies;
						campaign.setStrategyType(strategyType);
						formStore.clearError(CampaignDomIds.Strategy);
					}}>
					<ManifestoGButton
						value={Strategies.Smart}
						title={'Smart'}
						description={'Define your goals and use our enhanced optimized auto-pilot'}
						icon={'magic-wand-01'}
						badge={
							<Tag textColor={getTheme().primary700} backgroundColor={'#F9F5FF'} fontWeight={'500'}>
								Recommended
							</Tag>
						}
						debugProps={campaignStrategyDebugProps}
					/>
					<ManifestoGButton
						value={Strategies.Manual}
						title={'Manual'}
						description={'Custom bid and budget per a selected channel'}
						icon={'sliders-01'}
						debugProps={campaignStrategyDebugProps}
					/>
				</ButtonGroup>
			</InputItem>

			<SpaceBetweenInputControls />

			{campaignStrategy ? CampaignStrategiesOptions[campaignStrategy] : null}
		</div>
	);
});
