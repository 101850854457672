import { generateStorageKey } from '@monorepo/controlled/src/stores/helper';
import { makeAutoObservable } from 'mobx';
import dayjs from 'dayjs';
import { makePersistable } from 'mobx-persist-store';

export interface IDateSelection {
	key: string;
	startDate?: Date;
	endDate?: Date;
	label?: string;
}

export class DateSelection {
	startDate: Date;
	endDate: Date;
	key: string;
	label?: string;

	constructor(props: IDateSelection) {
		this.startDate = props?.startDate || new Date();
		this.endDate = props?.endDate || new Date();
		this.label = props?.label;
		this.key = props.key;

		makeAutoObservable(this);
	}

	setEndDate(endDate: Date) {
		this.endDate = endDate;
	}

	setStartDate(startDate: Date) {
		this.startDate = startDate;
	}

	getEndDate(): Date {
		return this.endDate;
	}

	getStartDate(): Date {
		return this.startDate;
	}

	getLabel(): string | undefined {
		return this.label;
	}
}

interface IDateStore {
	primarySelection: IDateSelection;
}

const initialStartDate = dayjs().toDate();
const initialEndDate = dayjs().subtract(7, 'day').toDate();

export class DateStore implements IDateStore {
	primarySelection: DateSelection;

	constructor() {
		this.primarySelection = new DateSelection({
			key: 'initial',
			startDate: initialStartDate,
			endDate: initialEndDate,
			label: 'Last 7 Days',
		});

		makeAutoObservable(this);

		makePersistable(this, {
			name: generateStorageKey('datestore'),
			properties: ['primarySelection'],
			storage: {
				setItem: (key, data) => {
					// dont save initial because of hydration problems with dater component, send only key primary dater
					if (JSON.parse(data)?.primarySelection?.key === 'initial') {
						return;
					}
					window.localStorage.setItem(key, data);
				},
				removeItem: key => window.localStorage.removeItem(key),
				getItem: key => {
					const data = JSON.parse(window.localStorage.getItem(key) || '{}');
					const primary = {
						startDate: new Date(data?.primarySelection?.startDate),
						endDate: new Date(data?.primarySelection?.endDate),
						key: data.primarySelection?.key,
						label: data.primarySelection?.label,
					};
					return {
						...data,
						primarySelection: data.primarySelection ? new DateSelection(primary) : undefined,
					};
				},
			},
		});
	}

	setPrimarySelection(date: DateSelection): void {
		this.primarySelection = date;
	}

	getPrimarySelection(): DateSelection {
		return this.primarySelection;
	}
}
