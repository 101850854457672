import { IBulkCampaignsCreateForm, IBulkCampaignsEditForm, BulkCampaignsModel } from '../models/bulk-campaigns.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';

export const BulkCampaignsApi = {
	list: (params?: URLSearchParams): Promise<BulkCampaignsModel[]> => {
		return ask.get(endpoints.campaigns.bulkCampaignsList(), params).then(res => res?.payload?.list);
	},
	create: (bulkCampaigns: IBulkCampaignsCreateForm, options?: IRequestOptions): Promise<BulkCampaignsModel> => {
		return ask.post<IBulkCampaignsCreateForm>(endpoints.bulkCampaigns.create(), bulkCampaigns, options).then(res => res?.payload?.item);
	},
	edit: (bulkCampaignsId: id, bulkCampaigns: IBulkCampaignsEditForm, options?: IRequestOptions): Promise<BulkCampaignsModel> => {
		return ask
			.patch<IBulkCampaignsEditForm>(endpoints.bulkCampaigns.edit(bulkCampaignsId), bulkCampaigns, options)
			.then(res => res?.payload?.item);
	},
	get: (bulkCampaignsId: id, options?: URLSearchParams): Promise<BulkCampaignsModel> => {
		return ask.get(endpoints.bulkCampaigns.get(bulkCampaignsId), options).then(res => res?.payload?.item);
	},
	delete: (bulkCampaignsId: id) => {
		return ask.delete(endpoints.bulkCampaigns.delete(bulkCampaignsId)).then(res => res?.payload?.ids);
	},
};
