import { id } from '@monorepo/tools/src/lib/types/primitives';
import { makeObservable, observable } from 'mobx';

export interface ISource {
	id?: number;
	name?: string;
	campaignName?: string;
	channelName?: string;
	campaignId?: number;
	publisherName?: string;
	sourceName?: string;
	datet?: Date;
	accountId?: number;
	accountName?: string;
	advertiserId?: number;
	advertiserName?: string;
	impressions?: number;
	clicks?: number;
	cost?: number;
	mainConversions?: number;
	mainRevenue?: number;
	roas?: number;
	cpc?: number;
	cpa?: number;
	cpm?: number;
	cvr?: number;
	epc?: number;
	convVal?: number;
	winRate?: number;
}
export class SourceModel implements ISource {
	id?: number;
	name?: string;
	campaignName?: string;
	channelName?: string;
	campaignId?: number;
	publisherName?: string;
	sourceName?: string;
	datet?: Date;
	accountId?: number;
	accountName?: string;
	advertiserId?: number;
	advertiserName?: string;
	impressions?: number;
	clicks?: number;
	cost?: number;
	mainConversions?: number;
	mainRevenue?: number;
	roas?: number;
	cpc?: number;
	cpa?: number;
	cpm?: number;
	cvr?: number;
	epc?: number;
	convVal?: number;
	winRate?: number;

	constructor(source?: ISource) {
		this.id = source?.id;
		this.name = source?.name;
		this.campaignName = source?.campaignName;
		this.channelName = source?.channelName;
		this.campaignId = source?.campaignId;
		this.publisherName = source?.publisherName;
		this.sourceName = source?.sourceName;
		this.datet = source?.datet;
		this.accountId = source?.accountId;
		this.accountName = source?.accountName;
		this.advertiserId = source?.advertiserId;
		this.advertiserName = source?.advertiserName;
		this.impressions = source?.impressions;
		this.clicks = source?.clicks;
		this.cost = source?.cost;
		this.mainConversions = source?.mainConversions;
		this.mainRevenue = source?.mainRevenue;
		this.roas = source?.roas;
		this.cpc = source?.cpc;
		this.cpa = source?.cpa;
		this.cpm = source?.cpm;
		this.cvr = source?.cvr;
		this.epc = source?.epc;
		this.convVal = source?.convVal;
		this.winRate = source?.winRate;

		makeObservable(this, {
			campaignName: observable,
			channelName: observable,
			campaignId: observable,
			publisherName: observable,
			sourceName: observable,
			datet: observable,
			accountId: observable,
			accountName: observable,
			advertiserId: observable,
			advertiserName: observable,
			impressions: observable,
			clicks: observable,
			cost: observable,
			mainConversions: observable,
			mainRevenue: observable,
			roas: observable,
			cpc: observable,
			cpa: observable,
			cpm: observable,
			cvr: observable,
			epc: observable,
			convVal: observable,
			winRate: observable,
		});
	}

	public getId(): id | undefined {
		return this.id;
	}
}
