import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import {
	Entities,
	IPerformanceOptionsSortBy,
	PerformanceOptionsSortByStrings,
	PerformancesColumns,
} from '../../hooks/apis/use-reports-list';
import { useMetricsGet } from '../../hooks/tools/use-metrics-get';
import { IMFPStore } from '../../stores';
import { ListDonut } from './list-donut/list-donut';
import styles from './insights.module.scss';
import { InsightsTabs } from './insights-tabs/insights-tabs';
import { useInsights } from '../../hooks/tools/use-insights';

interface IInsights {
	options: PerformanceOptionsSortByStrings[];
	defaultOption?: PerformanceOptionsSortByStrings;
}

export const Insights = observer((props: IInsights) => {
	const { options, defaultOption } = props;
	const [selectedOption, setSelectedOption] = useState<PerformanceOptionsSortByStrings>(defaultOption || options[0]);
	const { campaignStore } = useStores<IMFPStore>();
	const insightsStore = campaignStore.getInsightsStore();

	const { metricsError, isLoading } = useMetricsGet(insightsStore, {
		entity: Entities.Campagins,
		groupBys: [PerformancesColumns.CampaignId],
		sortBys: [{ column: IPerformanceOptionsSortBy[selectedOption], order: 'desc' }],
		limit: 6,
	});

	const data = useInsights(campaignStore.getInsightsStore().getData(), IPerformanceOptionsSortBy[selectedOption]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Top Campaigns Insights</div>
			<InsightsTabs isError={Boolean(metricsError)} options={options} defaultValue={selectedOption} onTabClick={setSelectedOption} />
			<ListDonut data={data} defaultValue={selectedOption} isLoading={isLoading} isError={Boolean(metricsError)} />
		</div>
	);
});
