import { Strategies } from '../../enums/strategies';
import { ChannelModel } from '../../models/channel.model';
import { mapToObject } from '@monorepo/tools/src/lib/utils/map';
import { CampaignsApi } from '../../apis/campaigns.api';
import { runInAction } from 'mobx';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { ErrorPayloadType, HttpError, IHttpError } from '../../models/http-error.model';
import { CampaignModel, ICampaignCreateForm, ICampaignEditForm } from '../../models/campaign.model';
import { setToArray } from '@monorepo/tools/src/lib/utils/set';
import { ChannelsEnums } from '../../models/channel.model';
import { isValidUrl } from '@monorepo/tools/src/lib/utils/url';
import dayjs from 'dayjs';
import { CampaignDomIds } from '../../hooks/toc/campaign.toc';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { IAskError } from '@monorepo/tools/src/lib/tools/ask/ask';
import { StatusEnum } from '../../enums/status';
import { OptimizationAlgoVersionEnum } from '../../models/strategies/advanced-bidding';

export class CampaignCrudStore extends BaseCrudStore<CampaignModel, ICampaignCreateForm, ICampaignEditForm, HttpError> {
	constructor() {
		super({
			apiLayer: CampaignsApi,
			model: CampaignModel,
			errorModel: HttpError,
		});
	}

	create(options?: IRequestOptions): Promise<CampaignModel | { id: id } | void> {
		const campaign = this.getData();
		campaign.getChannels()?.forEach(channel => {
			if (!channel.getDefaultBid()) {
				channel.setDefaultBid(channel.getSuggestedBid() as number);
			}
		});

		return super.create(options);
	}

	createInstance(value = {}) {
		return new CampaignModel(value);
	}

	saveDraft() {
		this.getData().setStatus(StatusEnum.DRAFT);
		const isValid = this.isValidDraft();

		this.setHttpError(null);
		if (!isValid && !this.isLoading) {
			return Promise.resolve(null);
		}
		this.setIsLoading(true);
		this.setIsEdited(false);
		const campaignId = this.getData().getId();
		return (campaignId ? CampaignsApi.edit(campaignId, this.getEditFormData()) : CampaignsApi.create(this.getEditFormData()))
			.then(response => {
				const data = new this.model(response);
				runInAction(() => {
					this.setData(data);
					this.setIsEdited(true);
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return data;
			})
			.catch((error: IAskError) => {
				runInAction(() => {
					this.setHttpError(
						this.errorModel ? new this.errorModel({ ...error.data, httpStatus: error.response?.status }) : (error as HttpError)
					);
					this.setIsEdited(true);
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
				return error;
			});
	}

	async publishDraft() {
		const isValid = this.isValid();
		this.setHttpError(null);
		if (!isValid) {
			return;
		}
		this.getData().setStatus(StatusEnum.ACTIVE);

		const id = this.data.getId();
		if (!id) {
			this.setIsSuccess(false);
			// this.setHttpError(new Error('No id'));
		}
		this.setIsLoading(true);
		this.setIsSuccess(false);
		if (id) {
			this.apiLayer
				.edit(id.toString(), this.getEditFormData())
				.then(() => {
					runInAction(() => {
						this.setIsLoading(false);
						this.setIsSuccess(true);
					});
				})
				.catch(error => {
					if (this.onError) {
						this.onError(error.data);
					}
					this.getData().setStatus(StatusEnum.DRAFT);
					runInAction(() => {
						if (this.errorModel) {
							this.setHttpError(new this.errorModel({ ...error.data, httpStatus: error.response.status }));
						}
						this.setIsLoading(false);
						this.setIsSuccess(false);
					});
				});
		}
	}

	enableCampaigns(campaignsIds: id[]): Promise<void> {
		return CampaignsApi.enableCampaigns(campaignsIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	pauseCampaigns(campaignsIds: id[]): Promise<void> {
		return CampaignsApi.pauseCampaigns(campaignsIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	/**
	 * Must call isValidCampaign before calling this function
	 * @returns
	 */
	public getCreateFormData(): ICampaignCreateForm {
		const campaign = this.getData();
		const devices = campaign.getDevices();
		// const languages = campaign.getLanguages();
		const advertiser = campaign.getAdvertiser();
		const advertiserId = advertiser?.getId();
		const advertiserName = advertiser?.getName();
		const channels = campaign.getChannels();
		const endTime = campaign.getEndTime();

		return {
			name: campaign.getName(),
			advertiser: advertiserId ? { id: advertiserId, name: advertiserName } : undefined,
			landingPage: this.replaceToAts(campaign.getLandingPage() || '') || '',
			isDeepLink: campaign.getIsDeepLink(),
			startTime: dayjs(campaign.getStartTime()).format('YYYY-MM-DD HH:MM:ss'),
			endTime: endTime ? dayjs(endTime).format('YYYY-MM-DD HH:MM:ss') : undefined,
			devices: devices ? setToArray(devices) : [],
			geo: campaign.getGeo(),
			totalBudget: campaign.getTotalBudget(),
			strategyType: campaign.getStrategyType(),
			channelsByName: channels ? mapToObject<ChannelsEnums, ChannelModel>(channels) : null,
			dailyBudget: campaign.getDailyBudget(),
			defaultBid: campaign.getDefaultBid(),
			optimizationOn: campaign.getOptimizationOn(),
			optimizerConfiguration: campaign.getOptimizerConfiguration()?.getData(),
		};
	}

	/**
	 * Must call isValidCampaign before calling this function
	 * @returns
	 */
	public getEditFormData(): ICampaignEditForm {
		const campaign = this.getData();
		const devices = campaign.getDevices();
		// const languages = campaign.getLanguages();
		const advertiser = campaign.getAdvertiser();
		const advertiserId = advertiser?.getId();
		const advertiserName = advertiser?.getName();
		const channels = campaign.getChannels();
		const endTime = campaign.getEndTime();

		return {
			id: campaign.getId(),
			name: campaign.getName(),
			status: campaign.getStatus(),
			landingPage: this.replaceToAts(campaign.getLandingPage() || '') || '',
			advertiser: advertiserId ? { id: advertiserId, name: advertiserName } : undefined,
			startTime: dayjs(campaign.getStartTime()).format('YYYY-MM-DD HH:MM:ss'),
			endTime: endTime ? dayjs(campaign.getEndTime()).format('YYYY-MM-DD HH:MM:ss') : undefined,
			devices: devices ? setToArray(devices) : [],
			geo: campaign.getGeo(),
			totalBudget: campaign.getTotalBudget(),
			strategyType: campaign.getStrategyType(),
			channelsByName: channels ? mapToObject<ChannelsEnums, ChannelModel>(channels) : null,
			dailyBudget: campaign.getDailyBudget(),
			defaultBid: campaign.getDefaultBid(),
			isDeepLink: campaign.getIsDeepLink(),
			optimizationOn: campaign.getOptimizationOn(),
			optimizerConfiguration: campaign.getOptimizerConfiguration(),
		};
	}

	public isValidDraft(): boolean {
		this.formStore.reset();

		// Draft validation
		const campaign = this.getData();

		const campaignName = campaign.getName();
		if (!campaignName) {
			this.formStore.addError(new FormError(CampaignDomIds.Name, 'Please provide a campaign name'));
		}
		if (!campaign.getAdvertiser()) {
			this.formStore.addError(new FormError(CampaignDomIds.Advertiser, 'Please provide an advertiser'));
		}
		const landingPageUrl = campaign.getLandingPage();
		if (landingPageUrl && !isValidUrl(landingPageUrl)) {
			this.formStore.addError(new FormError(CampaignDomIds.LandingPage, 'Please provide a valid url'));
		}
		if (campaign.strategyType === Strategies.Smart) {
			const dailyBudget = campaign.getDailyBudget();
			if (dailyBudget && dailyBudget < 25) {
				this.formStore.addError(new FormError(CampaignDomIds.DailyBudget, 'Daily buget must be greater then 25$'));
			}
			this.validateAdvancedOptimization();
		} else if (campaign.strategyType === Strategies.Manual) {
			const manualCampaignChannels = campaign.getChannels();
			let sumChannelsDailyBudgets = 0;
			let channelDailyBudgetError = false;
			manualCampaignChannels?.forEach(channel => {
				if (channel.dailyCap) {
					if (channel.dailyCap < 25) {
						this.formStore.addError(
							new FormError(
								`manual_strategy_channel_${channel.getName()?.toLowerCase().split(' ').join('_')}_daily_budget`,
								'daily budget must be greater then 25$'
							)
						);
						channelDailyBudgetError = true;
					} else {
						sumChannelsDailyBudgets += channel.dailyCap;
					}
				}
			});
			if (channelDailyBudgetError) {
				this.formStore.addError(new FormError(CampaignDomIds.TrafficChannels, 'Channels daily budget must be greater then 25$'));
			}
			if (sumChannelsDailyBudgets && campaign?.totalBudget && sumChannelsDailyBudgets > campaign?.totalBudget) {
				this.formStore.addError(
					new FormError(CampaignDomIds.TotalBudget, 'Total budget must be bigger then Channels daily budget')
				);
			}
		}
		return this.formStore.getIsValid();
	}

	public isValid(): boolean {
		this.formStore.reset();
		const campaign = this.getData();

		//  Campaign name validation
		const campaignName = campaign.getName();
		if (!campaignName) {
			this.formStore.addError(new FormError(CampaignDomIds.Name, 'Please provide a campaign name'));
		} else if (campaignName.length > 65) {
			this.formStore.addError(new FormError(CampaignDomIds.Name, 'Campaign name must be lower then 65 characters'));
		}

		//  Campaign advertiser validation
		const advertiser = campaign.getAdvertiser();
		if (!advertiser || !advertiser?.id) {
			this.formStore.addError(new FormError(CampaignDomIds.Advertiser, 'Please provide an advertiser'));
		}

		//  Campaign landing page validation
		const landingPageUrl = campaign.getLandingPage();
		if (!landingPageUrl) {
			this.formStore.addError(new FormError(CampaignDomIds.LandingPage, 'Please provide a landing page url'));
		} else if (!isValidUrl(landingPageUrl)) {
			this.formStore.addError(new FormError(CampaignDomIds.LandingPage, 'Please provide a valid url'));
		}

		//  Campaign start time validation
		if (!campaign.getStartTime()) {
			this.formStore.addError(new FormError(CampaignDomIds.Dates, 'Please provide start date'));
		}

		//  Campaign devices validation
		const devices = campaign.getDevices();
		if (!devices || devices.size === 0) {
			this.formStore.addError(new FormError(CampaignDomIds.Devices, 'Please provide at least one device type'));
		}

		//  Campaign country (geo) validation
		if (!campaign.getGeo()) {
			this.formStore.addError(new FormError(CampaignDomIds.Geos, 'Please provide country'));
		}

		//  Campaign budget validation
		const totalBudget = campaign.getTotalBudget();
		const dailyBudget = campaign.getDailyBudget();
		if (totalBudget && dailyBudget && totalBudget < dailyBudget) {
			console.log('Total budget nust be bigger then daily budget');
			this.formStore.addError(new FormError(CampaignDomIds.TotalBudget, 'Total budget nust be bigger then daily budget'));
		}
		if (dailyBudget && dailyBudget < 25) {
			console.log('Daily budget must be greater then 25$');
			this.formStore.addError(new FormError(CampaignDomIds.DailyBudget, 'Daily budget must be greater then 25$'));
		}

		if (campaign.getChannels()?.size === 0) {
			this.formStore.addError(new FormError(CampaignDomIds.TrafficChannels, 'Please add at least one channel'));
		}
		if (campaign.strategyType === Strategies.Smart) {
			this.validateAdvancedOptimization();
		} else if (campaign.strategyType === Strategies.Manual) {
			const channelsBid = campaign.getDefaultBid();
			if (!channelsBid) {
				this.formStore.addError(new FormError(CampaignDomIds.DefaultBid, 'Please provide channels default bid'));
			}
		}
		return this.formStore.getIsValid();
	}

	private validateAdvancedOptimization(): void {
		const campaign = this.getData();
		const optimizerConfiguration = campaign.getOptimizerConfiguration();
		if (!optimizerConfiguration) {
			return;
		}
		const optimizationOn = campaign.getOptimizationOn();
		const optimizationMode = optimizerConfiguration.algoVersion;
		const optimizationModeValue =
			optimizationMode === OptimizationAlgoVersionEnum.TargetCPA
				? optimizerConfiguration.targetCpa
				: optimizerConfiguration.targetRoi;
		if (!optimizationModeValue) {
			this.formStore.addError(
				new FormError(
					CampaignDomIds.OptimizationModeValue,
					`Please provide target ${optimizationMode === OptimizationAlgoVersionEnum.TargetCPA ? 'CPA' : 'ROAS'}`
				)
			);
		}
		if (!optimizationOn) {
			return;
		}
		const learningBudgetPerSource = optimizerConfiguration.learningBudgetPerSource;
		if (learningBudgetPerSource || learningBudgetPerSource === 0) {
			if (learningBudgetPerSource < 0.1) {
				this.formStore.addError(new FormError(CampaignDomIds.LearningBudgetPerSource, 'budget must be greater then 0.1'));
			} else if (learningBudgetPerSource.toString().length > 5) {
				this.formStore.addError(new FormError(CampaignDomIds.LearningBudgetPerSource, 'Learning budget per sourceis to big'));
			}
		}
		const learningClicksPerHqSource = optimizerConfiguration.learningClicksPerHqSource;
		if ((learningClicksPerHqSource || learningClicksPerHqSource === 0) && learningClicksPerHqSource < 10) {
			this.formStore.addError(
				new FormError(CampaignDomIds.LearningClicksPerHqSource, 'Learning clicks per HQ source must be greater then 10')
			);
		}
		const learningClicksPerRqSource = optimizerConfiguration.learningClicksPerRqSource;
		if ((learningClicksPerRqSource || learningClicksPerRqSource === 0) && learningClicksPerRqSource < 10) {
			this.formStore.addError(
				new FormError(CampaignDomIds.LearningClicksPerRqSource, 'Learning clicks per RQ source must be greater then 10')
			);
		}
		const maximumLearningBid = optimizerConfiguration.maximumLearningBid;
		const defaultBid = campaign.getDefaultBid();
		if ((maximumLearningBid || maximumLearningBid === 0) && (defaultBid || defaultBid === 0) && maximumLearningBid < defaultBid) {
			this.formStore.addError(
				new FormError(CampaignDomIds.MaximumLearningBid, 'learning bid must be equal or higher then campaign bid')
			);
		}
		const maximumOptimizingBid = optimizerConfiguration.maximumOptimizingBid;
		if ((maximumOptimizingBid || maximumOptimizingBid === 0) && maximumOptimizingBid > 20) {
			this.formStore.addError(new FormError(CampaignDomIds.MaximumOptimizingBid, 'Maximum optimizing bid cannot be greater then 20'));
		}
		const statisticsWindow1 = optimizerConfiguration.statisticsWindow1;
		const statisticsWindow2 = optimizerConfiguration.statisticsWindow2;
		if ((statisticsWindow1 || statisticsWindow1 === 0) && statisticsWindow1 > 90) {
			this.formStore.addError(new FormError(CampaignDomIds.StatisticsWindow1, 'Primary window cannot be greater then 90 Days'));
		}
		if (statisticsWindow2 || statisticsWindow2 === 0) {
			if (statisticsWindow2 > 90) {
				this.formStore.addError(new FormError(CampaignDomIds.StatisticsWindow2, 'Secondary window cannot be greater then 90 Days'));
			} else if (statisticsWindow1 && statisticsWindow1 > statisticsWindow2) {
				this.formStore.addError(new FormError(CampaignDomIds.StatisticsWindow2, 'Secondary must be greater then primary window'));
			}
		}
		const minRoi = optimizerConfiguration.minRoi;
		const maxCpa = optimizerConfiguration.maxCpa;
		if (optimizationModeValue || optimizationModeValue === 0) {
			if (optimizationMode === OptimizationAlgoVersionEnum.TargetRoas && (minRoi || minRoi === 0)) {
				if (minRoi >= optimizationModeValue) {
					this.formStore.addError(
						new FormError(CampaignDomIds.OptimizationModeOptimizerValue, 'max CPA must be greater then Target ROAS')
					);
				}
			} else if (optimizationMode === OptimizationAlgoVersionEnum.TargetCPA && (maxCpa || maxCpa === 0)) {
				if (maxCpa <= optimizationModeValue) {
					this.formStore.addError(
						new FormError(CampaignDomIds.OptimizationModeOptimizerValue, 'max CPA must be greater then Target CPA')
					);
				}
			}
		}
	}

	public onError(error: IHttpError) {
		if (error.errorPayload?.type === ErrorPayloadType.Properties) {
			Object.keys(error.errorPayload?.data).forEach(key => {
				this.formStore.addError(new FormError(this.propertiesErrorMapper(key), error.errorPayload?.data[key] as string));
			});
		}
	}

	private propertiesErrorMapper(errProperty: string): string {
		const formStoreMapper = new Map([
			['advertiser', 'campaign-advertiser'],
			['name', 'campaign-name'],
			['landingPage', 'campaign-landing-page'],
			['startTime', 'campaign-dates'],
			['endTime', 'campaign-dates'],
			['geo', 'campaign-geos'],
			['languages', 'campaign-browser-languages'],
			['strategyType', 'campaign-strategy'],
			['totalBudget', 'campaign-total-budget'],
			['dailyBudget', 'campaign-smart-daily-budget'],
			['optimizationMode', 'campaign-smart-optimization-mode'],
			['dailyBudget', 'campaign-basic-daily-budget'],
			['defaultBid', 'campaign-basic-default-bid'],
		]);
		// const formStoreKeysArr = Object.values(CampaignDomIds);
		return formStoreMapper.get(errProperty) || '';
	}

	private replaceToAts(url: string) {
		return url.replace(/{{/g, '@@').replace(/}}/g, '@@');
	}
}
