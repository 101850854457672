import { FullResponse, IQueryParams } from './../../interfaces/url';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { url } from '@monorepo/tools/src/lib/types/url';

interface IInterceptors {
	responses: Map<string, IResponseInterceptor>;
	requests: Map<string, IRequestInterceptor>;
}

export interface IAskError {
	response?: Response;
	data?: any;
}

export interface IResponseInterceptor {
	success?: (response: Response) => void;
	error?: (response?: Response, data?: any) => void;
}

export interface IRequestInterceptor {
	call: (url: URL, requestOptions: RequestInit) => void;
}

function get(url: url, params?: URLSearchParams, options?: IRequestOptions, isDownload?: boolean) {
	const validUrl = new URL(url);

	if (params) {
		params.forEach((value, key) => {
			validUrl.searchParams.append(key, value);
		});
	}

	if (options?.queryParams) {
		updateQueryParamsToUrl(validUrl, options.queryParams);
	}

	const requestOptions: RequestInit = {
		method: 'GET',
		headers: {
			...options?.headers,
		},
	};
	return handleRequest(validUrl, requestOptions)
		.then(response => handleResponse(response, isDownload))
		.catch(handleCatch);
}

function getWithHeaders<TBody>(url: url, params?: URLSearchParams, options?: IRequestOptions) {
	const validUrl = new URL(url);

	if (params) {
		params.forEach((value, key) => {
			validUrl.searchParams.append(key, value);
		});
	}
	if (options?.queryParams) {
		updateQueryParamsToUrl(validUrl, options.queryParams);
	}

	const requestOptions: RequestInit = {
		method: 'GET',
		headers: {
			...options?.headers,
		},
	};
	return handleRequest(validUrl, requestOptions)
		.then(res => handleCompleteResponse<TBody>(res))
		.catch(handleCatch);
}

function postWithHeaders<TBody>(url: url, body: TBody, options?: IRequestOptions) {
	const validUrl = new URL(url);
	if (options?.queryParams) {
		updateQueryParamsToUrl(validUrl, options.queryParams);
	}
	const requestOptions: RequestInit = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', ...options?.headers },
		body: JSON.stringify(body),
	};
	return handleRequest(validUrl, requestOptions)
		.then(res => handleCompleteResponse<TBody>(res))
		.catch(handleCatch);
}

function post<T>(url: url, body: T, options?: IRequestOptions) {
	const validUrl = new URL(url);

	if (options?.queryParams) {
		updateQueryParamsToUrl(validUrl, options.queryParams);
	}
	const requestOptions: RequestInit = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json', ...options?.headers },
		body: JSON.stringify(body),
	};
	return handleRequest(validUrl, requestOptions).then(handleResponse).catch(handleCatch);
}

function download(url: url, params?: URLSearchParams, options?: IRequestOptions) {
	return get(url, params, options, true);
}

function upload(url: url, body: FormData) {
	const requestOptions: RequestInit = {
		method: 'POST',
		body,
	};
	return handleRequest(new URL(url), requestOptions).then(handleResponse).catch(handleCatch);
}

function patch<T>(url: url, body: T, options?: IRequestOptions) {
	const requestOptions: RequestInit = {
		method: 'PATCH',
		headers: { 'Content-Type': 'application/json', ...options?.headers },
		body: JSON.stringify(body),
	};
	return handleRequest(new URL(url), requestOptions).then(handleResponse).catch(handleCatch);
}

function put<T>(url: url, body: T, options?: IRequestOptions) {
	const requestOptions: RequestInit = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json', ...options?.headers },
		body: JSON.stringify(body),
	};
	return handleRequest(new URL(url), requestOptions).then(handleResponse).catch(handleCatch);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url: url, options?: IRequestOptions) {
	const validUrl = new URL(url);
	const requestOptions: RequestInit = {
		method: 'DELETE',
		headers: {
			...options?.headers,
		},
	};

	if (options?.queryParams) {
		updateQueryParamsToUrl(validUrl, options.queryParams);
	}
	return handleRequest(validUrl, requestOptions).then(handleResponse).catch(handleCatch);
}

function updateQueryParamsToUrl(validUrl: URL, queryParams: IQueryParams) {
	for (const [key, value] of Object.entries(queryParams)) {
		if (Array.isArray(value)) {
			value.forEach(val => {
				validUrl.searchParams.append(key, `${val}`);
			});
		} else {
			if (value !== undefined && value !== null) {
				validUrl.searchParams.append(key, `${value}`);
			}
		}
	}
}

function handleRequest(url: URL, requestOptions: RequestInit) {
	// Request interceptors
	if (ask.interceptors.requests.size > 0) {
		ask.interceptors.requests.forEach((interceptor: IRequestInterceptor) => {
			if (interceptor.call) {
				interceptor.call(url, requestOptions);
			}
		});
	}
	const timeoutController = new AbortController();
	const timeoutSignal = timeoutController.signal;
	const ABORT_TIMEOUT = 90 * 1000;

	setTimeout(() => timeoutController.abort('client timeout'), ABORT_TIMEOUT);

	return fetch(url.toString(), { signal: externalSignal || timeoutSignal, ...requestOptions });
}

async function handleResponse(response: Response, isDownload?: boolean): Promise<any> {
	const _response = await new Promise((res, rej) => {
		response.text().then(text => {
			externalSignal = null;

			let data = null;
			try {
				if (!isDownload) {
					data = text && JSON.parse(text);
				} else {
					data = new Blob([text]);
				}
			} catch (error) {
				rej({ response, data: { message: error } });
			}

			if (!response.ok) {
				rej({ data, response }); // TODO - add IAskError interface
			}

			// Response interceptors success
			if (ask.interceptors.responses.size > 0) {
				ask.interceptors.responses.forEach((interceptor: IResponseInterceptor) => {
					if (interceptor.success) {
						interceptor.success(response);
					}
				});
			}
			res(data);
		});
	});

	return _response;
}

// TODO - all requests should be using this
async function handleCompleteResponse<TBody>(response: Response): Promise<FullResponse<TBody>> {
	const body: TBody = await new Promise((res, rej) => {
		response.text().then(text => {
			externalSignal = null;
			let data = null;
			try {
				data = text && JSON.parse(text);
			} catch (error) {
				//this error will happen only if the text is not json
				rej({ response, data: { message: error } });
			}

			if (!response.ok) {
				rej({ response, data });
			}

			// Response interceptors success
			if (ask.interceptors.responses.size > 0) {
				ask.interceptors.responses.forEach((interceptor: IResponseInterceptor) => {
					if (interceptor.success) {
						interceptor.success(response);
					}
				});
			}
			res(data);
		});
	});

	const headers = Array.from(response.headers.entries());

	return { body, headers };
}

// IAskError in case of http error
// Error in case of something is work with the request
function handleCatch(catchErr: IAskError | Error) {
	let askError: IAskError = {};
	if (catchErr instanceof Error) {
		askError.data = catchErr;
		askError.response = undefined;
	} else {
		askError = catchErr;
	}
	externalSignal = null;
	if (ask.interceptors.responses.size > 0) {
		ask.interceptors.responses.forEach((interceptor: IResponseInterceptor) => {
			if (interceptor.error) {
				interceptor.error(askError.response, askError.data);
			}
		});
	}
	return Promise.reject(askError);
}

const interceptors: IInterceptors = {
	responses: new Map(),
	requests: new Map(),
};

let externalSignal: AbortSignal | null = null;

// Currently interceptors do not change the data
export const ask = {
	get,
	post,
	download,
	upload,
	put,
	patch,
	delete: _delete,
	getWithHeaders,
	postWithHeaders,
	interceptors,
	addSignal: (signal: AbortSignal) => {
		externalSignal = signal;
	},
};
