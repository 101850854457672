import { NotificationsStore } from '@monorepo/controlled/src/stores/notifications.store';
import { AuthStore } from '@monorepo/controlled/src/stores/auth.store';
import { FormStore } from '@monorepo/controlled/src/stores/form.store';
import { DateStore } from '@monorepo/controlled/src/stores/date.store';
import { ModalsStore } from '@monorepo/controlled/src/stores/modals.store';
import { ToastsStore } from '@monorepo/controlled/src/stores/toasts.store';
import { SidebarStore } from '@monorepo/controlled/src/stores/sidebar.store';
import { CampaignStore } from './campaigns/campaign.store';
import { AdvertiserStore } from './advertisers/advertiser.store';
import { AccountStore } from './accounts/account.store';
import { UserStore } from './users/user.store';
import { ChannelStore } from './channels/channel.store';
import { AnnouncementsStore } from '@monorepo/controlled/src/stores/announcements.store';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { SourcesStore } from './sources/source.store';
import { BulkCampaignsStore } from './campaigns/bulk-campaigns/bulk-campaigns.store';
import { ReviewCampaignsStore } from './campaigns/review-campaigns/review-campaigns.store';
import { TooltipsStore } from '@monorepo/controlled/src/stores/tooltips.store';

export interface IMFPStore {
	campaignStore: CampaignStore;
	bulkCampaignsStore: BulkCampaignsStore;
	reviewCampaignsStore: ReviewCampaignsStore;
	advertiserStore: AdvertiserStore;
	accountStore: AccountStore;
	channelStore: ChannelStore;
	sourcesStore: SourcesStore;
	sidebarStore: SidebarStore;
	toastsStore: ToastsStore;
	modalsStore: ModalsStore;
	authStore: AuthStore;
	formStore: FormStore;
	notificationsStore: NotificationsStore;
	dateStore: DateStore;
	userStore: UserStore;
	announcementsStore: AnnouncementsStore;
	settingsStore: SettingsStore;
	tooltipsStore: TooltipsStore;
}

export class MFPStore implements IMFPStore {
	public accountStore: AccountStore;
	public advertiserStore: AdvertiserStore;
	public authStore: AuthStore;
	public campaignStore: CampaignStore;
	public bulkCampaignsStore: BulkCampaignsStore;
	public reviewCampaignsStore: ReviewCampaignsStore;
	public channelStore: ChannelStore;
	public sourcesStore: SourcesStore;
	public dateStore: DateStore;
	public formStore: FormStore;
	public modalsStore: ModalsStore;
	public notificationsStore: NotificationsStore;
	public sidebarStore: SidebarStore;
	public toastsStore: ToastsStore;
	public userStore: UserStore;
	public announcementsStore: AnnouncementsStore;
	public settingsStore: SettingsStore;
	public tooltipsStore: TooltipsStore;

	constructor() {
		this.settingsStore = new SettingsStore();
		this.formStore = new FormStore();
		this.authStore = new AuthStore();
		this.userStore = new UserStore();
		this.notificationsStore = new NotificationsStore();
		this.sidebarStore = new SidebarStore();
		this.toastsStore = new ToastsStore();
		this.modalsStore = new ModalsStore();
		this.dateStore = new DateStore();
		this.campaignStore = new CampaignStore(this.settingsStore);
		this.bulkCampaignsStore = new BulkCampaignsStore(this.settingsStore);
		this.reviewCampaignsStore = new ReviewCampaignsStore(this.settingsStore);
		this.advertiserStore = new AdvertiserStore(this.settingsStore);
		this.accountStore = new AccountStore(this.settingsStore);
		this.channelStore = new ChannelStore(this.settingsStore);
		this.sourcesStore = new SourcesStore(this.settingsStore);
		this.announcementsStore = new AnnouncementsStore();
		this.tooltipsStore = new TooltipsStore();
	}
}
