import { FC, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { MainNavbar } from '../../../modules/components/main-navbar/main-navbar';
import { Tabs } from '@monorepo/base/src/components/tabs/tabs';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import routes from '../../../modules/routes/index.routes';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { Outlet } from 'react-router-dom';

export const BulkCampaigns: FC = () => {
	const navigate = useNavigateParams();
	return (
		<Fragment>
			<Helmet>
				<title>Manifesto - Bulk Campaigns</title>
			</Helmet>
			<MainNavbar
				BeginNavbar={
					<SecondaryText
						onClick={() => navigate(routes.campaigns.index())}
						icon={'chevron-left'}
						iconSize={'20px'}
						width={'110px'}>
						Campaigns
					</SecondaryText>
				}
			/>
			<SpacerY y={4} />
			<Tabs
				tabs={[
					{
						route: routes.campaigns.bulkOptions(),
						name: 'Bulk Options',
					},
					{
						route: routes.campaigns.bulkHistory(),
						name: 'Uploaded files',
					},
				]}
			/>
			<Outlet />
		</Fragment>
	);
};
