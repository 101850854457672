// https://www.meticulous.ai/blog/getting-started-with-react-logging

export interface IConsoleLoggerOptions {
	message?: any;
	optionalParams: any[];
}

export interface IConsoleLogger {
	log: LogFn;
	warn: LogFn;
	error: LogFn;
}

export type LogLevel = 'log' | 'warn' | 'error';

export type LogFn = (message?: any, ...optionalParams: any[]) => void;

const NO_OP: LogFn = (options: IConsoleLoggerOptions) => {};

export class ConsoleLogger implements IConsoleLogger {
	readonly log: LogFn;
	readonly warn: LogFn;
	readonly error: LogFn;

	constructor(options?: { level: LogLevel }) {
		const { level } = options || {};
		this.error = console.error.bind(console);

		if (level === 'error') {
			this.warn = NO_OP;
			this.log = NO_OP;

			return;
		}

		this.warn = console.warn.bind(console);

		if (level === 'warn') {
			this.log = NO_OP;

			return;
		}

		this.log = console.log.bind(console);
	}
}
