import { id } from '@monorepo/tools/src/lib/types/primitives';
import { Strategies } from '../../enums/strategies';
import { CrudEndpoint } from './crud.endpoint';

export class CampaignsEndpoint extends CrudEndpoint {
	constructor() {
		super('campaigns');
	}

	enableCampaigns() {
		return `${this.baseUrl}/status/ACTIVE`;
	}
	pauseCampaigns() {
		return `${this.baseUrl}/status/PAUSED`;
	}

	bulkCampaignsList() {
		return `${this.baseUrl}/bulk`;
	}

	bulkCampaign(id: id) {
		return `${this.baseUrl}/bulk/${id}`;
	}

	optimizationDefaults() {
		return `${this.baseUrl}/optimizationDefaults`;
	}

	csvDownloadTemplate(strategyType: Strategies) {
		return `${this.baseUrl}/bulk/download/${strategyType}`;
	}

	manualCsvUpload() {
		return `${this.baseUrl}/bulk/MANUAL`;
	}
	smartCsvUpload() {
		return `${this.baseUrl}/bulk/SMART`;
	}

	publishBulk(id: id) {
		return `${this.baseUrl}/bulk/${id}/publish`;
	}
	downloadReviewedCampaigns(id: id) {
		return `${this.baseUrl}/bulk/${id}/download/reviewed`;
	}
	downloadFileWithErrors(id: id) {
		return `${this.baseUrl}/bulk/${id}/download/errors`;
	}
	downloadPublishedStatusFile(id: id) {
		return `${this.baseUrl}/bulk/${id}/download/status`;
	}
}
