import { observer } from 'mobx-react';
import { FC } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../../modules/routes/index.routes';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { IMFPStore } from '../../../modules/stores';
import { useCampaignHeaders } from '../../../modules/hooks/theaders/campaigns.headers';
import { useCampaignActions } from '../../../modules/hooks/actions/campaign.actions';
import { Entities, PerformancesColumns, useReportsList } from '../../../modules/hooks/apis/use-reports-list';
import { PrimaryLink, SecondaryLink } from '@monorepo/base/src/components/buttons/buttons';
import styles from './../../../styles/pages.module.scss';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { useMetrics } from '../../../modules/hooks/tools/use-metrics';
import { useMetricsGet } from '../../../modules/hooks/tools/use-metrics-get';
import { availablePerformanceToMetrics } from '../../../modules/models/performance.model';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { EmptyCampaignsState } from '../../../modules/components/empty-campaigns-state/empty-campaigns-state';
import { ManifestoChart } from '../../../modules/components/chart/manifesto-chart';
import { Helmet } from 'react-helmet-async';
import { PaginationState } from '@tanstack/react-table';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { isFetchFromBackend } from '../../../modules/helpers/reports.helpers';

const actionsDebugProps = { dataAttrs: [new DataAttribute('id', 'campaigns_actions')] };

const tableDebugProps = { dataAttrs: [new DataAttribute('id', 'campaigns_table')] };

export const Campaigns: FC = observer(() => {
	const { campaignStore } = useStores<IMFPStore>();
	const reportsStore = campaignStore.getReportsStore();
	const metricsStore = campaignStore.getMetricsStore();
	const reportData = reportsStore.getData();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const { reportsError, fetchReports } = useReportsList(
		reportsStore,
		{
			entity: Entities.Campagins,
			groupBys: [PerformancesColumns.CampaignId],
		},
		undefined,
		campaignStore.getSegments()
	);

	const { metricsError, fetchMetrics } = useMetricsGet(metricsStore, {
		entity: Entities.Chart,
		groupBys: [PerformancesColumns.Date],
	});
	const { columns } = useCampaignHeaders({ summary: reportData?.getSummary(), actionDebugProps: actionsDebugProps });
	const { SelectedActions, FiltersActions, SegmentActions } = useCampaignActions({
		currentSegments: campaignStore.getSegments(),
		debugProps: actionsDebugProps,
	});

	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page unstyled={true} className={styles.article}>
			<Helmet>
				<title>Manifesto - Campaigns</title>
			</Helmet>
			<Pagebar classes={{ pagebar: styles.padding }} debugProps={{ dataAttrs: [new DataAttribute('id', 'pagebar')] }}>
				<PagebarTitle>Campaigns</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
					<PrimaryLink
						icon={'plus'}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'create_campaign')] }}
						to={`${routes.campaigns.create()}`}
						width={'155px'}>
						New Campaign
					</PrimaryLink>
					<SecondaryLink
						icon={'folder-plus'}
						className={styles.bulkOptionsButton}
						iconColor={'#5552ee'}
						textColor={'#5552ee'}
						style={{ borderColor: '#5552ee' }}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'bulk_options')] }}
						to={`${routes.campaigns.bulkOptions()}`}
						width={'155px'}>
						Bulk Options
					</SecondaryLink>
				</PagebarActions>
			</Pagebar>
			<ManifestoChart
				isLoading={metricsStore.getIsLoading()}
				metrics={metrics}
				labels={xLabels}
				legendOptions={Object.keys(availablePerformanceToMetrics)}
				isError={Boolean(metricsError)}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'chart')] }}
			/>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				classes={{ wrapper: styles.padding }}
				label={'Campaigns'}
				totalRows={reportData?.getTotalRows() ? reportData?.getTotalRows()?.toLocaleString() : undefined}
				totalFilteredRows={reportData?.getTotalFilteredRows() ? reportData?.getTotalFilteredRows()?.toLocaleString() : undefined}
				debugProps={tableDebugProps}
				columns={columns}
				emptyState={<EmptyCampaignsState to={routes.campaigns.create()} />}
				data={reportData?.getData() || []}
				isLoading={reportsStore.getIsLoading()}
				isWithPerformance={true}
				isLoadingPerformance={reportsStore.getIsLoading()}
				defaultSortBy={[{ id: 'revenue', desc: true }]}
				isError={Boolean(reportsError)}
				fetchReports={fetchReports}
				fetchMetrics={fetchMetrics}
				onPageChange={(state: PaginationState) => {
					if (isFetchFromBackend(reportData, state) && !reportsStore.getIsLoading()) {
						fetchReports({ appendData: true, resetOffset: false });
					}
				}}>
				{({ sortedRows }) => {
					campaignStore.setSortedRows(sortedRows);

					return {
						SelectedActions,
						FiltersActions,
						SegmentActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
