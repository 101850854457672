import { InputDesc, InputInfo, InputTitle } from '@monorepo/base/src/components/form/input-item/input-item';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { IMFPStore } from '../../../../stores';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { CampaignDomIds } from '../../../../hooks/toc/campaign.toc';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { BudgetInput } from '../campaign-common.form';

export const ManualStrategy = observer(() => {
	const { campaignStore } = useStores<IMFPStore>();
	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();

	return (
		<div id={CampaignDomIds.DefaultBid}>
			<Flex maxWidth={'620px'}>
				<Flex justifyContent={'flex-start'}>
					<InputInfo>
						<InputTitle>Channels Bid</InputTitle>
						<InputDesc>Enter the amount you want to spend per channel</InputDesc>
					</InputInfo>
					<BudgetInput
						value={campaign.getDefaultBid()?.toString()}
						setValue={value => campaign.setDefaultBid(parseFloat(value))}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'defaultBid')] }}
					/>
				</Flex>
			</Flex>
			<SpacerY />
		</div>
	);
});
