import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import routes from '../../routes/index.routes';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AccountModel, IAccount } from '../../models/account.model';
import { IEntityHeaders, IMergedHeadersReturn, renderSubRow } from './index.headers';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { IPerformanceItem } from '../../models/performance.model';
import { createColumnHelper } from '@tanstack/react-table';
import { IMFPStore } from '../../stores';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { StatusType, mapToTagStatus, mapToIcon } from '../../enums/status';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import { sentenceCase } from 'change-case';
import styles from './headers.module.scss';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';

const columnHelper = createColumnHelper<IAccount & IPerformanceItem>();

export const useAccountHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<AccountModel> => {
	const { EntityActions, onEdit, summary } = props;
	const { currentRouteWithoutSpecialChars } = useRoute();
	const { sidebarStore, settingsStore } = useStores<IMFPStore>();
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				id: false,
				impressions: false,
				epc: false,
				win_rate: false,
				conv_val: false,
			});
			tableStore.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => <TableSelectionHeader headerProps={headerProps} />,
				cell: cellProps => {
					const { row } = cellProps;
					const account = new AccountModel(row.original);

					if (isSubRow(row)) {
						return <span></span>;
					}
					if (!EntityActions) {
						return null;
					}
					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<QuickActionsMenu>
								<EntityActions entity={account} />
							</QuickActionsMenu>
						</TableMergeColumn>
					);
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('name', {
				header: 'Name',
				enableHiding: false,
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return renderSubRow({ row });
					}
					const toEdit = (() => {
						if (onEdit) {
							return onEdit(row.getValue('id'));
						}
						return routes.accounts.edit(row.getValue('id'));
					})();
					return (
						<TableLink
							to={routes.accounts.advertisers(row.getValue('id'))}
							toEdit={toEdit}
							toEditState={{ isBlockRequest: true } as BetweenPagesStateProps}
							onClick={() => {
								if (!toEdit) {
									sidebarStore.setSidebarOpen(false);
								}
							}}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('status', {
				header: 'Status',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return <span></span>;
					}
					const status = row.getValue('status') as string;
					const mappedStatus = mapToIcon(status?.toUpperCase() as StatusType);
					return (
						<Tag type={mapToTagStatus(status?.toUpperCase() as StatusType)}>
							<Icon
								className={styles[mappedStatus.className]}
								size={'13px'}
								color={mappedStatus.color}
								font={IconFonts.Outlined}
								isMFP={true}>
								{mappedStatus.icon}
							</Icon>
							{`${sentenceCase(row.getValue('status'))}`}
						</Tag>
					);
				},
			}),
			...mergedPerforamnceHeaders,
		],
	};
};
