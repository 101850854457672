import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Fragment, useEffect } from 'react';
import { PrimaryButton, SecondaryButton, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { Nav, NavPage } from '@monorepo/base/src/components/nav/nav';
import { route } from '@monorepo/tools/src/lib/types/url';
import { IMFPStore } from '../../../modules/stores';
import { CampaignForm } from '../../../modules/components/forms/campaign/campaign.form';
import { useCampaignTableOfContents } from '../../../modules/hooks/toc/campaign.toc';
import { TableOfContents } from '@monorepo/base/src/components/table-of-contents/table-of-contents';
import { useLayoutContext } from '../../../modules/hooks/useLayoutContext';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { useCampaignGet } from '../../../modules/hooks/apis/use-campaign-get';
import styles from '../../../modules/components/forms/campaign/campaign-form.module.scss';
import { useList } from '../../../modules/hooks/use-list';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { StatusEnum } from '../../../modules/enums/status';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { sentenceCase } from 'change-case';
import { Strategies } from '../../../modules/enums/strategies';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { Helmet } from 'react-helmet-async';
import routes from '../../../modules/routes/index.routes';

interface IEditCampaign {
	parentRoute?: route;
}

export const EditCampaign = observer((props: IEditCampaign) => {
	const { parentRoute } = props;
	const { campaignStore, advertiserStore } = useStores<IMFPStore>();
	const { dispatchLog } = useLogs();
	const campaignCrudStore = campaignStore.getCrud();
	const { setIsTableOfContents } = useLayoutContext();
	const { tableOfContents } = useCampaignTableOfContents();
	// Fixes not rerendering table of content
	campaignCrudStore.getFormStore().getErrors();

	useList(advertiserStore.getListStore());

	const navigate = useNavigateParams();
	const params = useParams();
	if (params.campaignId) {
		useCampaignGet({ id: params.campaignId });
	}

	const setDefaultBidOnAction = () => {
		if (campaign.getStrategyType() === Strategies.Manual) {
			campaign.getChannels()?.forEach(channel => {
				if (!channel.getDefaultBid()) {
					channel.setDefaultBid(channel?.getSuggestedBid() as number);
				}
			});
		}
	};

	useEffect(() => {
		return () => {
			campaignCrudStore.reset();
		};
	}, [location]);

	useEffect(() => {
		if (parentRoute) {
			setIsTableOfContents(true);
		}
	}, []);

	const campaign = campaignCrudStore.getData();
	const name = campaign.getName();
	const status = campaign.getStatus();
	const isDraftStatus = status === StatusEnum.DRAFT;
	const isLoading = campaignCrudStore.getIsLoading();
	const isSuccess = campaignCrudStore.getIsSuccess();

	useEffect(() => {
		if (isSuccess) {
			dispatchLog({
				msg: `Campaign ${name} was successfully edited`,
				type: DispatchLogsTypes.Success,
			});
			campaignCrudStore.setIsSuccess(false);
			if (!isDraftStatus) {
				navigate(routes.campaigns.index());
			}
		}
	}, [isSuccess]);

	const onEditCampaign = () => {
		setDefaultBidOnAction();
		campaignStore.getCrud().edit();
	};

	// On edit draft only
	const onPublishDraft = () => {
		setDefaultBidOnAction();
		campaignCrudStore.setIsEdited(false);
		campaignCrudStore.publishDraft();
	};

	return (
		<Fragment>
			<BarLoader is={isLoading} />
			<Disable is={isLoading}>
				<Helmet>
					<title>Edit Campaign {campaign.getName() || ''}</title>
				</Helmet>
				<BottomBar>
					<Flex height={'100%'}>
						<SecondaryText onClick={() => navigate(-1)} icon={'chevron-left'} iconSize={'20px'} width={'70px'}>
							Back
						</SecondaryText>
						<BottomBarActions>
							{isDraftStatus ? (
								<Fragment>
									<SecondaryButton
										isRotateIcon={!isDraftStatus && isLoading}
										icon={!isDraftStatus && isLoading ? 'sync' : ''}
										onClick={() => campaignCrudStore.saveDraft()}
										debugProps={{ dataAttrs: [new DataAttribute('id', 'save_draft_campaign_button')] }}>
										Save Draft
									</SecondaryButton>
									<PrimaryButton
										onClick={() => onPublishDraft()}
										debugProps={{ dataAttrs: [new DataAttribute('id', 'publish_campaign_button')] }}>
										Publish
									</PrimaryButton>
								</Fragment>
							) : (
								<Flex width={'180px'}>
									<Toggle
										label={
											campaign.getStatus() === StatusEnum.ACTIVE
												? sentenceCase(StatusEnum.ACTIVE)
												: sentenceCase(StatusEnum.PAUSED)
										}
										onChange={status => campaign.setStatus(status ? StatusEnum.ACTIVE : StatusEnum.PAUSED)}
										defaultValue={campaign.getStatus() === StatusEnum.ACTIVE}
										debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_status')] }}
									/>
									<PrimaryButton
										width={'63px'}
										onClick={() => onEditCampaign()}
										debugProps={{ dataAttrs: [new DataAttribute('id', 'save_campaign_button')] }}>
										Save
									</PrimaryButton>
								</Flex>
							)}
						</BottomBarActions>
					</Flex>
				</BottomBar>
				<NavPage>
					<Nav classes={{ nav: styles.tableOfContentsWrapper }}>
						<TableOfContents
							heads={tableOfContents}
							offset={100}
							observerOptions={{ threshold: 0.1, rootMargin: '-70px 0px -50% 0px', intersectionRatio: 0.1 }}
						/>
					</Nav>
					<Page>
						<Pagebar>
							<PagebarTitle>Edit Campaign</PagebarTitle>
						</Pagebar>
						<CampaignForm />
					</Page>
				</NavPage>
			</Disable>
		</Fragment>
	);
});
