import { Page } from '@monorepo/base/src/components/page/page';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';
import styles from './../../../../styles/pages.module.scss';
import bulkStyles from './bulk-options.module.scss';
import { Card } from '@monorepo/base/src/components/card/card';
import { PrimaryButton, SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { DragAndDrop } from '@monorepo/base/src/components/form/drag-and-drop/drag-and-drop';
import { Upload } from '@monorepo/base/src/components/form/upload/upload';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { ManifestoGButton } from '../../../../modules/components/base-wrappers/g-button/g-button';
import { Strategies } from '../../../../modules/enums/strategies';
import { useEffect, useState } from 'react';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useReviewCampaignsHeaders } from '../../../../modules/hooks/theaders/review-campaigns.headers';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../../../modules/stores';
import { useDownload } from '@monorepo/tools/src/lib/hooks/tools/use-download';
import { ReviewCampaignsModel, ReviewCampaignsPayload } from '../../../../modules/models/review-campaigns.model';
import { CampaignsApi } from '../../../../modules/apis/campaigns.api';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { IAskError } from '@monorepo/tools/src/lib/tools/ask/ask';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';

export const BulkOptions = observer(() => {
	const { reviewCampaignsStore, notificationsStore } = useStores<IMFPStore>();
	const [strategyType, setStrategyType] = useState<Strategies>(Strategies.Manual);
	const [uploadStrategyTemplate, setUploadStrategyTemplate] = useState<HttpStore<File, ReviewCampaignsPayload | null, IAskError>>(
		reviewCampaignsStore.getUploadManualTemplate()
	);
	const { currentRouteWithoutSpecialChars } = useRoute();
	const [fileName, setFileName] = useState<string>('');
	const [forbidPublish, setForbidPublish] = useState<boolean>(true);
	const [bulkId, setBulkId] = useState<id | undefined>();
	const { columns, columnVisibility } = useReviewCampaignsHeaders({ strategyType });
	const listLength = reviewCampaignsStore.getListStore().getList().length;
	const [isPublishLoader, setPublishLoader] = useState<boolean>(false);

	useEffect(() => {
		//cuz httpstore by default loading is true and i can't remember why
		uploadStrategyTemplate.setIsLoading(false);
		uploadStrategyTemplate.setIsLoading(false);
		uploadStrategyTemplate.setHttpError(null);
		uploadStrategyTemplate.setHttpError(null);

		return () => {
			setFileName('');
			setBulkId(undefined);
			setPublishLoader(false);
			reviewCampaignsStore.getListStore().reset();
			reviewCampaignsStore.getUploadManualTemplate().reset();
			reviewCampaignsStore.getUploadSmartTemplate().reset();
		};
	}, [uploadStrategyTemplate]);

	useEffect(() => {
		setUploadStrategyTemplate(
			strategyType === Strategies.Manual
				? reviewCampaignsStore.getUploadManualTemplate()
				: reviewCampaignsStore.getUploadSmartTemplate()
		);
	}, [strategyType]);

	const downloadTemplate = (strategyType: Strategies) => {
		const httpStore =
			strategyType === Strategies.Manual ? reviewCampaignsStore.getManualTemplate() : reviewCampaignsStore.getSmartTemplate();
		useDownload({ httpStore, fileName: `${strategyType.toLowerCase()}-template`, extension: 'csv' });
	};

	const onUpload = (file: File | FileList) => {
		uploadStrategyTemplate.reset();

		if (file instanceof File) {
			setFileName(file.name);
			uploadStrategyTemplate.fetch(file).then(res => {
				const campaigns = res?.item.campaigns.map(campaign => new ReviewCampaignsModel(campaign)) || [];
				reviewCampaignsStore.getListStore().setList(campaigns);
				setForbidPublish(
					reviewCampaignsStore
						.getListStore()
						.getList()
						.some(campaign => Object.keys(campaign.Errors || []).length > 0)
				);

				if (res?.item.bulkCampaign) {
					setBulkId(res?.item.bulkCampaign.id);
				}
			});
		}
	};

	const onPublish = (bulkId: id) => {
		setPublishLoader(true);
		CampaignsApi.publishBulk(bulkId).then(() => {
			notificationsStore.setIsOpenMenu(true);
			notificationsStore.addNotification({
				type: DispatchLogsTypes.PublishingProgress,
				date: new Date(),
				extraData: {
					bulkId,
					createdAmount: 0,
					updatedAmount: 0,
					errorsAmount: 0,
					totalCampaigns: 0,
					percentage: 0,
					fileName,
					strategyType,
				},
			});
			reviewCampaignsStore.getListStore().reset();
			setFileName('');
			setPublishLoader(false);
		});
	};

	const getDataAndFillEmpty = () => {
		const emptyRows = 6 - listLength;
		const emptyData = Array.from({ length: emptyRows }, () => ({}));

		return {
			data: [...reviewCampaignsStore.getListStore().getList(), ...emptyData],
			totalRows: listLength.toString(),
		};
	};

	const onDownloadFile = (bulkId: id) => {
		useDownload({
			httpStore: new HttpStore<undefined, File | null>({
				httpFunc: () => CampaignsApi.downloadReviewedCampaigns(bulkId),
			}),
			fileName: `${fileName}-reviewed`,
			extension: 'csv',
		});
	};

	return (
		<Page unstyled={true} className={`${styles.article} ${bulkStyles.wrapper}`}>
			<Helmet>
				<title>Manifesto - Bulk Options</title>
			</Helmet>
			<div className={bulkStyles.downloadAndUpload}>
				<Card className={bulkStyles.downloadCard} full>
					<span className={bulkStyles.title}>Template for bulk campaigns uploads</span>
					<div className={bulkStyles.bullets}>
						<div className={bulkStyles.bullet}>
							<Icon isMFP={true} color={getTheme().primaryColor}>
								check
							</Icon>
							<span>Download the template and fill it out with your campaigns.</span>
						</div>
						<div className={bulkStyles.bullet}>
							<Icon isMFP={true} color={getTheme().primaryColor}>
								check
							</Icon>
							<span>Choose a bidding strategy and upload the file.</span>
						</div>
						<div className={bulkStyles.bullet}>
							<Icon isMFP={true} color={getTheme().primaryColor}>
								check
							</Icon>
							<span>Take a moment to review your campaigns before publishing.</span>
						</div>
					</div>
					<div className={bulkStyles.downloadBtns}>
						<PrimaryButton
							className={bulkStyles.btn}
							icon={'download-01'}
							iconSize={'12px'}
							onClick={() => downloadTemplate(Strategies.Manual)}>
							Manual Template
						</PrimaryButton>
						<PrimaryButton
							className={bulkStyles.btn}
							icon={'download-01'}
							iconSize={'12px'}
							onClick={() => downloadTemplate(Strategies.Smart)}>
							Smart Template
						</PrimaryButton>
					</div>
				</Card>
				<Card className={bulkStyles.uploadCard} full>
					<span className={bulkStyles.title}>Choose your bidding strategy and upload your file</span>
					<div className={bulkStyles.toggleAndUpload}>
						<ButtonGroup
							flexDirection={'column'}
							required={true}
							defaultValue={strategyType}
							onChange={setValues => {
								reviewCampaignsStore.getListStore().reset();
								setFileName('');
								setStrategyType(setValues.values().next().value as Strategies);
							}}>
							<ManifestoGButton classNames={bulkStyles.gBtn} value={Strategies.Manual} title={'Manual'} icon={'sliders-01'} />
							<ManifestoGButton
								classNames={bulkStyles.gBtn}
								value={Strategies.Smart}
								title={'Smart'}
								icon={'magic-wand-01'}
							/>
						</ButtonGroup>
						<DragAndDrop isLoading={uploadStrategyTemplate?.getIsLoading() && fileName.length > 0} onDrop={onUpload}>
							<div className={bulkStyles.dragAndDrop}>
								<Icon className={bulkStyles.icon} isMFP={true}>
									upload-cloud-02
								</Icon>
								<div className={bulkStyles.textWrapper}>
									<span className={bulkStyles.actionText}>
										<Upload onUpload={onUpload}>
											<span className={bulkStyles.highlightText}>Click to upload</span>
										</Upload>
										&nbsp;or drag and drop
									</span>
									<span className={bulkStyles.description}>only .csv file (max. 10mb)</span>
								</div>
								{uploadStrategyTemplate.getHttpError() !== null ? (
									<div className={bulkStyles.errorUpload}>
										<Icon className={bulkStyles.errorIcon} isMFP>
											file-04
										</Icon>
										<div>
											<div className={bulkStyles.errorUploadMessage}>Upload failed, please try again</div>
											<div className={bulkStyles.errorFileName}>{fileName}</div>
										</div>
									</div>
								) : null}
							</div>
						</DragAndDrop>
					</div>
				</Card>
			</div>
			<div className={bulkStyles.reviewTitle}>Review campaigns before publishing</div>
			<div className={bulkStyles.tableWrapper}>
				<div className={bulkStyles.tableTopSection}>
					<div className={bulkStyles.left}>
						<div className={bulkStyles.filename}>Filename: {fileName}</div>
						<div>
							<span>Total:</span> {getDataAndFillEmpty().totalRows} Campaigns
						</div>
					</div>
					<div className={bulkStyles.right}>
						<SecondaryButton
							onClick={() => {
								if (bulkId) {
									onDownloadFile(bulkId);
								}
							}}
							disabled={listLength === 0}
							icon={'download-01'}
							// className={`${bulkStyles.downloadFileBtn} ${listLength > 0 ? bulkStyles.active : null}`}
						>
							Download File
						</SecondaryButton>
					</div>
				</div>
				<ControlledTable
					classes={{ wrapper: bulkStyles.table }}
					columns={columns}
					columnVisibility={columnVisibility}
					data={getDataAndFillEmpty().data}
					totalRows={getDataAndFillEmpty().totalRows}
					isLoading={reviewCampaignsStore.getListStore().getIsLoading()}
					isError={reviewCampaignsStore.getListStore().getError() !== null}
					isActionLiner={false}
					currentRoute={currentRouteWithoutSpecialChars}
					isEmptyAndDisabled={listLength === 0}
				/>
			</div>
			<div className={bulkStyles.publishWrapper}>
				{forbidPublish && fileName.length > 0 && (
					<span className={bulkStyles.hint}>
						<Icon isMFP>info-circle</Icon> There are errors in your file. Please address them and upload a new CSV file.
					</span>
				)}
				<PrimaryButton
					className={bulkStyles.publishBtn}
					disabled={forbidPublish}
					icon={`${isPublishLoader ? 'loading-01' : ''}`}
					isRotateIcon={isPublishLoader}
					onClick={() => {
						if (bulkId) {
							onPublish(bulkId);
						}
					}}>
					Publish
				</PrimaryButton>
			</div>
		</Page>
	);
});
