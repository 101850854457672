import { IInjectHeadItem } from '@monorepo/base/src/components/table-of-contents/table-of-contents';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SubTocItem, TocItem } from '../../components/base-wrappers/toc-item/toc-item';
import { Strategies } from '../../enums/strategies';
import { IMFPStore } from '../../stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { StatusEnum } from '../../enums/status';

export enum CampaignDomIds {
	Profile = 'campaign-profile',
	Advertiser = 'campaign-advertiser',
	Name = 'campaign-name',
	LandingPage = 'campaign-landing-page',
	Delivery = 'campaign-delivery',
	Dates = 'campaign-dates',
	Targeting = 'campaign-targeting',
	Devices = 'campaign-devices',
	Geos = 'campaign-geos',
	BrowserLanguages = 'campaign-browser-languages',
	Strategy = 'campaign-strategy',
	TotalBudget = 'campaign-total-budget',
	DailyBudget = 'campaign-daily-budget',
	TrafficChannels = 'campaign-traffic-channels',

	// Smart
	OptimizationMode = 'campaign-smart-optimization-mode',
	OptimizationModeValue = 'campaign-smart-optimization-mode-value',

	// Manual
	ChannelsBid = 'campaign-manual-channels-bid',
	DefaultBid = 'campaign-default-bid',

	// Advanced Bidding Configs
	AdvancedBiddingCheckbox = 'campaign-advanced-bidding-checkbox',
	LearningBudgetPerSource = 'campaign-learning-budget-for-source',
	LearningClicksPerHqSource = 'campaign-learning-clicks-per-hq-source',
	LearningClicksPerRqSource = 'campaign-learning-clicks-per-rq-source',
	MaximumLearningBid = 'campaign-maximum-learning-bid',
	MaximumOptimizingBid = 'campaign-maximum-optimizing-bid',
	CampaignLevelOptimization = 'campaign-campaign-level-optimization',
	StatisticsWindow1 = 'campaign-statistics-window-1',
	StatisticsWindow2 = 'campaign-statistics-window-2',
	MaxFirstConversions = 'campaign-max-first-conversions',
	MinSecondFirstRate = 'campaign-min-second-first-rate',
	OptimizationModeOptimizerValue = 'campaign-optimization-mode-optimizer-value',
}

export const useCampaignTableOfContents = () => {
	const [isTableOfContents, setIsTableOfContents] = useState<boolean>(false);
	const { campaignStore } = useStores<IMFPStore>();
	const { currentRoute } = useRoute();
	const isEditPage = currentRoute.includes('edit');
	const campaign = campaignStore.getCrud().getData();
	const isAdvancedBidding = campaign.getOptimizationOn();
	const isDraftStatus = campaign.getStatus() === StatusEnum.DRAFT;
	const isDisabled = isEditPage && !isDraftStatus;
	const strategyType = campaign.getStrategyType();
	const location = useLocation();
	const formStore = campaignStore.getCrud().getFormStore();

	useEffect(() => {
		return () => setIsTableOfContents(false);
	}, [location]);

	const getStrategySteps = () => {
		const strategySteps = [
			{
				element: (subHeadProps: IInjectHeadItem) => (
					<SubTocItem {...subHeadProps} isError={Boolean(formStore.getErrors().get(CampaignDomIds.TotalBudget)?.getMsg())}>
						Total Budget
					</SubTocItem>
				),
				id: CampaignDomIds.TotalBudget,
				scroll: false,
			},
			{
				element: (subHeadProps: IInjectHeadItem) => (
					<SubTocItem isError={Boolean(formStore.getErrors().get(CampaignDomIds.DailyBudget)?.getMsg())} {...subHeadProps}>
						Daily Budget
					</SubTocItem>
				),
				id: CampaignDomIds.DailyBudget,
				scroll: false,
			},
			{
				element: (subHeadProps: IInjectHeadItem) => (
					<SubTocItem isError={Boolean(formStore.getErrors().get(CampaignDomIds.TrafficChannels)?.getMsg())} {...subHeadProps}>
						Traffic Channels
					</SubTocItem>
				),
				id: CampaignDomIds.TrafficChannels,
				scroll: false,
			},
		];
		if (strategyType === Strategies.Smart) {
			strategySteps.push(
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							{...subHeadProps}
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.OptimizationMode)?.getMsg())}>
							Optimization Mode
						</SubTocItem>
					),
					id: CampaignDomIds.OptimizationMode,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							{...subHeadProps}
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.OptimizationModeValue)?.getMsg())}>
							Optimization Bid
						</SubTocItem>
					),
					id: CampaignDomIds.OptimizationModeValue,
					scroll: false,
				}
			);
			if (!isAdvancedBidding) {
				strategySteps.push({
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							{...subHeadProps}
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.AdvancedBiddingCheckbox)?.getMsg())}>
							Advanced Bidding Options
						</SubTocItem>
					),
					id: CampaignDomIds.AdvancedBiddingCheckbox,
					scroll: false,
				});
			}
		}

		if (strategyType === Strategies.Manual) {
			strategySteps.push({
				element: (subHeadProps: IInjectHeadItem) => (
					<SubTocItem {...subHeadProps} isError={Boolean(formStore.getErrors().get(CampaignDomIds.ChannelsBid)?.getMsg())}>
						Channels Bid
					</SubTocItem>
				),
				id: CampaignDomIds.ChannelsBid,
				scroll: false,
			});
		}

		return strategySteps;
	};

	const getAdvancedBiddingSteps = () => {
		if (strategyType === Strategies.Manual || !isAdvancedBidding) {
			return [];
		}

		const getAdvancedSteps = () => {
			if (!isAdvancedBidding) {
				return [];
			}
			return [
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.LearningBudgetPerSource)?.getMsg())}
							{...subHeadProps}>
							Learning Budget For Source
						</SubTocItem>
					),
					id: CampaignDomIds.LearningBudgetPerSource,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.LearningClicksPerHqSource)?.getMsg())}
							{...subHeadProps}>
							Learning Clicks Per HQ Source
						</SubTocItem>
					),
					id: CampaignDomIds.LearningClicksPerHqSource,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.LearningClicksPerRqSource)?.getMsg())}
							{...subHeadProps}>
							Learning Clicks Per RQ Source
						</SubTocItem>
					),
					id: CampaignDomIds.LearningClicksPerRqSource,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.MaximumLearningBid)?.getMsg())}
							{...subHeadProps}>
							Maximum Learning Bid
						</SubTocItem>
					),
					id: CampaignDomIds.MaximumLearningBid,
					scroll: false,
				},
				{
					element: () => <div>&nbsp;</div>,
					id: '',
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.MaximumOptimizingBid)?.getMsg())}
							{...subHeadProps}>
							Maximum Optimizing Bid
						</SubTocItem>
					),
					id: CampaignDomIds.MaximumOptimizingBid,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.CampaignLevelOptimization)?.getMsg())}
							{...subHeadProps}>
							Campaign Level Optimization
						</SubTocItem>
					),
					id: CampaignDomIds.CampaignLevelOptimization,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.StatisticsWindow1)?.getMsg())}
							{...subHeadProps}>
							Statistics Window - Primary
						</SubTocItem>
					),
					id: CampaignDomIds.StatisticsWindow1,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.StatisticsWindow2)?.getMsg())}
							{...subHeadProps}>
							Statistics Window - Secondary
						</SubTocItem>
					),
					id: CampaignDomIds.StatisticsWindow2,
					scroll: false,
				},
				// {
				// 	element: (subHeadProps: IInjectHeadItem) => (
				// 		<SubTocItem
				// 			isError={Boolean(formStore.getErrors().get(CampaignDomIds.MaxFirstConversions)?.getMsg())}
				// 			{...subHeadProps}>
				// 			Max First Conversions
				// 		</SubTocItem>
				// 	),
				// 	id: CampaignDomIds.MaxFirstConversions,
				// 	scroll: false,
				// },
				// {
				// 	element: (subHeadProps: IInjectHeadItem) => (
				// 		<SubTocItem
				// 			isError={Boolean(formStore.getErrors().get(CampaignDomIds.MinSecondFirstRate)?.getMsg())}
				// 			{...subHeadProps}>
				// 			Min Second First Rate
				// 		</SubTocItem>
				// 	),
				// 	id: CampaignDomIds.MinSecondFirstRate,
				// 	scroll: false,
				// },
			];
		};

		return [
			{
				id: CampaignDomIds.AdvancedBiddingCheckbox,
				element: (headProps: IInjectHeadItem) => (
					<TocItem {...headProps} title={'Advanced Bidding Options'} steps={getAdvancedSteps()} />
				),
			},
		];
	};

	return {
		isTableOfContents,
		tableOfContents: [
			{
				id: CampaignDomIds.Profile,
				element: (headProps: IInjectHeadItem) => (
					<TocItem
						title={'Campaign Profile'}
						steps={[
							{
								element: (subHeadProps: IInjectHeadItem) => (
									<SubTocItem
										{...subHeadProps}
										isError={Boolean(formStore.getErrors().get(CampaignDomIds.Name)?.getMsg())}>
										Name
									</SubTocItem>
								),
								id: CampaignDomIds.Name,
								scroll: false,
							},
							{
								element: (subHeadProps: IInjectHeadItem) => (
									<SubTocItem
										{...subHeadProps}
										isDisabled={isDisabled}
										isError={Boolean(formStore.getErrors().get(CampaignDomIds.Advertiser)?.getMsg())}>
										Advertiser
									</SubTocItem>
								),
								id: CampaignDomIds.Advertiser,
								scroll: false,
							},
							{
								element: (subHeadProps: IInjectHeadItem) => (
									<SubTocItem
										{...subHeadProps}
										isError={Boolean(formStore.getErrors().get(CampaignDomIds.LandingPage)?.getMsg())}>
										Landing Page
									</SubTocItem>
								),
								id: CampaignDomIds.LandingPage,
								scroll: false,
							},
						]}
						{...headProps}
					/>
				),
			},
			{
				id: CampaignDomIds.Delivery,
				element: (headProps: IInjectHeadItem) => (
					<TocItem
						title={'Campaign Delivery'}
						steps={[
							{
								element: (subHeadProps: IInjectHeadItem) => (
									<SubTocItem {...subHeadProps} isDisabled={isDisabled}>
										Dates
									</SubTocItem>
								),
								id: CampaignDomIds.Dates,
								scroll: false,
							},
						]}
						{...headProps}
					/>
				),
			},
			{
				id: CampaignDomIds.Targeting,
				element: (headProps: IInjectHeadItem) => (
					<TocItem
						title={'Campaign Targeting'}
						steps={[
							{
								element: (subHeadProps: IInjectHeadItem) => (
									<SubTocItem
										{...subHeadProps}
										isDisabled={isDisabled}
										isError={Boolean(formStore.getErrors().get(CampaignDomIds.Devices)?.getMsg())}>
										Devices
									</SubTocItem>
								),
								id: CampaignDomIds.Devices,
								scroll: false,
							},
							{
								element: (subHeadProps: IInjectHeadItem) => (
									<SubTocItem
										{...subHeadProps}
										isDisabled={isDisabled}
										isError={Boolean(formStore.getErrors().get(CampaignDomIds.Geos)?.getMsg())}>
										Country
									</SubTocItem>
								),
								id: CampaignDomIds.Geos,
								scroll: false,
							},
						]}
						{...headProps}
					/>
				),
			},
			{
				id: CampaignDomIds.Strategy,
				element: (headProps: IInjectHeadItem) => (
					<TocItem title={'Budget and Bidding Strategy'} steps={getStrategySteps()} {...headProps} />
				),
			},
			...getAdvancedBiddingSteps(),
		],
		setIsTableOfContents,
	};
};
