import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AdvertiserModel } from '../../models/advertiser.model';
import { CampaignModel } from '../../models/campaign.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { BreadcrumbMenu } from '@monorepo/base/src/components/breadcrumb-menu/breadcrumb-menu';
import { SkeletonBreadCrumb } from './main-breadcrumbs';
import styles from './main-breadcrumbs.module.scss';
import { AdvertiserBreadcrumb } from './advertiser-page.breadcrumbs';
import { IMFPStore } from '../../stores';
import { useCampaignGet } from '../../hooks/apis/use-campaign-get';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';
import { IQueryParams } from '@monorepo/tools/src/lib/interfaces/url';
import { AccountBreadcrumb } from './account-page.breadcrumbs';
import { Select, SelectAction, SelectActions } from '@monorepo/base/src/components/select/select';
import { StatusEnum } from '../../enums/status';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

interface ICampaignBreadcrumb {
	campaign?: CampaignModel;
	advertiser?: AdvertiserModel;
	isActive?: boolean;
}

const All = 'All';

export const CampaignBreadcrumb = observer((props: ICampaignBreadcrumb) => {
	const { campaign, advertiser, isActive } = props;
	const { campaignStore } = useStores<IMFPStore>();
	const isLoadingCampaigns = campaignStore.getListStore().getIsLoading();
	const isError = campaignStore.getListStore().getError();
	const campaignName = campaign?.getName() || '';
	const advertiserId = advertiser?.getId();
	const navigate = useNavigateParams();
	const advertiserCampaigns = advertiser?.getCampaigns()?.filter(c => c.getStatus() === StatusEnum.ACTIVE) || [];

	const onListItemCampaign = (_campaignName: string, e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		const clickedCampaign = advertiserCampaigns.find(_campaign => _campaign.getName() === _campaignName);
		if (!clickedCampaign) {
			// TODO - log
			return null;
		}
		const clickedCampaignId = clickedCampaign.getId();
		if (!clickedCampaign || !clickedCampaignId) {
			// TODO - log
			return null;
		}
		navigate(`${routes.campaigns.view(clickedCampaignId)}`);
	};

	const onBreadcrumbArrow = () => {
		const params = {
			advertisersIds: advertiser?.getId(),
		};

		campaignStore
			.getListStore()
			.fetch(queryParamsToSearchParams(params as IQueryParams))
			.then(_campaigns => {
				if (!Array.isArray(_campaigns)) {
					return null;
				}
				advertiser?.setCampaigns(_campaigns);
				// setAdvertiserCampaigns(_campaigns.filter(c => c.getStatus() === StatusEnum.ACTIVE));
			});
	};

	const onBreadcrumbLink = () => {
		const campaignId = campaign?.getId();
		if (campaignId) {
			navigate(`${routes.campaigns.view(campaignId)}`);
		}
	};

	return (
		<BreadcrumbMenu
			isError={Boolean(isError)}
			label={'Campaign'}
			title={<span className={isActive ? styles.active : ''}>{campaignName || All}</span>}
			onArrowClick={onBreadcrumbArrow}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_breadcrumbs')] }}
			navigateToModel={onBreadcrumbLink}
			isBarLoader={isLoadingCampaigns}
			isLoading={isLoadingCampaigns && advertiserCampaigns.length === 0}
			skeletonRectanglesAmount={12}>
			<div className={styles.menuWrapper}>
				{advertiserCampaigns && !isLoadingCampaigns ? (
					<Select
						listItemClassName={styles.selectItem}
						placeholder={'Search Campaign'}
						values={advertiserCampaigns.map(_advertiserCampaign => _advertiserCampaign.getName())}
						onClick={onListItemCampaign}
						disabled={isLoadingCampaigns}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_breadcrumbs_select')] }}
						defaultValue={campaignName}
						actions={
							!isLoadingCampaigns && advertiserCampaigns.length === 0 ? (
								<SelectActions>
									<SelectAction
										to={advertiserId ? routes.advertisers.createCampaign(advertiserId) : routes.campaigns.create()}>
										Add a new campaign
									</SelectAction>
								</SelectActions>
							) : null
						}
					/>
				) : null}
			</div>
		</BreadcrumbMenu>
	);
});
interface ICampaignBreadcrumbs {
	campaignId: id;
}

export const CampaignPageBreadcrumbs = observer((props: ICampaignBreadcrumbs) => {
	const { campaignId } = props;
	const { campaignStore } = useStores<IMFPStore>();

	useCampaignGet({ id: campaignId });

	const campaign = campaignStore.getCrud().getData();
	const advertiser = campaign?.getAdvertiser();
	const account = advertiser?.getAccount();

	return (
		<div className={styles.wrapper}>
			{account?.getName() ? <AccountBreadcrumb account={account} /> : <SkeletonBreadCrumb isNext={true} />}
			{advertiser?.getName() ? <AdvertiserBreadcrumb advertiser={advertiser} /> : <SkeletonBreadCrumb isNext={true} />}
			{campaign?.getName() && advertiser?.getName() ? (
				<CampaignBreadcrumb campaign={campaign} advertiser={advertiser} isActive={true} />
			) : (
				<SkeletonBreadCrumb />
			)}
		</div>
	);
});
