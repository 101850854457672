import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { route } from '@monorepo/tools/src/lib/types/url';
import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import routes from '../../../modules/routes/index.routes';
import { Page } from '@monorepo/base/src/components/page/page';
import { NestedNavPage, NestedNav, NestedNavItem, NestedNavGroup } from '../../../modules/components/base-wrappers/nested-nav/nested-nav';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { observer } from 'mobx-react';
import { Directions } from '@monorepo/base/src/components/buttons/buttons';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { Guard } from '../../../modules/components/guard/guard';
import { PermissionActions } from '../../../modules/enums/permissions';
import { validateAction } from '../../../modules/hooks/tools/use-guard';
import { AdvertiserModel } from '../../../modules/models/advertiser.model';
import { EntityNavigation } from '@monorepo/base/src/components/entity-navigation/entity-navigation';
import { IMFPStore } from '../../../modules/stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { SidebarMode } from '@monorepo/base/src/components/sidebar/sidebar';
import { useNestedSidebar } from '@monorepo/controlled/src/hooks/use-nested-sidebar';

export const AdvertiserView = observer(() => {
	const { advertiserStore } = useStores<IMFPStore>();
	const sortedRows = advertiserStore.getSortedRows();
	const { advertiserId } = useParams();
	const navigate = useNavigateParams();
	const { currentRoute } = useRoute();
	useNestedSidebar({ enterMode: SidebarMode.LOCK });

	const [activeRoute, setActiveRoute] = useState<route>(currentRoute);
	useEffect(() => {
		setActiveRoute(currentRoute);
	}, [currentRoute]);

	if (!advertiserId) {
		navigate(`${routes.advertisers.index()}`, { replace: true });
		return null;
	}

	return (
		<NestedNavPage>
			<NestedNav onNavClick={({ to }) => to && setActiveRoute(to)} activeRoute={activeRoute}>
				{/* <NestedNavTitle>{advertiserName}</NestedNavTitle> */}
				<NestedNavItem icon={'bar-chart-square-02'} to={advertiserId ? `${routes.advertisers.view(advertiserId)}` : ''}>
					Overview
				</NestedNavItem>
				<Guard validate={validateAction([PermissionActions.CampaignRead])}>
					<NestedNavItem icon={'announcement-01'} to={advertiserId ? `${routes.advertisers.campaigns(advertiserId)}` : ''}>
						Campaigns
					</NestedNavItem>
				</Guard>
				<NestedNavItem icon={'globe-03'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Landing Pages</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavItem icon={'timeline'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Activity Log</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavItem icon={'settings-01'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Settings</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavGroup title={'Placements'}>
					<NestedNavItem icon={'route'} to={advertiserId ? `${routes.advertisers.channels(advertiserId)}` : ''}>
						Channels
					</NestedNavItem>
				</NestedNavGroup>
			</NestedNav>
			<Page unstyled={true}>
				<EntityNavigation<AdvertiserModel>
					sortedRows={sortedRows}
					entityId={advertiserId}
					navigationRouteFunc={id => routes.advertisers.campaigns(id)}
					entityName={'Advertisers'}
				/>
				<Outlet />
			</Page>
		</NestedNavPage>
	);
});
