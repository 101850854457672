import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './user-menu.module.scss';
import { observer } from 'mobx-react';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import { useState } from 'react';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { Avatar } from '@monorepo/base/src/components/avatar/avatar';
import routes from '../../routes/index.routes';
import { AvatarDetails, AvatarSubtitle, AvatarTitle } from '@monorepo/base/src/components/avatar-details/avatar-details';
import { IMFPStore } from '../../stores';
import { Divider } from '@monorepo/base/src/components/divider/divider';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

export const UserMenu = observer(() => {
	const { authStore, userStore } = useStores<IMFPStore>();
	const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
	const navigate = useNavigateParams();
	const user = userStore.getUser();

	const onSignOut = () => {
		userStore.removeUser();
		authStore.removeToken();
		navigate(routes.general.home(), { replace: true });
		setIsOpenMenu(false);
	};

	if (!user) {
		return null;
	}

	const userLogoName = (firstName?: string, lastName?: string) => {
		const initials = `${firstName?.[0]}${lastName?.[0]}`.toUpperCase();
		return (
			<div className={styles.initialsCircle}>
				<span className={styles.initialsText}>{initials || 'U'}</span>
			</div>
		);
	};

	const UserBar = () => {
		return (
			<div className={styles.userBar} onClick={() => setIsOpenMenu(!isOpenMenu)}>
				{user?.getPicture() && !!userLogoName ? <Avatar url={user?.getPicture()} /> : userLogoName(user.firstName, user.lastName)}
				{/* <span className={styles.name}>{user?.getGivenName()}</span> */}
				{/* <Icon color={'#8a8a8a'} size={'10'} isMFP={true}>
					keyboard_arrow_down
				</Icon> */}
			</div>
		);
	};

	return (
		<div className={styles.wrapper}>
			<UserBar />
			<Menu unstyled={true} className={styles.menu} isOpen={isOpenMenu} onClose={() => setIsOpenMenu(false)}>
				<div className={styles.userMenu}>
					<AvatarDetails className={styles.details} url={user?.getPicture()}>
						<AvatarTitle className={styles.fullName}>{user?.getFullName()}</AvatarTitle>
						<AvatarSubtitle className={styles.email}>{user?.getEmail()}</AvatarSubtitle>
					</AvatarDetails>
				</div>
				<Spacer y={1} />
				<Divider classNames={styles.divider} />
				<div className={styles.actions}>
					<SecondaryText className={styles.logoutWrapper} icon={'log-out-01'} onClick={() => onSignOut()}>
						Log out
					</SecondaryText>
				</div>
			</Menu>
		</div>
	);
});
