import { AdvertiserModel, IAdvertiserCreateForm, IAdvertiserEditForm } from '../../models/advertiser.model';
import { AdvertisersApi } from '../../apis/advertisers.api';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { ErrorPayloadType, IHttpError, HttpError } from '../../models/http-error.model';
import { isValidUrl } from '@monorepo/tools/src/lib/utils/url';

export class AdvertiserCrudStore extends BaseCrudStore<AdvertiserModel, IAdvertiserCreateForm, IAdvertiserEditForm, HttpError> {
	constructor() {
		super({
			apiLayer: AdvertisersApi,
			model: AdvertiserModel,
			errorModel: HttpError,
		});
	}

	/**
	 * Must call isValidAdvertiser before calling this function
	 * @returns
	 */
	public getCreateFormData(): IAdvertiserCreateForm {
		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			categoryId: this.getData().getCategoryId(),
			mainDomain: this.getData().getMainDomain(),
			account: this.getData().getAccount(),
		};
	}

	/**
	 * Must call isValidAdvertiser before calling this function
	 * @returns
	 */
	public getEditFormData(): IAdvertiserEditForm {
		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			categoryId: this.getData().getCategoryId(),
			mainDomain: this.getData().getMainDomain(),
			account: this.getData().getAccount(),
			status: this.getData().getStatus(),
		};
	}

	public isValid(): boolean {
		this.formStore.reset();
		const advertiser = this.getData();

		if (!advertiser.getName()) {
			this.formStore.addError(new FormError('name', 'Please provide a name'));
		}

		if (!advertiser.getMainDomain()) {
			this.formStore.addError(new FormError('mainDomain', 'Please provide a domain'));
		}
		let domain = advertiser.getMainDomain();
		if (domain) {
			if (domain.includes('http://')) {
				domain = domain.replace('http://', 'https://');
			}
			if (!domain.includes('https://')) {
				domain = `https://${domain}`;
			}
			advertiser.setMainDomain(domain);
		}
		domain = advertiser.getMainDomain();
		if (domain && !isValidUrl(domain)) {
			this.formStore.addError(new FormError('mainDomain', 'Please enter a valid URL'));
		}

		if (!advertiser.getCategoryId()) {
			this.formStore.addError(new FormError('categoryId', 'Please select a category'));
		}

		const account = advertiser.getAccount();
		if (!account || !account.getId()) {
			this.formStore.addError(new FormError('account', 'Please select an account'));
		}

		return this.formStore.getIsValid();
	}

	public enableAdvertisers(advertisersIds: id[]): Promise<void> {
		return AdvertisersApi.enableAdvertisers(advertisersIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	public pauseAdvertisers(advertisersIds: id[]): Promise<void> {
		return AdvertisersApi.pauseAdvertisers(advertisersIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	public onError(error: IHttpError) {
		if (error.errorPayload?.type === ErrorPayloadType.Properties) {
			Object.keys(error.errorPayload?.data).forEach(key => {
				this.formStore.addError(new FormError(key, error.errorPayload?.data[key] as string));
			});
		}
	}
}
