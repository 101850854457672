import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { Menu } from '../menu/menu';
import styles from './breadcrumb-menu.module.scss';
import { BarLoader } from '../bar-loader/bar-loader';
import { Skeleton } from '../skeleton/skeleton';
import { SpacerY } from '../spacer/spacer';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';

interface IBreadcrumb {
	title: string | JSX.Element;
	children: ReactNode;
	label?: string;
	isArrow?: boolean;
	onArrowClick?: () => void;
	navigateToModel: () => void;
	isLoading?: boolean;
	isBarLoader?: boolean;
	skeletonRectanglesAmount?: number;
	menuClassName?: string;
	debugProps?: IDebugProps;
	isError?: boolean;
}

export const BreadcrumbMenu = (props: IBreadcrumb) => {
	const {
		label,
		title,
		isArrow = true,
		children,
		onArrowClick,
		navigateToModel,
		isLoading = false,
		isBarLoader = true,
		skeletonRectanglesAmount = 6,
		menuClassName,

		isError,
	} = props;
	const [isBreadcrumbMenuOpen, setBreadcrumbMenuOpen] = useState<boolean>(false);
	const breadcrumbAndMenuRef = useRef<HTMLDivElement>(null);

	const location = useLocation();
	// close menu on navigation
	useEffect(() => {
		setBreadcrumbMenuOpen(false);
	}, [location]);

	useOnClickOutside(breadcrumbAndMenuRef, () => {
		setBreadcrumbMenuOpen(false);
	});

	const _onArrowClick = () => {
		setBreadcrumbMenuOpen(!isBreadcrumbMenuOpen);
		if (onArrowClick) {
			onArrowClick();
		}
	};

	const onBreadcrumbClick = () => {
		if (isError) {
			return;
		}
		navigateToModel();
	};

	return (
		<div className={styles.wrapper} ref={breadcrumbAndMenuRef}>
			{isArrow ? <div className={styles.next}>/</div> : null}
			<div className={styles.breadcrumbAndMenuWrapper}>
				<div className={styles.breadcrumb}>
					<div
						className={styles.breadcrumbKeyValue}
						{...generateDataAttrs([new DataAttribute('id', `${label?.toLowerCase().replaceAll(' ', '_')}_breadcrumb`)])}>
						<div className={styles.linkWrapper} onClick={onBreadcrumbClick}>
							<div className={styles.breadcrumbLink}>
								<div className={styles.breadcrumbKey}>{label}</div>
								<div className={styles.breadcrumbValue}>
									<span
										{...generateDataAttrs([
											new DataAttribute(
												'id',
												`${label?.toLowerCase().replaceAll(' ', '_')}_breadcrumb_current_value`
											),
										])}
										className={isError ? styles.error : ''}>
										{isError ? 'Error' : title}
									</span>
								</div>
							</div>
						</div>
						{isError ? null : (
							<div className={styles.arrowDownWrapper} onClick={_onArrowClick}>
								<Icon
									className={styles.arrowDown}
									isMFP={true}
									debugProps={{ dataAttrs: [new DataAttribute('id', 'extend_button')] }}>
									chevron-down
								</Icon>
							</div>
						)}
					</div>
				</div>
				{isBreadcrumbMenuOpen ? (
					<Menu
						className={menuClassName || styles.openBreadcrumbMenu}
						alignToLeft={true}
						fromTop={true}
						isOpen={isBreadcrumbMenuOpen}
						unstyled={!isLoading}
						debugProps={{
							dataAttrs: [new DataAttribute('id', `${label?.toLowerCase().replaceAll(' ', '_')}_breadcrumb_menu`)],
						}}>
						{isLoading && isBarLoader ? (
							<Fragment>
								<BarLoader is={true} />
								<SpacerY />
							</Fragment>
						) : null}
						{!isLoading ? children : null}
						<Skeleton is={isLoading} rectanglesAmount={skeletonRectanglesAmount} />
					</Menu>
				) : null}
			</div>
		</div>
	);
};
