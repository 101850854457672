import { capitalCase } from 'change-case';
import { FilterModel, StringFilterOptionsEnums, IFilterComponentProps, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { FilterOptions } from '@monorepo/base/src/components/filters/filter-options/filter-options';
import { StatusEnum, IStatus } from '../../enums/status';

const StatusFilter = (props: IFilterComponentProps) => {
	const { filter, onApply, onCancel, addFilter, editFilter } = props;

	//Currently empty div, anyway it's an overkill
	if (typeof filter?.value === 'number') {
		return <div></div>;
	}

	const onAddStatusFilter = (status: string | string[]) => {
		const statusFilter = new FilterModel({
			label: `Status is ${typeof status === 'string' ? status : status.join(', ')}`,
			value: typeof status === 'string' ? [status.toUpperCase()] : status.map(stat => stat.toUpperCase()),
			prototype: StatusFilterPrototype,
		});

		if (filter?.index && editFilter) {
			editFilter(filter.index, statusFilter);
		} else {
			if (StatusFilterPrototype.multi && addFilter) {
				addFilter(statusFilter);
			}
		}

		onApply();
	};

	return (
		<FilterOptions
			placeholder={'Search Statuses'}
			value={
				typeof filter?.value === 'string'
					? capitalCase(filter?.value)
					: filter?.value?.map(val => capitalCase(val)).join(', ') || ''
			}
			onFilterOption={onAddStatusFilter}
			onCancel={onCancel}
			options={Object.values(StatusEnum).map(_status => capitalCase(_status))}
			isSelectAll={true}
		/>
	);
};

export const StatusFilterPrototype: IFilterProto = {
	MenuComponent: 'Status',
	FilterComponent: StatusFilter,
	prop: StringFilterOptionsEnums.Status,
	multi: false,
	deletable: false,
	filterFunc<T extends IStatus>(models: Array<T>, filter: FilterModel): Array<T> {
		const { value } = filter;
		return models.filter(model => {
			if (typeof value === 'number') {
				return;
			}

			if (typeof value === 'string') {
				return model.status?.toLowerCase() === value.toLowerCase();
			}
			let isEqual = false;
			value.forEach(val => {
				if (model.status?.toLowerCase() === val.toLowerCase()) {
					isEqual = true;
				}
			});
			return isEqual;
		});
	},
};
