import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import styles from './404.module.scss';
import routes from '../../modules/routes/index.routes';
import { Image } from '@monorepo/base/src/components/img/img';
import { Helmet } from 'react-helmet-async';

export const NotFoundPage = () => {
	return (
		<div className={styles.wrapper}>
			<Helmet>
				<title>Manifesto - Page Not Found</title>
			</Helmet>
			<div className={styles.imageWrapper}>
				<Image className={styles.img} source={'/assets/not-found.png'} alt={'email photo'} />
			</div>
			<div className={styles.heading}>404 Not Found</div>
			<div className={styles.text}>The page you requested could not be found.</div>
			<div className={styles.text}>We apologize for the inconvenience. Please check the URL and try again, or go to login page</div>
			<div className={styles.actions}>
				<PrimaryLink to={routes.auth.login()} className={styles.btn}>
					Login
				</PrimaryLink>
			</div>
		</div>
	);
};
