import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter } from '../../components/filters';
import { performanceFilters } from '../../components/filters/perfomance.filter';

export const accountFilters = new Map<FilterTypeKey, FilterType>([
	['numberFilters', { ...performanceFilters.numberFilters } as INumberFilter],
	['stringFilters', { ...performanceFilters.stringFilters, name: { columnName: 'Name', cellKey: 'name' } } as IStringFilter],
	[
		'enumFilters',
		{
			...performanceFilters.enumFilters,
		} as IEnumFilter,
	],
]);
