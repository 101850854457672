import { InputItem, InputInfo, InputTitle, InputDesc } from '@monorepo/base/src/components/form/input-item/input-item';
import { BudgetInput } from '../campaign-common.form';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { OptimizationModesLabels } from '../../../../enums/optimization-modes';
import { IMFPStore } from '../../../../stores';
import styles from '../campaign-form.module.scss';
import { SpaceBetweenFormControls } from '../campaign.form';
import { CampaignDomIds } from '../../../../hooks/toc/campaign.toc';
import { ButtonGroup, GButton } from '@monorepo/base/src/components/buttons/group/button-group';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { AdvancedBidding } from '../advanced/advanced-bidding';
import { OptimizationAlgoVersionEnum } from '../../../../models/strategies/advanced-bidding';

export const SmartStrategy = observer(() => {
	const { campaignStore, advertiserStore } = useStores<IMFPStore>();
	const routes = useRoute();
	const isEditPage = routes.currentRoute.includes('edit');
	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();
	const optimizationMode = campaign.getOptimizerConfiguration()?.getAlgoVersion();
	const oprimizationConfiguration = campaign.getOptimizerConfiguration();
	const formStore = campaignCrudStore.getFormStore();

	useEffect(() => {
		if (isEditPage && campaign?.getAdvertiser()) {
			advertiserStore.getCrud().get(campaign?.getAdvertiser()?.id || -1);
		}
	}, [campaign?.getAdvertiser()]);

	return (
		<div>
			<InputItem maxWidth={'944px'}>
				<InputInfo className={`${styles.inputInfo} ${styles.stickToTop}`}>
					<InputTitle>Optimization Mode</InputTitle>
					<InputDesc>Select the optimization mode that best suits your goals</InputDesc>
				</InputInfo>
				{/* TODO - think about gbutton? */}
				<Flex flexDirection={'column'} alignItems={'flex-start'} width={'320px'} gap={'10px'}>
					<ButtonGroup
						radio={true}
						defaultValue={OptimizationModesLabels.TargetCPA}
						onChange={values => {
							if (Array.from(values)[0] === OptimizationModesLabels.TargetCPA) {
								campaign.getOptimizerConfiguration()?.setAlgoVersion(OptimizationAlgoVersionEnum.TargetCPA);
							} else {
								campaign.getOptimizerConfiguration()?.setAlgoVersion(OptimizationAlgoVersionEnum.TargetRoas);
							}
						}}>
						<GButton
							text={OptimizationModesLabels.TargetCPA}
							value={OptimizationModesLabels.TargetCPA}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'optimization_mode_target_cpa')] }}
						/>
						<GButton
							text={OptimizationModesLabels.TargetRoas}
							value={OptimizationModesLabels.TargetRoas}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'optimization_mode_target_roas')] }}
						/>
						<div id={CampaignDomIds.OptimizationMode} />
					</ButtonGroup>
					<BudgetInput
						before={optimizationMode === OptimizationAlgoVersionEnum.TargetCPA ? '$' : '%'}
						after={''}
						placeholder={''}
						step={optimizationMode === OptimizationAlgoVersionEnum.TargetCPA ? 0.01 : 1}
						value={
							oprimizationConfiguration?.getAlgoVersion() === OptimizationAlgoVersionEnum.TargetRoas
								? oprimizationConfiguration.getTargetRoi()?.toString()
								: oprimizationConfiguration?.getTargetCpa()?.toString()
						}
						id={CampaignDomIds.OptimizationModeValue}
						error={formStore.getErrors().get(CampaignDomIds.OptimizationModeValue)?.getMsg()}
						setValue={value => {
							const isRoas = oprimizationConfiguration?.getAlgoVersion() === OptimizationAlgoVersionEnum.TargetRoas;
							if (isRoas) {
								oprimizationConfiguration?.setTargetRoi(parseFloat(value));
							} else {
								oprimizationConfiguration?.setTargetCpa(parseFloat(value));
							}
							formStore.clearError(CampaignDomIds.OptimizationModeValue);
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'optimization-mode-value-debug')] }}
					/>
				</Flex>
			</InputItem>
			<SpaceBetweenFormControls />
			<AdvancedBidding />
		</div>
	);
});
