import styles from './tabs.module.scss';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { LinkWithParams } from '../link-with-params/link-with-params';

interface ITabs {
	tabs?: {
		route: string;
		name: string;
	}[];
}

export const Tabs = (props: ITabs) => {
	const { tabs = [] } = props;
	const { currentRoute } = useRoute();

	return (
		<div className={styles.wrapper}>
			{tabs.map(tab => {
				return (
					<LinkWithParams
						key={tab.name}
						to={tab.route}
						className={`${styles.tabItem} ${tab.route === currentRoute ? styles.active : ''}`}>
						{tab.name}
					</LinkWithParams>
				);
			})}
		</div>
	);
};
