import { declareIncludeParams, getParamsFromUrl } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { LinkProps } from 'react-router-dom';
import { Link, useSearchParams } from 'react-router-dom';

export const SearchParamsGlobalDecleration = ({ knownsSearchParams = [] }: { knownsSearchParams: string[] }) => {
	declareIncludeParams(knownsSearchParams);
	return null;
};

export const LinkWithParams = (props: LinkProps) => {
	const { to, ...rest } = props;
	const [params] = useSearchParams();
	const queryString = getParamsFromUrl(params);

	return <Link to={to + queryString} {...rest} />;
};
