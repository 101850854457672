import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { URLSearchParams } from 'url';
import { PerformancePayload } from '../models/performance.model';
import { IPerformanceOptions } from '../hooks/apis/use-reports-list';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { SourceModel } from '../models/sources.model';

export const SourcesApi = {
	// TODO - check if using, maybe DEPRECATED
	list: (params?: URLSearchParams): Promise<SourceModel[]> => {
		return ask.get(endpoints.sources.list(), params).then(res => res?.payload?.list);
	},
	reports: async (params?: IPerformanceOptions, options?: IRequestOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		const res = await ask.get(
			endpoints.sources.reports(),
			queryParamsToSearchParams({ p: hashParams, ...(options?.queryParams || {}) })
		);
		return res?.payload?.report;
	},
	metrics: (params?: IPerformanceOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask.get(endpoints.sources.reports(), queryParamsToSearchParams({ p: hashParams })).then(res => res?.payload?.report);
	},
};
