import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { jwt } from '@monorepo/tools/src/lib/types/jwt';
import { generateStorageKey } from './helper';

export class AuthStore {
	token?: jwt;

	constructor() {
		makeAutoObservable(this);

		makePersistable(this, {
			name: generateStorageKey('ilbbaicnl'),
			properties: ['token'],
			// storage: {
			// 	setItem: (key, data) => cookies.setCookie(key, data),
			// 	removeItem: key => cookies.deleteCookie(key),
			// 	getItem: key => {
			// 		const data = JSON.parse(cookies.getCookie(key) || '{}');

			// 		return {
			// 			...data,
			// 		};
			// 	},
			// },
			//!TODO: Cookies currently does not save properly, we dont need cookie because the token is encrypted

			storage: {
				setItem: (key, data) => {
					window.localStorage.setItem(key, data);
				},
				removeItem: key => window.localStorage.removeItem(key),
				getItem: key => {
					const data = JSON.parse(window.localStorage.getItem(key) || '{}');

					return {
						...data,
					};
				},
			},
		});
	}

	setToken(token?: jwt) {
		this.token = token;
	}

	getToken(): jwt | undefined {
		return this.token;
	}

	removeToken() {
		this.token = undefined;
	}
}
