import { useEffect, useState } from 'react';
import { stringAction } from '@monorepo/tools/src/lib/utils/string';
import { PrimaryButton, SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './filter-string-menu.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';

interface IFilterStringMenu {
	title: string;
	onApply: (action: stringAction, value: string) => void;
	onCancel: () => void;
	defaultAction?: string;
	defaultValue?: string;
	debugProps?: IDebugProps;
}

export const FilterStringMenu = (props: IFilterStringMenu) => {
	const { title, onApply, onCancel, defaultAction = 'contains', debugProps, defaultValue = '' } = props;
	const [action, setAction] = useState<option>(defaultAction);
	const [filterValue, setFilterValue] = useState<option>(defaultValue);
	const [actionError, setActionError] = useState<string | undefined>(undefined);
	const [inputError, setInputError] = useState<string | undefined>(undefined);
	const { dataAttrs } = debugProps || {};

	useEffect(() => {
		setAction(defaultAction);
	}, [defaultAction]);

	return (
		<div className={styles.wrapper} {...generateDataAttrs(dataAttrs)}>
			<div className={styles.title}>
				{title}
				<Icon isMFP={true} className={styles.closeButton} onClick={onCancel}>
					x-close
				</Icon>
			</div>
			<div className={styles.actionsDiv}>Actions</div>
			<div className={styles.content}>
				<Dropdown
					className={styles.dropdown}
					label={'Actions'}
					required={true}
					defaultOption={action}
					options={['contains', 'does not contain', 'begins with', 'ends with', 'equals', 'does not equal']}
					onSelect={(_action: Set<option> | undefined) => {
						setAction(_action?.values().next().value);
						setActionError(undefined);
					}}
					error={actionError}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'filter_action')] }}
				/>
				<div className={styles.subTitle}>{title}</div>
				<Input
					value={filterValue}
					onValue={value => {
						setFilterValue(value);
						setInputError(undefined);
					}}
					required={true}
					error={inputError}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'action_filter_value')] }}
				/>
			</div>
			<div className={styles.actions}>
				<div className={styles.action}>
					<SecondaryButton
						className={`${styles.actionButton} ${styles.cancelButton}`}
						onClick={onCancel}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'filter_menu_cancel_button')] }}>
						Cancel
					</SecondaryButton>
				</div>
				<div className={styles.action}>
					<PrimaryButton
						className={styles.actionButton}
						onClick={() => {
							if (!filterValue) {
								setInputError('Required');
							}
							if (!action) {
								setActionError('Required');
							}
							if (filterValue && action) {
								onApply(action as stringAction, filterValue);
							}
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'filter_menu_apply_button')] }}>
						Apply
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
};
