import { Envs } from '../../enums/env';
import { getConfig } from '../../get-config';
import { PrimitiveObjectType } from '../../types/primitives';
import { ConsoleLogger, LogLevel } from './console-logger';

interface ILoggerOptions {
	message: any;
	traces?: PrimitiveObjectType;
	payload?: any;
}

abstract class ALogger {
	abstract error(params: ILoggerOptions): void;
	abstract warn(params: ILoggerOptions): void;
	abstract log(params: ILoggerOptions): void;
}

export class Logger extends ALogger {
	consoleLogger: ConsoleLogger;

	constructor(options?: { level?: LogLevel }) {
		super();
		this.consoleLogger = new ConsoleLogger({
			level: options?.level || (getConfig().env.projectEnv === Envs.Production ? 'error' : 'log'),
		});
	}

	log(options: ILoggerOptions) {
		const { message, payload } = options;
		this.consoleLogger.log(message, payload);
	}

	warn(options: ILoggerOptions): void {
		const { message, payload } = options;
		this.consoleLogger.warn(message, payload);
	}

	error(options: ILoggerOptions) {
		const { message, payload } = options;
		this.consoleLogger.error(message, payload);
	}
}
