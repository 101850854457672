import { makeAutoObservable } from 'mobx';
import { PerformanceEnumarableLabels } from '@monorepo/tools/src/lib/enums/performance-labels';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export const availablePerformanceToMetrics = {
	[PerformanceEnumarableLabels.Impressions]: 'impressions',
	[PerformanceEnumarableLabels.Clicks]: 'clicks',
	[PerformanceEnumarableLabels.Conversions]: 'conversions',
	[PerformanceEnumarableLabels.Cost]: 'cost',
	[PerformanceEnumarableLabels.Revenue]: 'revenue',
	[PerformanceEnumarableLabels.ROAS]: 'roas',
	[PerformanceEnumarableLabels.CVR]: 'cvr',
	[PerformanceEnumarableLabels.CPA]: 'cpa',
	[PerformanceEnumarableLabels.CPC]: 'cpc',
	[PerformanceEnumarableLabels.CPM]: 'cpm',
	[PerformanceEnumarableLabels.EPC]: 'epc',
	[PerformanceEnumarableLabels.CONV_VAL]: 'conv_val',
	[PerformanceEnumarableLabels.WIN_RATE]: 'win_rate',
};

export type TPerformanceEnumerable = string; // should be one of availablePerformanceToMetrics

export interface IPerformanceItem {
	budget?: number;
	daily_cap?: number;
	clicks?: number;
	conversions?: number;
	cost?: number;
	revenue?: number;
	impressions?: number;
	roas?: number;
	cpc?: number;
	cpa?: number;
	cpm?: number;
	cvr?: number;
	date?: string;
	advertiser_id?: id;
	advertiser_name?: string;
	account_id?: id;
	account_name?: string;
	// added columns
	epc?: number;
	win_rate?: number;
	conv_val?: number;
}

export interface IPerformancePayload {
	totalRows: number;
	totalFilteredRows: number;
	summary: IPerformanceItem;
	data: PerformanceModel[];
}

export class PerformancePayload {
	totalRows: number;
	totalFilteredRows: number;
	summary: IPerformanceItem;
	data: PerformanceModel[];

	constructor(performanceResponse: IPerformancePayload) {
		this.summary = performanceResponse?.summary;
		this.data = performanceResponse?.data;
		this.totalRows = performanceResponse?.totalRows;
		this.totalFilteredRows = performanceResponse?.totalFilteredRows;
		// this.segmentedSummary = performanceResponse?.segmentedSummary;
		makeAutoObservable(this);
	}

	public getSummary(): PerformanceModel {
		return this.summary;
	}

	public getData(): PerformanceModel[] {
		return this.data;
	}

	public getTotalRows(): number {
		return this.totalRows;
	}

	public getTotalFilteredRows(): number {
		return this.totalFilteredRows;
	}
}

export class PerformanceModel implements IPerformanceItem {
	budget?: number;
	daily_cap?: number;
	clicks?: number;
	conversions?: number;
	cost?: number;
	revenue?: number;
	impressions?: number;
	roas?: number;
	cpc?: number;
	cpa?: number;
	cpm?: number;
	cvr?: number;
	win_rate?: number;
	conv_val?: number;
	epc?: number;
	date?: string;
	name?: string;
	id?: id;
	advertiser_id?: id;
	advertiser_name?: string;
	account_id?: id;
	account_name?: string;

	constructor(performance: IPerformanceItem) {
		this.clicks = performance.clicks;
		this.revenue = performance.revenue;
		this.conversions = performance.conversions;
		this.cost = performance.cost;
		this.cpa = performance.cpa;
		this.cvr = performance.cvr;
		this.roas = performance.roas;
		this.cpc = performance.cpc;
		this.cpm = performance.cpm;
		this.impressions = performance.impressions;
		this.date = performance.date;
		this.advertiser_id = performance.advertiser_id;
		this.advertiser_name = performance.advertiser_name;
		this.account_id = performance.account_id;
		this.account_name = performance.account_name;
		this.win_rate = performance?.win_rate;
		this.conv_val = performance?.conv_val;
		this.epc = performance?.epc;
	}
}
