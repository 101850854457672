import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect } from 'react';
import { PrimaryButton, SecondaryButton, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { observer } from 'mobx-react';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { Nav, NavPage } from '@monorepo/base/src/components/nav/nav';
import { route } from '@monorepo/tools/src/lib/types/url';
import { IMFPStore } from '../../../modules/stores';
import { CampaignForm } from '../../../modules/components/forms/campaign/campaign.form';
import { useCampaignTableOfContents } from '../../../modules/hooks/toc/campaign.toc';
import { TableOfContents } from '@monorepo/base/src/components/table-of-contents/table-of-contents';
import { useLayoutContext } from '../../../modules/hooks/useLayoutContext';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { useList } from '../../../modules/hooks/use-list';
import styles from '../../../modules/components/forms/campaign/campaign-form.module.scss';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { Helmet } from 'react-helmet-async';

interface ICreateCampaign {
	parentRoute?: route;
}

export const CreateCampaign = observer((props: ICreateCampaign) => {
	const { parentRoute } = props;
	const { campaignStore, advertiserStore } = useStores<IMFPStore>();
	const { dispatchLog } = useLogs();
	const { setIsTableOfContents } = useLayoutContext();
	const campaignCrudStore = campaignStore.getCrud();
	const { tableOfContents } = useCampaignTableOfContents();
	const isError = campaignCrudStore.getHttpError();
	// Fixes not rerendering table of content
	campaignCrudStore.getFormStore().getErrors();

	useList(advertiserStore.getListStore());

	const navigate = useNavigateParams();

	useEffect(() => {
		campaignCrudStore.reset();
		return () => campaignCrudStore.reset();
	}, [location]);

	useEffect(() => {
		if (parentRoute) {
			setIsTableOfContents(true);
		}
	}, []);

	const campaign = campaignCrudStore.getData();
	const name = campaign.getName();
	const isLoading = campaignCrudStore.getIsLoading();
	const isSuccess = campaignCrudStore.getIsSuccess();
	const isSavedToDraft = campaignCrudStore.getIsEdited();

	useEffect(() => {
		if (isSuccess) {
			campaignCrudStore.setIsSuccess(false);
			dispatchLog({
				msg: `Campaign ${name} was successfully ${isSavedToDraft ? 'saved to draft' : 'added'}`,
				type: DispatchLogsTypes.Success,
			});
			if (!isSavedToDraft) {
				navigate(-1);
				return;
			}
			navigate(`/campaigns/${campaign.getId()}/edit`);
		} else if (isError) {
			dispatchLog({
				msg: `Sorry, we were unable to save your campaign.`,
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isSuccess, isSavedToDraft]);

	const onPublishCampaign = () => {
		campaignCrudStore.setIsEdited(false);
		campaignCrudStore.create();
		// console.log({ campaign: campaignCrudStore.getCreateFormData() });
	};

	return (
		<Disable is={isLoading}>
			<Helmet>
				<title>Create New Campaign</title>
			</Helmet>
			<BottomBar>
				<Flex height={'100%'}>
					<SecondaryText onClick={() => navigate(-1)} icon={'chevron-left'} iconSize={'20px'} width={'70px'}>
						Back
					</SecondaryText>
					<BottomBarActions>
						<Flex gap={'16px'}>
							<SecondaryButton
								isRotateIcon={!isSavedToDraft && isLoading}
								icon={!isSavedToDraft && isLoading ? 'sync' : ''}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'save_draft_campaign')] }}
								onClick={() => campaignCrudStore.saveDraft()}>
								Save Draft
							</SecondaryButton>
							<PrimaryButton
								onClick={() => onPublishCampaign()}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'publish_campaign')] }}>
								Publish
							</PrimaryButton>
						</Flex>
					</BottomBarActions>
				</Flex>
			</BottomBar>
			<NavPage>
				<Nav classes={{ nav: styles.tableOfContentsWrapper }}>
					<TableOfContents
						heads={tableOfContents}
						offset={100}
						observerOptions={{ threshold: 0.1, rootMargin: '-70px 0px -50% 0px', intersectionRatio: 0.1 }}
					/>
				</Nav>
				<Page>
					<Pagebar>
						<PagebarTitle>Create new campaign</PagebarTitle>
					</Pagebar>
					<CampaignForm />
				</Page>
			</NavPage>
		</Disable>
	);
});
