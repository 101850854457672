import { Page } from '@monorepo/base/src/components/page/page';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';
import { useBulkCampaignActions } from '../../../../modules/hooks/actions/bulk-history.actions';
import { useBulkCampaignsHeaders } from '../../../../modules/hooks/theaders/bulk-campaigns.headers';
import { useList } from '../../../../modules/hooks/use-list';
import { BulkCampaignsModel } from '../../../../modules/models/bulk-campaigns.model';
import { IMFPStore } from '../../../../modules/stores';
import styles from './../../../../styles/pages.module.scss';
import bulkStyles from './bulk-history.module.scss';

const tableDebugProps = { dataAttrs: [new DataAttribute('id', 'bulk_campaigns_table')] };
const actionsDebugProps = { dataAttrs: [new DataAttribute('id', 'bulk_options_actions')] };

export const BulkHistory = observer(() => {
	const { bulkCampaignsStore } = useStores<IMFPStore>();
	useList<BulkCampaignsModel>(bulkCampaignsStore.getListStore());
	const { currentRouteWithoutSpecialChars } = useRoute();

	const { EntityActions } = useBulkCampaignActions({ debugProps: actionsDebugProps });
	const { columns, columnVisibility } = useBulkCampaignsHeaders({ EntityActions });

	return (
		<Page unstyled={true} className={`${styles.article} ${bulkStyles.wrapper}`}>
			<Helmet>
				<title>Manifesto - Bulk Campaigns History</title>
			</Helmet>
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				classes={{ wrapper: styles.padding }}
				label={'Bulk History'}
				totalRows={bulkCampaignsStore.getListStore().getList().length}
				debugProps={tableDebugProps}
				columns={columns}
				columnVisibility={columnVisibility}
				data={bulkCampaignsStore.getListStore().getList()}
				isLoading={bulkCampaignsStore.getListStore().getIsLoading()}
				defaultSortBy={[{ id: 'uploadedDate', desc: true }]}
				isError={false}>
				{({ sortedRows }) => {
					bulkCampaignsStore.setSortedRows(sortedRows);

					return {};
				}}
			</ControlledTable>
		</Page>
	);
});
