import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AdvertiserModel } from '../../models/advertiser.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { Select, SelectAction, SelectActions } from '@monorepo/base/src/components/select/select';
import SelectionImage from '@monorepo/base/src/assets/selection.svg';
import { BreadcrumbMenu } from '@monorepo/base/src/components/breadcrumb-menu/breadcrumb-menu';
import styles from './main-breadcrumbs.module.scss';
import { IMFPStore } from '../../stores';
import { useAdvertiserGet } from '../../hooks/apis/use-advertiser-get';
import { SkeletonBreadCrumb } from './main-breadcrumbs';
import { AccountBreadcrumb } from './account-page.breadcrumbs';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';
import { IQueryParams } from '@monorepo/tools/src/lib/interfaces/url';
import { useState } from 'react';
import {
	EmptyState,
	EmptyStateActions,
	EmptyStateDescription,
	EmptyStateImage,
	EmptyStateTitle,
} from '@monorepo/base/src/components/empty-state/empty-state';
import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { AccountModel } from '../../models/account.model';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { CampaignBreadcrumb } from './campaign-page.breadcrumbs';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

interface IAdvertiserBreadcrumb {
	advertiser?: AdvertiserModel;
	account?: AccountModel;
	isActive?: boolean;
}

const All = 'All';

export const AdvertiserBreadcrumb = observer((props: IAdvertiserBreadcrumb) => {
	const { advertiser, account, isActive = false } = props;
	const { advertiserStore, userStore } = useStores<IMFPStore>();
	const advertiserListStore = advertiserStore.getListStore();
	const advertisers = advertiserListStore.getList();
	const isError = advertiserListStore.getError();
	const advertiserName = advertiser?.getName();
	const isLoadingAdvertisers = advertiserListStore.getIsLoading();
	const accountId = account?.getId() || advertiser?.getAccount()?.getId();
	const [accountAdvertisers, setAccountAdvertisers] = useState<AdvertiserModel[]>([]);

	const navigate = useNavigateParams();

	const onListItemAdvertiser = (advertiserName: string, e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();

		if (advertiserName === All) {
			navigate(accountId ? `${routes.accounts.advertisers(accountId)}` : `${routes.advertisers.index()}`);
			return;
		}
		const clickedAdvertiser = advertisers.find(_advertiser => _advertiser.getName() === advertiserName);
		if (!clickedAdvertiser) {
			// TODO - log
			return null;
		}
		const clickedAdvertiserId = clickedAdvertiser.getId();
		if (!clickedAdvertiserId) {
			// TODO - log
			return null;
		}
		navigate(`${routes.advertisers.campaigns(clickedAdvertiserId)}`);
	};

	const onBreadcrumbLink = () => {
		const advertiseId = advertiser?.getId();
		if (advertiseId) {
			navigate(`${routes.advertisers.campaigns(advertiseId)}`);
		}
	};

	const onBreadcrumbArrow = () => {
		const params = {
			accountsIds: accountId,
		};

		advertiserStore
			.getListStore()
			.fetch(queryParamsToSearchParams(params as IQueryParams))
			.then(_advertisers => {
				if (!Array.isArray(_advertisers)) {
					return null;
				}
				setAccountAdvertisers(_advertisers);
			});
	};

	const setAdvertisersList = () => {
		if (accountAdvertisers.length <= 1) {
			return accountAdvertisers.map(advertiser => advertiser.getName());
		}
		return [All, ...accountAdvertisers.map(advertiser => advertiser.getName())];
	};

	const isEmptyState = () => {
		return (accountAdvertisers.length === 0 || accountAdvertisers.length === 1) && !isLoadingAdvertisers;
	};

	const renderMenu = () => {
		if (!accountAdvertisers) {
			return null;
		}
		if (isEmptyState() && !userStore?.getUser()?.isInternal) {
			return (
				<EmptyState>
					<EmptyStateImage source={SelectionImage} />
					<EmptyStateTitle>Multiple advertisers</EmptyStateTitle>
					<EmptyStateDescription>
						Create multiple advertisers to better track and optimize campaigns for different brands, products, or business
						units.
					</EmptyStateDescription>
					<EmptyStateActions>
						<PrimaryLink
							to={accountId ? routes.accounts.createAdvertiser(accountId) : routes.advertisers.create()}
							icon={'plus'}
							style={{ maxWidth: '200px', margin: '0 auto' }}>
							New Advertiser
						</PrimaryLink>
					</EmptyStateActions>
				</EmptyState>
			);
		}
		return (
			<Select
				placeholder={'Search Advertiser'}
				listItemClassName={styles.selectItem}
				values={setAdvertisersList()}
				onClick={onListItemAdvertiser}
				disabled={isLoadingAdvertisers}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'advertiser_breadcrumbs_select')] }}
				defaultValue={advertiserName}
				actions={
					accountAdvertisers.length > 1 ? (
						<SelectActions>
							<SelectAction to={accountId ? routes.accounts.createAdvertiser(accountId) : routes.advertisers.create()}>
								Add a new advertiser
							</SelectAction>
						</SelectActions>
					) : null
				}
			/>
		);
	};

	return (
		<BreadcrumbMenu
			label={'Advertiser'}
			onArrowClick={onBreadcrumbArrow}
			title={<span className={isActive ? styles.active : ''}>{advertiserName || All}</span>}
			isArrow={Boolean(accountId && userStore?.getUser()?.isInternal)}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'advertiser_breadcrumbs')] }}
			navigateToModel={onBreadcrumbLink}
			isBarLoader={isLoadingAdvertisers}
			isLoading={isLoadingAdvertisers}
			menuClassName={isEmptyState() ? styles.emptyState : ''}
			isError={Boolean(isError)}>
			<div className={styles.menuWrapper}>{renderMenu()}</div>
		</BreadcrumbMenu>
	);
});

interface IAdvertiserPageBreadcrumbs {
	advertiserId?: id;
}

export const AdvertiserPageBreadcrumbs = observer((props: IAdvertiserPageBreadcrumbs) => {
	const { advertiserId } = props;
	const { advertiserStore, userStore } = useStores<IMFPStore>();
	const advertiser = advertiserStore.getCrud().getData();
	const isLoading = advertiserStore.getCrud().getIsLoading();
	const account = advertiser.getAccount();
	const user = userStore.getUser();
	const { currentRoute } = useRoute();
	const isEditPage = currentRoute.includes('edit');

	useAdvertiserGet({ id: advertiserId });

	return (
		<div className={styles.wrapper}>
			{account?.getName() && user?.isInternal ? (
				<AccountBreadcrumb account={account} />
			) : isLoading && user?.isInternal ? (
				<SkeletonBreadCrumb />
			) : null}
			{advertiser.getName() ? <AdvertiserBreadcrumb isActive={true} advertiser={advertiser} /> : <SkeletonBreadCrumb />}
			{advertiser.getName() && !isEditPage ? (
				<CampaignBreadcrumb advertiser={advertiser} isActive={true} />
			) : !isEditPage ? (
				<SkeletonBreadCrumb />
			) : null}
		</div>
	);
});
