import { IHead, IInjectHeadItem, TableOfContents } from '@monorepo/base/src/components/table-of-contents/table-of-contents';
import { ReactNode } from 'react';
import styles from './toc-item.module.scss';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { generateDataAttrs, DataAttribute, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';

interface ITocItem extends IInjectHeadItem {
	title: string;
	description?: string;
	steps?: IHead[];
	isError?: boolean;
}

export const TocItem = (props: ITocItem) => {
	const { title, steps = [], description, isActive, onClick } = props;

	return (
		<div className={`${styles.itemWrapper} ${isActive ? styles.active : ''}`}>
			<div className={styles.line}></div>
			<div className={styles.circle}>
				<div className={styles.dot}></div>
			</div>
			<div className={styles.info}>
				<div onClick={e => onClick(e)} className={styles.title} {...generateDataAttrs([new DataAttribute('id', 'title_toc_item')])}>
					{title}
				</div>
				{description ? <div className={styles.desc}>{description}</div> : null}
				<TableOfContents heads={steps} offset={50} />
			</div>
		</div>
	);
};

interface ISubTocItem extends IInjectHeadItem {
	children: ReactNode;
	isError?: boolean;
	isDisabled?: boolean;
	debugProps?: IDebugProps;
}

export const SubTocItem = (props: ISubTocItem) => {
	const { children, isError, isDisabled, debugProps } = props;
	const { dataAttrs } = debugProps || {};

	return (
		<Tooltip isPositionFixed content={'This property can not be changed while a campaign has been published'} is={Boolean(isDisabled)}>
			<div className={`${styles.subWrapper} ${isError ? styles.error : ''} ${isDisabled ? styles.disabled : ''}`}>
				{isError ? <div className={styles.errorCircle}></div> : null}
				{typeof children == 'string' ? (
					<span
						className={styles.label}
						{...generateDataAttrs(
							suffixToDataAttr(
								`_sub_toc_item_${children.toLowerCase().replace(' ', '_').replace('/', '_')}`.toLowerCase(),
								dataAttrs
							)
						)}>
						{children}
					</span>
				) : (
					<span className={styles.label}>{children}</span>
				)}
			</div>
		</Tooltip>
	);
};
