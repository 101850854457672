import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { StrategyType } from './campaign.model';

export interface IBulkCampaigns {
	id?: id;
	fileName?: string;
	url?: string;
	updatedAt?: Date;
	uploadedBy?: string;
	publishedDate?: Date;
	publishedBy?: string;
	createdAmount?: number;
	updatedAmount?: number;
	totalCampaigns?: number;
	errorsAmount?: number;
	strategyType?: StrategyType;
}

export interface IBulkCampaignsCreateForm {
	id?: id;
	fileName?: string;
	url?: string;
	uploadedDate?: Date;
	uploadedBy?: string;
	publishedDate?: Date;
	publishedBy?: string;
	createdAmount?: number;
	updatedAmount?: number;
	totalCampaigns?: number;
	errorsAmount?: number;
	strategyType?: StrategyType;
}

export interface IBulkCampaignsEditForm {
	id?: id;
	fileName?: string;
	url?: string;
	uploadedDate?: Date;
	uploadedBy?: string;
	publishedDate?: Date;
	publishedBy?: string;
	createdAmount?: number;
	updatedAmount?: number;
	totalCampaigns?: number;
	errorsAmount?: number;
	strategyType?: StrategyType;
}

export class BulkCampaignsModel implements IBulkCampaigns {
	id?: id;
	fileName?: string;
	url?: string;
	updatedAt?: Date;
	uploadedBy?: string;
	publishedDate?: Date;
	publishedBy?: string;
	createdAmount?: number;
	updatedAmount?: number;
	totalCampaigns?: number;
	errorsAmount?: number;
	strategyType?: StrategyType;

	constructor(bulkCampaign: IBulkCampaigns) {
		this.id = bulkCampaign?.id;
		this.fileName = bulkCampaign?.fileName;
		this.url = bulkCampaign?.url;
		this.updatedAt = bulkCampaign?.updatedAt ? new Date(bulkCampaign.updatedAt) : undefined;
		this.uploadedBy = bulkCampaign?.uploadedBy;
		this.publishedDate = bulkCampaign?.publishedDate ? new Date(bulkCampaign.publishedDate) : undefined;
		this.publishedBy = bulkCampaign?.publishedBy;
		this.createdAmount = bulkCampaign?.createdAmount;
		this.updatedAmount = bulkCampaign?.updatedAmount;
		this.totalCampaigns = bulkCampaign?.totalCampaigns;
		this.errorsAmount = bulkCampaign?.errorsAmount;
		this.strategyType = bulkCampaign?.strategyType;

		makeAutoObservable(this);
	}

	getId(): id | undefined {
		return this.id;
	}

	getFileName(): string | undefined {
		return this.fileName;
	}

	setFileName(fileName: string) {
		this.fileName = fileName;
	}

	getUrl(): string | undefined {
		return this.url;
	}

	setUrl(url: string) {
		this.url = url;
	}

	getUploadedDate(): Date | undefined {
		return this.updatedAt;
	}

	setUploadedDate(uploadedDate: Date) {
		this.updatedAt = uploadedDate;
	}

	getUploadedBy(): string | undefined {
		return this.uploadedBy;
	}

	setUploadedBy(uploadedBy: string) {
		this.uploadedBy = uploadedBy;
	}

	getPublishedDate(): Date | undefined {
		return this.publishedDate;
	}

	setPublishedDate(publishedDate: Date) {
		this.publishedDate = publishedDate;
	}

	getPublishedBy(): string | undefined {
		return this.publishedBy;
	}

	setPublishedBy(publishedBy: string) {
		this.publishedBy = publishedBy;
	}

	getCreatedAmount(): number | undefined {
		return this.createdAmount;
	}

	setCreatedAmount(createdAmount: number) {
		this.createdAmount = createdAmount;
	}

	getUpdatedAmount(): number | undefined {
		return this.updatedAmount;
	}

	setUpdatedAmount(updatedAmount: number) {
		this.updatedAmount = updatedAmount;
	}

	getTotalCampaigns(): number | undefined {
		return this.totalCampaigns;
	}

	setTotalCampaigns(totalCampaigns: number) {
		this.totalCampaigns = totalCampaigns;
	}

	getErrorsAmount(): number | undefined {
		return this.errorsAmount;
	}

	setErrorsAmount(errorsAmount: number) {
		this.errorsAmount = errorsAmount;
	}

	getStrategyType(): StrategyType | undefined {
		return this.strategyType;
	}

	setStrategyType(strategyType: StrategyType) {
		this.strategyType = strategyType;
	}
}
