import { Fragment } from 'react';
import { SelectedLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { QuickActionButton } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityActions, ITableActions, useDefaultActions } from './default.actions';
import { CampaignModel } from '../../models/campaign.model';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IMFPStore } from '../../stores/index';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';
import { CampaignFiltersStoreKeys } from '../../stores/campaigns/campaign.store';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { ISegment, Segments } from '@monorepo/base/src/components/segments/segments';
import { PerformanceModel } from '../../models/performance.model';
import { PerformancesColumns } from '../apis/use-reports-list';
import { useSegments } from '../tools/use-segments';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { EnablePauseCampaignsModal } from '../../components/modals/enable-pause-campaigns';
import { IButton, SecondaryLink, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { ISegmentActions } from '@monorepo/base/src/components/table/table';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { StatusFilterPrototype } from '../../components/filters/status.filter';
import { campaignFilterPrototypes } from '../../components/filters';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';

const theme = getTheme();

interface ICampaignActions {
	availableFilters?: IFilterProto[];
	currentSegments?: Set<ISegment<CampaignModel, PerformanceModel, PerformancesColumns>>;
	debugProps?: IDebugProps;
}

interface ICampaignEntityActions extends IEntityActions<CampaignModel> {
	actions?: {
		enable?: boolean;
		pause?: boolean;
		edit?: boolean;
		duplicate?: boolean;
		delete?: boolean;
	};
}

interface IDuplicateProps extends IButton<HTMLAnchorElement> {
	entities: CampaignModel[];
}

export const useCampaignActions = (props: ICampaignActions = {}) => {
	const { availableFilters, currentSegments, debugProps } = props;
	const { campaignStore, modalsStore, toastsStore, settingsStore } = useStores<IMFPStore>();
	const campaignsCrudStore = campaignStore.getCrud();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const navigate = useNavigateParams();
	const { dataAttrs } = debugProps || {};

	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(currentRouteWithoutSpecialChars as CampaignFiltersStoreKeys);
	const currentFilters = filterStore?.getCurrentFilters();

	const DefaultAvailableFilters = [StatusFilterPrototype, ...campaignFilterPrototypes];

	const onEditRoute = (id: id) => routes.campaigns.edit(id);
	const onDelete = (id: id) => campaignStore.getCrud().delete(id);
	const onDuplicateRoute = (id: id) => routes.campaigns.duplicate(id);

	const funcActions = {
		onEditRoute,
		onDelete,
	};

	const DuplicateAction = (props: IDuplicateProps) => {
		const { textColor = theme.actionLinerIconTextColor, iconColor = theme.actionLinerIconColor, className, entities } = props;
		const duplicateId = entities[0]?.getId();
		if (!onDuplicateRoute || !duplicateId) {
			return null;
		}
		return (
			<SecondaryLink
				disabled={entities.length !== 1}
				text={true}
				icon={'copy-06'}
				font={IconFonts.Outlined}
				to={onDuplicateRoute(duplicateId)}
				textColor={textColor}
				iconColor={iconColor}
				className={className}
				{...props}>
				Duplicate
			</SecondaryLink>
		);
	};

	// TODO - row should be converted to CampaignModel
	const SelectedActions = (props: ITableActions<CampaignModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new CampaignModel(row.original));
		const { EditAction, DeleteAction } = useDefaultActions({ entities, ...funcActions });
		const textColor = theme.actionLinerIconTextColor;
		const iconColor = theme.actionLinerIconColor;
		const entitiesIds: id[] = [];
		entities.forEach(e => {
			const id = e.getId();
			if (id) {
				entitiesIds.push(id);
			}
		});

		return (
			<Fragment>
				<SelectedLinerButton>
					<SecondaryText
						icon={'play'}
						onClick={() => enableCampaigns(entitiesIds)}
						textColor={textColor}
						iconColor={iconColor}
						font={IconFonts.Outlined}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_enable', dataAttrs) }}>
						Enable
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<SecondaryText
						icon={'pause-square'}
						onClick={() => pauseCampaigns(entitiesIds)}
						textColor={textColor}
						iconColor={iconColor}
						font={IconFonts.Outlined}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_pause', dataAttrs) }}>
						Pause
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<EditAction debugProps={{ dataAttrs: suffixToDataAttr('_selected_edit', dataAttrs) }} />
				</SelectedLinerButton>
				<SelectedLinerButton>
					<DuplicateAction debugProps={{ dataAttrs: suffixToDataAttr('_selected_duplicate', dataAttrs) }} entities={entities} />
				</SelectedLinerButton>
				<SelectedLinerButton>
					<DeleteAction
						disabled={entitiesIds.length !== 1}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_delete', dataAttrs) }}
					/>
				</SelectedLinerButton>
			</Fragment>
		);
	};

	const EntityActions = (props: ICampaignEntityActions) => {
		const { entity, actions = { enable: true, pause: true, edit: true, duplicate: true, delete: true } } = props;
		const { EditAction, DeleteAction, buttonClassName } = useDefaultActions({ entities: [entity], ...funcActions });
		const entityId = entity.getId();
		if (!entityId) {
			// log error
			return null;
		}

		return (
			<Fragment>
				{actions.enable ? (
					<QuickActionButton>
						<SecondaryText
							icon={'play'}
							onClick={() => enableCampaigns([entityId])}
							textColor={theme.titleColor}
							iconColor={theme.titleColor}
							className={buttonClassName}
							font={IconFonts.Outlined}
							debugProps={{ dataAttrs: suffixToDataAttr('_quick_enable', dataAttrs) }}>
							Enable
						</SecondaryText>
					</QuickActionButton>
				) : null}
				{actions.pause ? (
					<QuickActionButton>
						<SecondaryText
							icon={'pause-square'}
							onClick={() => pauseCampaigns([entityId])}
							textColor={theme.titleColor}
							iconColor={theme.titleColor}
							className={buttonClassName}
							font={IconFonts.Outlined}
							debugProps={{ dataAttrs: suffixToDataAttr('_quick_pause', dataAttrs) }}>
							Pause
						</SecondaryText>
					</QuickActionButton>
				) : null}
				{actions.edit ? (
					<QuickActionButton>
						<EditAction
							className={buttonClassName}
							iconColor={theme.titleColor}
							textColor={theme.titleColor}
							debugProps={{ dataAttrs: suffixToDataAttr('_quick_edit', dataAttrs) }}
						/>
					</QuickActionButton>
				) : null}
				{actions.duplicate ? (
					<QuickActionButton>
						<DuplicateAction
							entities={[entity]}
							className={buttonClassName}
							iconColor={theme.titleColor}
							textColor={theme.titleColor}
							debugProps={{ dataAttrs: suffixToDataAttr('_quick_duplicate', dataAttrs) }}
						/>
					</QuickActionButton>
				) : null}
				{actions.delete ? (
					<QuickActionButton>
						<DeleteAction
							className={buttonClassName}
							iconColor={theme.titleColor}
							textColor={theme.titleColor}
							debugProps={{ dataAttrs: suffixToDataAttr('_quick_delete', dataAttrs) }}
						/>
					</QuickActionButton>
				) : null}
			</Fragment>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={availableFilters || DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	const SegmentActions = (props: ISegmentActions) => {
		const { segments, onSegment } = useSegments<CampaignModel>({ setSegments: campaignStore.setSegments });

		return (
			<Segments<CampaignModel, PerformanceModel, PerformancesColumns>
				currentSegments={currentSegments}
				availableSegments={segments}
				isComingSoon={segments}
				onClick={segment => onSegment(segment)}
				{...props}
			/>
		);
	};

	const enableCampaigns = (campaignIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Enable'}
					onAction={() => {
						campaignsCrudStore.enableCampaigns(campaignIds).then(() => {
							toastsStore.addToastOnNav({ msg: `Campaigns ${campaignIds} Enabled`, type: DispatchLogsTypes.Success });
							navigate(0);
						});
					}}>
					<EnablePauseCampaignsModal action={'Enable'} campaignIds={campaignIds} />
				</ActionModal>
			),
		});
	};

	const pauseCampaigns = (campaignIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Paused'}
					onAction={() => {
						campaignsCrudStore.pauseCampaigns(campaignIds).then(() => {
							toastsStore.addToastOnNav({ msg: `Campaigns ${campaignIds} Paused`, type: DispatchLogsTypes.Success });
							navigate(0);
						});
					}}>
					<EnablePauseCampaignsModal action={'Paused'} campaignIds={campaignIds} />
				</ActionModal>
			),
		});
	};

	return {
		SelectedActions,
		EntityActions,
		FiltersActions,
		SegmentActions,
	};
};
