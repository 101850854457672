import styles from './page-size.module.scss';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

interface IPageSize {
	pageSize: number;
	setPageSize: (args0: number) => void;
	setPage: (_pageIndex: number, isScrollToTableWrapper?: boolean) => void;
	isScrollToTableWrapper?: boolean;
}

// TODO - check without tableStore

export const PageSize = (props: IPageSize) => {
	const { pageSize, setPageSize, setPage } = props;
	const { tableStore } = usePageSettings();

	if (!tableStore) {
		return null;
	}

	return (
		<div className={styles.wrapper}>
			<Dropdown
				defaultOption={`Show ${pageSize}`}
				isCloseIcon={false}
				label={'Show'}
				options={['Show 20', 'Show 50', 'Show 100', 'Show 200']}
				onSelect={(size: Set<option> | undefined) => {
					const sizeNumber = Number(size?.values().next().value.replace('Show ', ''));
					tableStore.setPageSize(sizeNumber);
					setPageSize(sizeNumber);
					setPage(0, true);
				}}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'page_size')] }}
			/>
		</div>
	);
};
