export enum PermissionActions {
	AccountRead = 'account:read',
	AccountWrite = 'account:write',
	AccountDelete = 'account:delete',
	AdvertiserRead = 'advertiser:read',
	AdvertiserWrite = 'advertiser:write',
	AdvertiserDelete = 'advertiser:delete',
	CampaignRead = 'campaign:read',
	CampaignWrite = 'campaign:write',
	CampaignDelete = 'campaign:delete',
	UsersRead = 'user:read',
	UsersWrite = 'user:write',
	UsersDelete = 'user:delete',
	UsersOnline = 'user:online',
	UserInvite = 'user:invite',
	Public = 'user:public',
	Internal = 'user:internal',
}
