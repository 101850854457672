import { Skeleton } from '@monorepo/base/src/components/skeleton/skeleton';
import { Fragment } from 'react';
import { IInsight } from '../../../hooks/tools/use-insights';
import styles from './list-donut.module.scss';
import { Donut } from '@monorepo/base/src/components/donut/donut';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import dayjs from 'dayjs';
import { DecimalData, PerformanceEnumarableLabels, PerformanceMetricsSymbols } from '@monorepo/tools/src/lib/enums/performance-labels';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import routes from '../../../routes/index.routes';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';

interface IListDonut {
	isLoading: boolean;
	defaultValue: string;
	data: IInsight[];
	isError?: boolean;
}

export const ListDonut = (props: IListDonut) => {
	const { isLoading, defaultValue, data, isError } = props;
	const { startDate, endDate } = useDates();
	const primaryFromDate = dayjs(startDate).format('MMM D, YYYY');
	const primaryToDate = dayjs(endDate).format('MMM D, YYYY');

	const renderForamttedValue = (value: number) => {
		const symbol = PerformanceMetricsSymbols.get(defaultValue as PerformanceEnumarableLabels);
		const formatedNumber = value.toLocaleString() !== 'NaN' ? value.toLocaleString() : '0';

		if (symbol === '%') {
			return `${formatedNumber}${symbol}`;
		} else if (symbol === '$') {
			return `${symbol}${formatedNumber}`;
		}
		return formatedNumber;
	};

	const renderSkeleton = () => {
		const skeletonArr = [];
		for (let i = 0; i < 6; i++) {
			skeletonArr.push(
				<div className={styles.listItem} key={i}>
					<div className={styles.itemName}>
						<Skeleton is={true} rectanglesAmount={1} fullWidth />
						<div className={styles.advertiser}>
							<Skeleton is={true} rectanglesAmount={1} fullWidth />
						</div>
					</div>
					<div className={styles.itemValue}>
						<Skeleton is={true} rectanglesAmount={1} fullWidth />
					</div>
				</div>
			);
		}

		if (isError) {
			skeletonArr.push(
				<span key={'error'} className={styles.error}>
					Oops! Something went wrong...
				</span>
			);
		}

		if (!isLoading && data.length === 0 && !isError) {
			skeletonArr.push(
				<span key={'not-found'} className={styles.noResults}>
					No Results Found
				</span>
			);
		}

		return skeletonArr;
	};

	const headerToDescription = (header: string) => {
		switch (header) {
			case 'Cost':
				return 'Review your campaigns with the highest cost.';
			case 'ROAS':
				return 'Review your campaigns with the best ROAS.';
			case 'Clicks':
				return 'Review your campaigns with the most clicks.';
			case 'Conversions':
				return 'Review your campaigns with the most conversions.';
			case 'CPA':
				return 'Review your campaigns with the highest CPA.';
			default:
				return 'Review your campaigns with the highest cost.';
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.listWrapper}>
				{
					<Fragment>
						<div className={styles.labelWrapper}>
							<div className={styles.labelDescription}>{headerToDescription(defaultValue)}</div>
						</div>
						<div className={styles.headers}>
							<div>Campaign</div>
							<div className={styles.loadingWrapper}>
								<BarLoader is={isLoading} />
							</div>
							<div>{defaultValue}</div>
						</div>
					</Fragment>
				}

				<div className={styles.list}>
					{!isLoading && !isError && data.length !== 0 ? (
						<Fragment>
							{data.map(insight => {
								return (
									<div className={styles.listItem} key={insight.id}>
										<div className={styles.itemName}>
											<TableLink to={routes.campaigns.channels(insight.id)}>{insight.name}</TableLink>
											<div className={styles.advertiser}>{insight.advertiser?.name}</div>
										</div>
										<div className={styles.itemValue}>
											{renderForamttedValue(
												DecimalData.includes(defaultValue) ? parseFloat(insight.value) : parseInt(insight.value)
											)}
										</div>
									</div>
								);
							})}
						</Fragment>
					) : (
						<Fragment>{renderSkeleton()}</Fragment>
					)}
				</div>
			</div>
			<div className={`${styles.donutWrapper} ${styles.flex}`}>
				<div className={styles.donutSize}>
					<Donut
						startDate={primaryFromDate}
						endDate={primaryToDate}
						labels={data.map(item => item.name)}
						data={data.map(item => {
							return DecimalData.includes(defaultValue) ? parseFloat(item.value) : parseInt(item.value);
						})}
						label={defaultValue}
						isLoading={isLoading}
						isError={isError}
					/>
				</div>
			</div>
		</div>
	);
};
