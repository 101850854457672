import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter } from '../../components/filters';
import { performanceFilters } from '../../components/filters/perfomance.filter';
import { Devices } from '../../enums/devices';
import { Strategies } from '../../enums/strategies';

export const campaignFilters = new Map<FilterTypeKey, FilterType>([
	[
		'numberFilters',
		{
			...performanceFilters.numberFilters,
			dailyBudget: { columnName: 'Daily Budget', cellKey: 'dailyBudget' },
			defaultBid: { columnName: 'Default Bid', cellKey: 'defautBid' },
			totalBudget: { columnName: 'Total Budget', cellKey: 'totalBudget' },
		} as INumberFilter,
	],
	[
		'stringFilters',
		{
			...performanceFilters.stringFilters,
			name: { columnName: 'Name', cellKey: 'name' },
			accountName: { columnName: 'Account Name', cellKey: 'account_name' },
			adveriserName: { columnName: 'Advertiser Name', cellKey: 'advertiser_name' },
			landingPage: { columnName: 'Landing Page', cellKey: 'landingPage' },
			geo: { columnName: 'Geo', cellKey: 'geo' },
		} as IStringFilter,
	],
	[
		'enumFilters',
		{
			...performanceFilters.enumFilters,
			strategyType: { columnName: 'Strategy Type', cellKey: 'strategyType', enumOptions: Object.values(Strategies), deletable: true },
			devices: { columnName: 'Devices', cellKey: 'devices', enumOptions: Object.keys(Devices), deletable: true },
		} as IEnumFilter,
	],
]);
