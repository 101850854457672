import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { createColumnHelper } from '@tanstack/react-table';
import { Fragment, useEffect } from 'react';
import { IReviewCampaigns, ReviewCampaignsModel } from '../../models/review-campaigns.model';
import { IMFPStore } from '../../stores';
import { IMergedHeadersReturn } from './index.headers';
import styles from './review-campaigns.module.scss';
import { Tooltip, TooltipThemes } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { Row } from '@tanstack/react-table';
import { Strategies } from '../../enums/strategies';

interface IReviewCampaignsHeaders {
	strategyType: Strategies;
}

const columnHelper = createColumnHelper<IReviewCampaigns>();

const ErrorTooltip = ({ row }: { row: Row<IReviewCampaigns> }) => {
	//columnIndex = 1,
	const ErrorCard = ({ column = '', errors = [''] }) => {
		const errorCards = errors?.map((error, i) => {
			return (
				<div key={i} className={styles.errorCard}>
					<div className={styles.errorDescWrapper}>
						{/* <div className={styles.errorCardIndex}>#{columnIndex + i}</div> */}
						<div className={styles.errorCardErrorKey}>Error:</div>
						<div className={styles.errorCardErrorValue}>{error}</div>
					</div>
					<div className={styles.columnNameWrapper}>
						<div className={styles.errorCardColumnKey}>Column:</div>
						<div className={styles.errorCardColumnValue}>{column}</div>
					</div>
				</div>
			);
		});

		return <Fragment>{errorCards}</Fragment>;
	};

	const renderTooltipContent = () => {
		return (
			<div className={styles.errorTooltip}>
				<span className={styles.errorTooltipTitle}>
					<Icon font={IconFonts.Filled} className={`${styles.reviewStatus} ${styles.error}`} isMFP={true}>
						alert-circle
					</Icon>
					<span>Error Informations</span>
				</span>
				{Object.keys({ ...row.original.Errors })?.map((error, index) => {
					//columnIndex={index + 1}
					return (
						<Fragment key={index}>
							{row?.original?.Errors && <ErrorCard column={error} errors={row.original.Errors[error]} />}
						</Fragment>
					);
				})}
			</div>
		);
	};

	return (
		<Tooltip maxHeight={265} isHoveredOnTooltip theme={TooltipThemes.Light} content={renderTooltipContent()}>
			<div className={styles.errorColumnWrapper}>
				<Icon font={IconFonts.Filled} className={`${styles.reviewStatus} ${styles.error}`} isMFP={true}>
					alert-circle
				</Icon>
				<span className={styles.errorsCount}>{Object.keys({ ...row.original.Errors }).length}</span>
			</div>
		</Tooltip>
	);
};

export const useReviewCampaignsHeaders = (props: IReviewCampaignsHeaders): IMergedHeadersReturn<ReviewCampaignsModel> => {
	const { strategyType } = props;
	const { currentRouteWithoutSpecialChars } = useRoute();
	const { settingsStore } = useStores<IMFPStore>();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore?.setColumnVisibility({
				// id: false,
			});
		}
	}, [tableStore]);

	const getHeadersByStrategyType = () => {
		if (strategyType === Strategies.Manual) {
			return [
				columnHelper.accessor('Bid', {
					header: 'Bid',
					cell: ({ row }) => {
						return (
							<Tooltip
								theme={TooltipThemes.Light}
								is={Boolean(row?.original?.Errors?.['Bid'])}
								content={
									<div>
										{row?.original?.Errors?.['Bid']?.map((error, index) => <span key={index}>{error}</span> || '')}
									</div>
								}>
								<div
									style={
										row?.original?.Errors?.['Bid']
											? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
											: { textAlign: 'center' }
									}>
									{row?.original?.['Bid']}
								</div>
							</Tooltip>
						);
					},
				}),
			];
		}

		return [
			columnHelper.accessor('Algo Version', {
				header: 'Algo Version',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Algo Version'])}
							content={
								<div>
									{row?.original?.Errors?.['Algo Version']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Algo Version']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Algo Version']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Target ROAS', {
				header: 'Target ROAS',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Target ROAS'])}
							content={
								<div>
									{row?.original?.Errors?.['Target ROAS']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Target ROAS']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Target ROAS']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Learning Budget Per Source', {
				header: 'Learning Budget Per Source',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Learning Budget Per Source'])}
							content={
								<div>
									{row?.original?.Errors?.['Learning Budget Per Source']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Learning Budget Per Source']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Learning Budget Per Source']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Learning Clicks Per Hq Source', {
				header: 'Learning Clicks Per Hq Source',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Learning Clicks Per Hq Source'])}
							content={
								<div>
									{row?.original?.Errors?.['Learning Clicks Per Hq Source']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Learning Clicks Per Hq Source']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Learning Clicks Per Hq Source']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Learning Clicks Per Rq Source', {
				header: 'Learning Clicks Per Rq Source',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Learning Clicks Per Rq Source'])}
							content={
								<div>
									{row?.original?.Errors?.['Learning Clicks Per Rq Source']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Learning Clicks Per Rq Source']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Learning Clicks Per Rq Source']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Maximum Learning Bid', {
				header: 'Maximum Learning Bid',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Maximum Learning Bid'])}
							content={
								<div>
									{row?.original?.Errors?.['Maximum Learning Bid']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Maximum Learning Bid']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Maximum Learning Bid']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Maximum Optimizing Bid', {
				header: 'Maximum Optimizing Bid',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Maximum Optimizing Bid'])}
							content={
								<div>
									{row?.original?.Errors?.['Maximum Optimizing Bid']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Maximum Optimizing Bid']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Maximum Optimizing Bid']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Campaign Level Optimization', {
				header: 'Campaign Level Optimization',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Campaign Level Optimization'])}
							content={
								<div>
									{row?.original?.Errors?.['Campaign Level Optimization']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Campaign Level Optimization']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Campaign Level Optimization']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Statistics Window1', {
				header: 'Statistics Window1',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Statistics Window1'])}
							content={
								<div>
									{row?.original?.Errors?.['Statistics Window1']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Statistics Window1']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Statistics Window1']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Statistics Window2', {
				header: 'Statistics Window2',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Statistics Window2'])}
							content={
								<div>
									{row?.original?.Errors?.['Statistics Window2']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Statistics Window2']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Statistics Window2']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Max First Conversions', {
				header: 'Max First Conversions',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Max First Conversions'])}
							content={
								<div>
									{row?.original?.Errors?.['Max First Conversions']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Max First Conversions']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Max First Conversions']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Min Second First Rate', {
				header: 'Min Second First Rate',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Min Second First Rate'])}
							content={
								<div>
									{row?.original?.Errors?.['Min Second First Rate']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Min Second First Rate']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Min Second First Rate']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Min ROAS', {
				header: 'Min ROAS',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Min ROAS'])}
							content={
								<div>
									{row?.original?.Errors?.['Min ROAS']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Min ROAS']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Min ROAS']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Max Cpa', {
				header: 'Max Cpa',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Max Cpa'])}
							content={
								<div>
									{row?.original?.Errors?.['Max Cpa']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Max Cpa']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Max Cpa']}
							</div>
						</Tooltip>
					);
				},
			}),
		];
	};

	return {
		columns: [
			//fix [Table] Column with id 'id' does not exist in console
			columnHelper.accessor('id', {
				enableHiding: true,
				header: '',
				cell: () => null,
			}),
			columnHelper.accessor('Errors', {
				header: '',
				cell: ({ row }) => {
					//change later to if no id

					if (!row.original.rowId) {
						return null;
					}

					if (!row.original.Errors) {
						return (
							<div className={styles.errorColumnWrapper}>
								<Icon font={IconFonts.Filled} className={`${styles.reviewStatus} ${styles.success}`} isMFP={true}>
									check-circle
								</Icon>
							</div>
						);
					}

					return <ErrorTooltip row={row} />;
				},
			}),
			columnHelper.accessor('rowId', {
				header: 'Row Id',
			}),
			columnHelper.accessor('Status', {
				header: 'Status',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Status'])}
							content={
								<div>
									{row?.original?.Errors?.['Status']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Status']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Status']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Campaign Name', {
				header: 'Campaign',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Campaign Name'])}
							content={
								<div>
									{row?.original?.Errors?.['Campaign Name']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Campaign Name']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Campaign Name']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Account Name', {
				header: 'Account',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Account Name'])}
							content={
								<div>
									{row?.original?.Errors?.['Account Name']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Account Name']
										? { color: 'red', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Account Name']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Account ID', {
				header: 'Account ID',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Account ID'])}
							content={
								<div>
									{row?.original?.Errors?.['Account ID']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Account ID']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Account ID']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Advertiser Name', {
				header: 'Advertiser Name',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Advertiser Name'])}
							content={
								<div>
									{row?.original?.Errors?.['Advertiser Name']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Advertiser Name']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Advertiser Name']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Advertiser ID', {
				header: 'Advertiser ID',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Advertiser ID'])}
							content={
								<div>
									{row?.original?.Errors?.['Advertiser ID']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Advertiser ID']
										? { color: 'red', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Advertiser ID']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Advertiser Domain', {
				header: 'Avertiser Domain',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Advertiser Domain'])}
							content={
								<div>
									{row?.original?.Errors?.['Advertiser Domain']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Advertiser Domain']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Advertiser Domain']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('advertiser category', {
				header: 'Advertiser Category',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['advertiser category'])}
							content={
								<div>
									{row?.original?.Errors?.['advertiser category']?.map(
										(error, index) => <span key={index}>{error}</span> || ''
									)}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['advertiser category']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['advertiser category']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Landing Page', {
				header: 'Landing Page',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Landing Page'])}
							content={
								<div>
									{row?.original?.Errors?.['Landing Page']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Landing Page']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Landing Page']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('DP', {
				header: 'DP',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['DP'])}
							content={
								<div>{row?.original?.Errors?.['DP']?.map((error, index) => <span key={index}>{error}</span> || '')}</div>
							}>
							<div
								style={
									row?.original?.Errors?.['DP']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['DP']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Start Date', {
				header: 'Start Date',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Start Date'])}
							content={
								<div>
									{row?.original?.Errors?.['Start Date']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Start Date']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Start Date']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('End Date', {
				header: 'End Date',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['End Date'])}
							content={
								<div>
									{row?.original?.Errors?.['End Date']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['End Date']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['End Date']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Mobile', {
				header: 'Mobile',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Mobile'])}
							content={
								<div>
									{row?.original?.Errors?.['Mobile']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Mobile']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Mobile']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Desktop', {
				header: 'Desktop',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Desktop'])}
							content={
								<div>
									{row?.original?.Errors?.['Desktop']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Desktop']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Desktop']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Country', {
				header: 'Country',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Country'])}
							content={
								<div>
									{row?.original?.Errors?.['Country']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Country']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Country']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Total Budget', {
				header: 'Total Budget',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Total Budget'])}
							content={
								<div>
									{row?.original?.Errors?.['Total Budget']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Total Budget']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Total Budget']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Daily Budget', {
				header: 'Daily Budget',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Daily Budget'])}
							content={
								<div>
									{row?.original?.Errors?.['Daily Budget']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Daily Budget']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Daily Budget']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Contextual', {
				header: 'Contextual',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Contextual'])}
							content={
								<div>
									{row?.original?.Errors?.['Contextual']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Contextual']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Contextual']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('BNPL', {
				header: 'BNPL',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['BNPL'])}
							content={
								<div>{row?.original?.Errors?.['BNPL']?.map((error, index) => <span key={index}>{error}</span> || '')}</div>
							}>
							<div
								style={
									row?.original?.Errors?.['BNPL']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['BNPL']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Push', {
				header: 'Push',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Push'])}
							content={
								<div>{row?.original?.Errors?.['Push']?.map((error, index) => <span key={index}>{error}</span> || '')}</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Push']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Push']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Native', {
				header: 'Native',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Native'])}
							content={
								<div>
									{row?.original?.Errors?.['Native']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Native']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Native']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Search', {
				header: 'Search',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Search'])}
							content={
								<div>
									{row?.original?.Errors?.['Search']?.map((error, index) => <span key={index}>{error}</span> || '')}
								</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Search']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Search']}
							</div>
						</Tooltip>
					);
				},
			}),
			columnHelper.accessor('Deals', {
				header: 'Deals',
				cell: ({ row }) => {
					return (
						<Tooltip
							theme={TooltipThemes.Light}
							is={Boolean(row?.original?.Errors?.['Deals'])}
							content={
								<div>{row?.original?.Errors?.['Deals']?.map((error, index) => <span key={index}>{error}</span> || '')}</div>
							}>
							<div
								style={
									row?.original?.Errors?.['Deals']
										? { color: '#dc3545', fontWeight: 600, textAlign: 'center' }
										: { textAlign: 'center' }
								}>
								{row?.original?.['Deals']}
							</div>
						</Tooltip>
					);
				},
			}),
			...getHeadersByStrategyType(),
		],
		columnVisibility: {
			id: false,
		},
	};
};
