import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { useCallback, useEffect, useState } from 'react';
import { CampaignsApi } from '../../../apis/campaigns.api';
import { Strategies } from '../../../enums/strategies';
import { IMFPStore } from '../../../stores';
import styles from './publishing-progress.module.scss';

export interface IPublishingProgress {
	notificationId: id;
	bulkId: id;
	totalCampaigns: number;
	createdAmount: number;
	updatedAmount: number;
	errorsAmount: number;
	percentage: number;
	fileName: string;
	strategyType: Strategies;
}

export const PublishingProgress = (props: IPublishingProgress) => {
	const { notificationsStore } = useStores<IMFPStore>();
	const { bulkId, errorsAmount, percentage, notificationId, fileName, strategyType, totalCampaigns } = props;
	const notificationFromStore = notificationsStore.getNotification(notificationId);
	const [requestsCount, setRequestsCount] = useState<number>(1);
	const isFinished = percentage === 100;

	const caluculateProgress = (createdAmount: number, updatedAmount: number, errorsAmount: number, totalCampaigns: number): number => {
		if (totalCampaigns === 0) {
			return 0;
		}

		return ((createdAmount + updatedAmount + errorsAmount) / totalCampaigns) * 100;
	};

	const renderStatusIcon = useCallback(() => {
		if (!isFinished) {
			return null;
		}

		if (errorsAmount > 0) {
			return (
				<Icon font={IconFonts.Filled} className={`${styles.errorIcon}`} isMFP={true}>
					alert-circle
				</Icon>
			);
		} else {
			return (
				<Icon font={IconFonts.Filled} className={`${styles.successIcon}`} isMFP={true}>
					check-circle
				</Icon>
			);
		}
	}, [isFinished]);

	const renderContent = useCallback(() => {
		if (!isFinished) {
			return (
				<div className={styles.progressWrapper}>
					<div className={styles.container}>
						<div className={styles.percentage}>{`${percentage?.toFixed(0)}%`}</div>
						<div style={fillerStyles} className={styles.filter}></div>
					</div>
				</div>
			);
		}

		if (errorsAmount > 0) {
			return <div className={styles.errorsSummary}>Failed publishing {errorsAmount} campaigns</div>;
		}

		return <div className={styles.publishSuccess}>{totalCampaigns} campaigns published successfully</div>;
	}, [isFinished, percentage]);

	const fillerStyles: React.CSSProperties = {
		width: `${percentage}%`,
		// backgroundColor: progress.percentage === 100 ? '#12B76A' : '#1570EF',
		backgroundColor: '#1570EF',
	};

	useEffect(() => {
		let timeout: NodeJS.Timeout | null = null;
		if (requestsCount > 0 && !isFinished) {
			timeout = setTimeout(() => {
				CampaignsApi.bulkCampaign(bulkId).then(res => {
					const { createdAmount, updatedAmount, errorsAmount, totalCampaigns } = res;
					const notification = notificationsStore.getNotification(notificationId);
					if (
						notification &&
						notificationFromStore &&
						percentage !== caluculateProgress(createdAmount, updatedAmount, errorsAmount, totalCampaigns)
					) {
						notificationFromStore.extraData = {
							...notification.extraData,
							createdAmount,
							updatedAmount,
							errorsAmount,
							totalCampaigns,
							percentage: caluculateProgress(createdAmount, updatedAmount, errorsAmount, totalCampaigns),
						};
					}
					setRequestsCount(isFinished ? 0 : requestsCount + 1);
				});
			}, 2000);
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [requestsCount]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.fileNameWrapper}>
				{isFinished && renderStatusIcon()}
				<span className={styles.fileName}>{fileName}</span>
				<Icon font={IconFonts.Filled} className={`${styles.strategyType}`} isMFP={true}>
					{Strategies.Manual === strategyType ? 'sliders-01' : 'magic-wand-01'}
				</Icon>
			</div>
			{renderContent()}
		</div>
	);
};
