import { CreateCampaign } from '../../pages/campaigns/create/create-campaign.page';
import { FullLayout } from '../components/layouts/full-layout/full-layout';
import { PageLayout } from '../components/layouts/page-layout/page-layout';
import { Campaigns } from '../../pages/campaigns/index/campaigns.page';
import routes from '../routes/index.routes';
import { CampaignView } from '../../pages/campaigns/view/campaign-view.page';
import { EditCampaign } from '../../pages/campaigns/edit/edit-campaign.page';
import { CampaignChannels } from '../../pages/campaigns/view/channels/channels.campaign';
import { CampaignSources } from '../../pages/campaigns/view/sources/sources.campaign';
import { CampaignOverview } from '../../pages/campaigns/view/overview/overview.campaign';
import { DuplicateCampaign } from '../../pages/campaigns/duplicate/duplicate-campaign.page';
import { GuardRoute } from '../components/guard/guard-route';
import { PermissionActions } from '../enums/permissions';
import { validateAction } from '../hooks/tools/use-guard';
import { BulkCampaigns } from '../../pages/campaigns/bulk/bulk-campaigns.page';
import { BulkHistory } from '../../pages/campaigns/bulk/bulk-history/bulk-history.page';
import { BulkOptions } from '../../pages/campaigns/bulk/bulk-options/bulk-options.page';

export const CampaignsRouter = [
	{
		element: <FullLayout />,
		children: [
			{
				path: routes.campaigns.create(),
				element: (
					<GuardRoute validate={validateAction([PermissionActions.CampaignWrite])}>
						<CreateCampaign />
					</GuardRoute>
				),
			},
			{
				path: routes.campaigns.edit(':campaignId'),
				element: (
					<GuardRoute validate={validateAction([PermissionActions.CampaignWrite])}>
						<EditCampaign />
					</GuardRoute>
				),
			},
			{
				path: routes.campaigns.duplicate(':campaignId'),
				element: (
					<GuardRoute validate={validateAction([PermissionActions.CampaignWrite])}>
						<DuplicateCampaign />
					</GuardRoute>
				),
			},
		],
	},
	{
		element: <PageLayout />,
		children: [
			{
				path: routes.campaigns.index(),
				element: <Campaigns />,
			},
			{
				path: routes.campaigns.bulk(),
				element: <BulkCampaigns />,
				children: [
					{
						path: routes.campaigns.bulkHistory(),
						element: <BulkHistory />,
					},
					{
						path: routes.campaigns.bulkOptions(),
						element: <BulkOptions />,
					},
				],
			},
			{
				element: (
					<GuardRoute validate={validateAction([PermissionActions.CampaignRead])}>
						<CampaignView />
					</GuardRoute>
				),
				children: [
					{
						path: routes.campaigns.view(':campaignId'),
						element: <CampaignOverview />,
					},
					{
						path: routes.campaigns.channels(':campaignId'),
						element: <CampaignChannels />,
					},
					{
						path: routes.campaigns.sources(':campaignId'),
						element: <CampaignSources />,
					},
				],
			},
		],
	},
];
