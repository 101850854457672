import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { Row } from '@tanstack/react-table';
import { makeAutoObservable, observable } from 'mobx';
import { CampaignsApi } from '../../../apis/campaigns.api';
import { ReviewCampaignsApi } from '../../../apis/review-campaigns.api';
import { Strategies } from '../../../enums/strategies';
import { ReviewCampaignsModel, ReviewCampaignsPayload } from '../../../models/review-campaigns.model';
import { ReviewCampaignsCrudStore } from './review-campaigns-crud.store';

export class ReviewCampaignsStore {
	crud = new ReviewCampaignsCrudStore();

	list = new BaseListStore<ReviewCampaignsModel>({
		listFunc: ReviewCampaignsApi.list,
		model: ReviewCampaignsModel,
	});

	sortedRows: Row<ReviewCampaignsModel>[] = [];

	manualTemplate = new HttpStore<undefined, File | null>({
		httpFunc: () => CampaignsApi.csvDownloadTemplate(Strategies.Manual),
	});

	smartTemplate = new HttpStore<undefined, File | null>({
		httpFunc: () => CampaignsApi.csvDownloadTemplate(Strategies.Smart),
	});

	uploadManualTemplate = new HttpStore<File, ReviewCampaignsPayload | null>({
		httpFunc: (file: File) => CampaignsApi.manualCsvUpload(file),
	});

	uploadSmartTemplate = new HttpStore<File, ReviewCampaignsPayload | null>({
		httpFunc: (file: File) => CampaignsApi.smartCsvUpload(file),
	});

	settingsStore: SettingsStore;

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this, {
			crud: observable,
			sortedRows: observable,
		});
		this.settingsStore = settingsStore;
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}

	getSortedRows(): Row<ReviewCampaignsModel>[] {
		return this.sortedRows;
	}

	setSortedRows(sortedRows: Row<ReviewCampaignsModel>[]): void {
		this.sortedRows = sortedRows;
	}

	getManualTemplate(): HttpStore<undefined, File | null> {
		return this.manualTemplate;
	}

	getSmartTemplate(): HttpStore<undefined, File | null> {
		return this.smartTemplate;
	}
	getUploadManualTemplate(): HttpStore<File, ReviewCampaignsPayload | null> {
		return this.uploadManualTemplate;
	}
	getUploadSmartTemplate(): HttpStore<File, ReviewCampaignsPayload | null> {
		return this.uploadSmartTemplate;
	}
}
