import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { AccountModel } from './account.model';
import { IPerformanceItem } from './performance.model';
import { StatusType } from '../enums/status';
import { CampaignModel } from './campaign.model';
import { ConversionModel, IConversion } from './conversion.model';

export interface ICategory {
	id: number;
	value: string;
}

export interface IAdvertiser extends IPerformanceItem {
	id?: id;
	name?: string;
	status?: StatusType;
	account?: AccountModel;
	categoryId?: number;
	mainDomain?: string;
	campaigns?: CampaignModel[];
	conversionActions?: IConversion[];
}

export interface IAdvertiserCreateForm {
	id?: id;
	name?: string;
	categoryId?: id;
	mainDomain?: string;
	account?: AccountModel;
}

export interface IAdvertiserEditForm {
	id?: id;
	name?: string;
	categoryId?: id;
	mainDomain?: string;
	account?: AccountModel;
	status?: StatusType;
}

export class AdvertiserModel implements IAdvertiser {
	id?: id;
	name?: string;
	status?: StatusType;
	account?: AccountModel;
	budget?: number;
	daily_cap?: number;
	clicks?: number;
	conversions?: number;
	cost?: number;
	revenue?: number;
	impressions?: number;
	roas?: number;
	cpc?: number;
	cpa?: number;
	cpm?: number;
	cvr?: number;
	categoryId?: number;
	mainDomain?: string;
	campaigns?: CampaignModel[];
	conversionActions?: ConversionModel[];

	constructor(advertiser?: IAdvertiser) {
		this.id = advertiser?.id;
		this.name = advertiser?.name;
		this.status = advertiser?.status;
		this.account = new AccountModel(advertiser?.account);
		this.clicks = advertiser?.clicks;
		this.conversions = advertiser?.conversions;
		this.cost = advertiser?.cost;
		this.revenue = advertiser?.revenue;
		this.impressions = advertiser?.impressions;
		this.roas = advertiser?.roas;
		this.cpc = advertiser?.cpc;
		this.cpa = advertiser?.cpa;
		this.cpm = advertiser?.cpm;
		this.cvr = advertiser?.cvr;
		this.categoryId = advertiser?.categoryId;
		this.mainDomain = advertiser?.mainDomain || '';
		this.campaigns = advertiser?.campaigns ? advertiser?.campaigns.map(campaign => new CampaignModel(campaign)) : [];
		this.conversionActions = advertiser?.conversionActions?.map(c => new ConversionModel(c));

		makeAutoObservable(this);
	}

	getId(): id | undefined {
		return this.id;
	}

	getName(): string | undefined {
		return this.name;
	}

	setName(name: string) {
		this.name = name;
	}

	getCategoryId(): number | undefined {
		return this.categoryId;
	}

	setCategoryId(categoryId: number) {
		this.categoryId = categoryId;
	}

	getMainDomain(): string | undefined {
		return this.mainDomain;
	}

	setMainDomain(mainDomain: string) {
		this.mainDomain = (mainDomain || '').toLowerCase();
	}

	getAccount(): AccountModel | undefined {
		return this.account;
	}

	setAccount(account: AccountModel): void {
		this.account = account;
	}

	getCampaigns(): CampaignModel[] | undefined {
		return this.campaigns;
	}

	setCampaigns(campaigns: CampaignModel[]): void {
		this.campaigns = campaigns;
	}

	getConversions(): ConversionModel[] | undefined {
		return this.conversionActions;
	}
	setConversions(conversions: ConversionModel[] | undefined) {
		this.conversionActions = conversions;
	}

	getStatus() {
		return this.status;
	}
}
