import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { BulkCampaignsModel } from './bulk-campaigns.model';

export interface IReviewCampaignsPayload {
	item: {
		bulkCampaign: BulkCampaignsModel;
		campaigns: ReviewCampaignsModel[];
	};
}

export class ReviewCampaignsPayload {
	item: {
		bulkCampaign: BulkCampaignsModel;
		campaigns: ReviewCampaignsModel[];
	};

	constructor(ReviewCampaignsResponse: IReviewCampaignsPayload) {
		this.item = ReviewCampaignsResponse?.item;
		makeAutoObservable(this);
	}
}

export type reviewCampaignsErrors = { [key: string]: string };

export interface IReviewCampaigns {
	rowId?: id;
	Status?: string;
	'Campaign Name'?: string;
	'Account Name'?: string;
	'Account ID'?: string;
	'Advertiser Name'?: string;
	'Advertiser ID'?: string;
	'Advertiser Domain'?: string;
	'advertiser category'?: string;
	'Landing Page'?: string;
	DP?: string;
	'Start Date'?: string;
	'End Date'?: string;
	Mobile?: string;
	Desktop?: string;
	Country?: string;
	'Total Budget'?: string;
	'Daily Budget'?: string;
	Contextual?: string;
	BNPL?: string;
	Push?: string;
	Native?: string;
	Search?: string;
	Deals?: string;
	Bid?: string;
	'Algo Version'?: string;
	'Target ROAS'?: string;
	'Target CPA'?: string;
	'Learning Budget Per Source'?: string;
	'Learning Clicks Per Hq Source'?: string;
	'Learning Clicks Per Rq Source'?: string;
	'Maximum Learning Bid'?: string;
	'Maximum Optimizing Bid'?: string;
	'Campaign Level Optimization'?: string;
	'Statistics Window1'?: string;
	'Statistics Window2'?: string;
	'Max First Conversions'?: string;
	'Min Second First Rate'?: string;
	'Min ROAS'?: string;
	'Max Cpa'?: string;
	Errors?: Record<string, string[]>;
}

export interface IReviewCampaignsCreateForm {
	rowId?: id;
	Status?: string;
	'Campaign Name'?: string;
	'Account Name'?: string;
	'Account ID'?: string;
	'Advertiser Name'?: string;
	'Advertiser ID'?: string;
	'Advertiser Domain'?: string;
	'advertiser category'?: string;
	'Landing Page'?: string;
	DP?: string;
	'Start Date'?: string;
	'End Date'?: string;
	Mobile?: string;
	Desktop?: string;
	Country?: string;
	'Total Budget'?: string;
	'Daily Budget'?: string;
	Contextual?: string;
	BNPL?: string;
	Push?: string;
	Native?: string;
	Search?: string;
	Deals?: string;
	Bid?: string;
	'Algo Version'?: string;
	'Target ROAS'?: string;
	'Target CPA'?: string;
	'Learning Budget Per Source'?: string;
	'Learning Clicks Per Hq Source'?: string;
	'Learning Clicks Per Rq Source'?: string;
	'Maximum Learning Bid'?: string;
	'Maximum Optimizing Bid'?: string;
	'Campaign Level Optimization'?: string;
	'Statistics Window1'?: string;
	'Statistics Window2'?: string;
	'Max First Conversions'?: string;
	'Min Second First Rate'?: string;
	'Min ROAS'?: string;
	'Max Cpa'?: string;
}

export interface IReviewCampaignsEditForm {
	rowId?: id;
	Status?: string;
	'Campaign Name'?: string;
	'Account Name'?: string;
	'Account ID'?: string;
	'Advertiser Name'?: string;
	'Advertiser ID'?: string;
	'Advertiser Domain'?: string;
	'advertiser category'?: string;
	'Landing Page'?: string;
	DP?: string;
	'Start Date'?: string;
	'End Date'?: string;
	Mobile?: string;
	Desktop?: string;
	Country?: string;
	'Total Budget'?: string;
	'Daily Budget'?: string;
	Contextual?: string;
	BNPL?: string;
	Push?: string;
	Native?: string;
	Search?: string;
	Deals?: string;
	Bid?: string;
	'Algo Version'?: string;
	'Target ROAS'?: string;
	'Target CPA'?: string;
	'Learning Budget Per Source'?: string;
	'Learning Clicks Per Hq Source'?: string;
	'Learning Clicks Per Rq Source'?: string;
	'Maximum Learning Bid'?: string;
	'Maximum Optimizing Bid'?: string;
	'Campaign Level Optimization'?: string;
	'Statistics Window1'?: string;
	'Statistics Window2'?: string;
	'Max First Conversions'?: string;
	'Min Second First Rate'?: string;
	'Min ROAS'?: string;
	'Max Cpa'?: string;
}

export class ReviewCampaignsModel implements IReviewCampaigns {
	rowId?: id;
	Status?: string;
	'Campaign Name'?: string;
	'Account Name'?: string;
	'Account ID'?: string;
	'Advertiser Name'?: string;
	'Advertiser ID'?: string;
	'Advertiser Domain'?: string;
	'advertiser category'?: string;
	'Landing Page'?: string;
	DP?: string;
	'Start Date'?: string;
	'End Date'?: string;
	Mobile?: string;
	Desktop?: string;
	Country?: string;
	'Total Budget'?: string;
	'Daily Budget'?: string;
	Contextual?: string;
	BNPL?: string;
	Push?: string;
	Native?: string;
	Search?: string;
	Deals?: string;
	Bid?: string;
	'Algo Version'?: string;
	'Target ROAS'?: string;
	'Target CPA'?: string;
	'Learning Budget Per Source'?: string;
	'Learning Clicks Per Hq Source'?: string;
	'Learning Clicks Per Rq Source'?: string;
	'Maximum Learning Bid'?: string;
	'Maximum Optimizing Bid'?: string;
	'Campaign Level Optimization'?: string;
	'Statistics Window1'?: string;
	'Statistics Window2'?: string;
	'Max First Conversions'?: string;
	'Min Second First Rate'?: string;
	'Min ROAS'?: string;
	'Max Cpa'?: string;
	Errors?: Record<string, string[]>;

	constructor(reviewCampaign: IReviewCampaigns) {
		this.rowId = reviewCampaign?.rowId;
		this.Status = reviewCampaign?.Status;
		this['Campaign Name'] = reviewCampaign?.['Campaign Name'];
		this['Account Name'] = reviewCampaign?.['Account Name'];
		this['Account ID'] = reviewCampaign?.['Account ID'];
		this['Advertiser Name'] = reviewCampaign?.['Advertiser Name'];
		this['Advertiser ID'] = reviewCampaign?.['Advertiser ID'];
		this['Advertiser Domain'] = reviewCampaign?.['Advertiser Domain'];
		this['advertiser category'] = reviewCampaign?.['advertiser category'];
		this['Landing Page'] = reviewCampaign?.['Landing Page'];
		this.DP = reviewCampaign?.DP;
		this['Start Date'] = reviewCampaign?.['Start Date'];
		this['End Date'] = reviewCampaign?.['End Date'];
		this.Mobile = reviewCampaign?.Mobile;
		this.Desktop = reviewCampaign?.Desktop;
		this.Country = reviewCampaign?.Country;
		this['Total Budget'] = reviewCampaign?.['Total Budget'];
		this['Daily Budget'] = reviewCampaign?.['Daily Budget'];
		this.Contextual = reviewCampaign?.Contextual;
		this.BNPL = reviewCampaign?.BNPL;
		this.Push = reviewCampaign?.Push;
		this.Native = reviewCampaign?.Native;
		this.Search = reviewCampaign?.Search;
		this.Deals = reviewCampaign?.Deals;
		this.Bid = reviewCampaign?.Bid;
		this['Algo Version'] = reviewCampaign?.['Algo Version'];
		this['Target ROAS'] = reviewCampaign?.['Target ROAS'];
		this['Target CPA'] = reviewCampaign?.['Target CPA'];
		this['Learning Budget Per Source'] = reviewCampaign?.['Learning Budget Per Source'];
		this['Learning Clicks Per Hq Source'] = reviewCampaign?.['Learning Clicks Per Hq Source'];
		this['Learning Clicks Per Rq Source'] = reviewCampaign?.['Learning Clicks Per Rq Source'];
		this['Maximum Learning Bid'] = reviewCampaign?.['Maximum Learning Bid'];
		this['Maximum Optimizing Bid'] = reviewCampaign?.['Maximum Optimizing Bid'];
		this['Campaign Level Optimization'] = reviewCampaign?.['Campaign Level Optimization'];
		this['Statistics Window1'] = reviewCampaign?.['Statistics Window1'];
		this['Statistics Window2'] = reviewCampaign?.['Statistics Window2'];
		this['Max First Conversions'] = reviewCampaign?.['Max First Conversions'];
		this['Min Second First Rate'] = reviewCampaign?.['Min Second First Rate'];
		this['Min ROAS'] = reviewCampaign?.['Min ROAS'];
		this['Max Cpa'] = reviewCampaign?.['Max Cpa'];
		this.Errors = reviewCampaign?.Errors;

		makeAutoObservable(this);
	}

	getId(): id | undefined {
		return this.rowId;
	}

	getStatus(): string | undefined {
		return this.Status;
	}

	getCampaignName(): string | undefined {
		return this['Campaign Name'];
	}

	getAccountName(): string | undefined {
		return this['Account Name'];
	}

	getAccountId(): string | undefined {
		return this['Account ID'];
	}

	getAdvertiserName(): string | undefined {
		return this['Advertiser Name'];
	}

	getAdvertiserId(): string | undefined {
		return this['Advertiser ID'];
	}

	getAdvertiserDomain(): string | undefined {
		return this['Advertiser Domain'];
	}

	getAdvertiserCategory(): string | undefined {
		return this['advertiser category'];
	}

	getLandingPage(): string | undefined {
		return this['Landing Page'];
	}

	getDP(): string | undefined {
		return this.DP;
	}

	getStartDate(): string | undefined {
		return this['Start Date'];
	}

	getEndDate(): string | undefined {
		return this['End Date'];
	}

	getMobile(): string | undefined {
		return this.Mobile;
	}

	getDesktop(): string | undefined {
		return this.Desktop;
	}

	getCountry(): string | undefined {
		return this.Country;
	}

	getTotalBudget(): string | undefined {
		return this['Total Budget'];
	}

	getDailyBudget(): string | undefined {
		return this['Daily Budget'];
	}

	getContextual(): string | undefined {
		return this.Contextual;
	}

	getBNPL(): string | undefined {
		return this.BNPL;
	}

	getPush(): string | undefined {
		return this.Push;
	}

	getNative(): string | undefined {
		return this.Native;
	}

	getSearch(): string | undefined {
		return this.Search;
	}

	getDeals(): string | undefined {
		return this.Deals;
	}

	getBid(): string | undefined {
		return this.Bid;
	}

	getAlgoVersion(): string | undefined {
		return this['Algo Version'];
	}

	getTargetROAS(): string | undefined {
		return this['Target ROAS'];
	}

	getTargetCPA(): string | undefined {
		return this['Target CPA'];
	}

	getLearningBudgetPerSource(): string | undefined {
		return this['Learning Budget Per Source'];
	}

	getLearningClicksPerHqSource(): string | undefined {
		return this['Learning Clicks Per Hq Source'];
	}

	getLearningClicksPerRqSource(): string | undefined {
		return this['Learning Clicks Per Rq Source'];
	}

	getMaximumLearningBid(): string | undefined {
		return this['Maximum Learning Bid'];
	}

	getMaximumOptimizingBid(): string | undefined {
		return this['Maximum Optimizing Bid'];
	}

	getCampaignLevelOptimization(): string | undefined {
		return this['Campaign Level Optimization'];
	}

	getStatisticsWindow1(): string | undefined {
		return this['Statistics Window1'];
	}

	getStatisticsWindow2(): string | undefined {
		return this['Statistics Window2'];
	}

	getMaxFirstConversions(): string | undefined {
		return this['Max First Conversions'];
	}

	getMinSecondFirstRate(): string | undefined {
		return this['Min Second First Rate'];
	}

	getMinROAS(): string | undefined {
		return this['Min ROAS'];
	}

	getMaxCpa(): string | undefined {
		return this['Max Cpa'];
	}

	setRowId(rowId: id | undefined): void {
		this.rowId = rowId;
	}

	setStatus(Status: string | undefined): void {
		this.Status = Status;
	}

	setCampaignName(CampaignName: string | undefined): void {
		this['Campaign Name'] = CampaignName;
	}

	setAccountName(AccountName: string | undefined): void {
		this['Account Name'] = AccountName;
	}

	setAccountId(AccountId: string | undefined): void {
		this['Account ID'] = AccountId;
	}

	setAdvertiserName(AdvertiserName: string | undefined): void {
		this['Advertiser Name'] = AdvertiserName;
	}

	setAdvertiserId(AdvertiserId: string | undefined): void {
		this['Advertiser ID'] = AdvertiserId;
	}

	setAdvertiserDomain(AdvertiserDomain: string | undefined): void {
		this['Advertiser Domain'] = AdvertiserDomain;
	}

	setAdvertiserCategory(AdvertiserCategory: string | undefined): void {
		this['advertiser category'] = AdvertiserCategory;
	}

	setLandingPage(LandingPage: string | undefined): void {
		this['Landing Page'] = LandingPage;
	}

	setDP(DP: string | undefined): void {
		this.DP = DP;
	}

	setStartDate(StartDate: string | undefined): void {
		this['Start Date'] = StartDate;
	}

	setEndDate(EndDate: string | undefined): void {
		this['End Date'] = EndDate;
	}

	setMobile(Mobile: string | undefined): void {
		this.Mobile = Mobile;
	}

	setDesktop(Desktop: string | undefined): void {
		this.Desktop = Desktop;
	}

	setCountry(Country: string | undefined): void {
		this.Country = Country;
	}

	setTotalBudget(TotalBudget: string | undefined): void {
		this['Total Budget'] = TotalBudget;
	}

	setDailyBudget(DailyBudget: string | undefined): void {
		this['Daily Budget'] = DailyBudget;
	}

	setContextual(Contextual: string | undefined): void {
		this.Contextual = Contextual;
	}

	setBNPL(BNPL: string | undefined): void {
		this.BNPL = BNPL;
	}

	setPush(Push: string | undefined): void {
		this.Push = Push;
	}

	setNative(Native: string | undefined): void {
		this.Native = Native;
	}

	setSearch(Search: string | undefined): void {
		this.Search = Search;
	}

	setDeals(Deals: string | undefined): void {
		this.Deals = Deals;
	}

	setBid(Bid: string | undefined): void {
		this.Bid = Bid;
	}

	setAlgoVersion(AlgoVersion: string | undefined): void {
		this['Algo Version'] = AlgoVersion;
	}

	setTargetROAS(TargetROAS: string | undefined): void {
		this['Target ROAS'] = TargetROAS;
	}

	setTargetCPA(TargetCPA: string | undefined): void {
		this['Target CPA'] = TargetCPA;
	}

	setLearningBudgetPerSource(LearningBudgetPerSource: string | undefined): void {
		this['Learning Budget Per Source'] = LearningBudgetPerSource;
	}

	setLearningClicksPerHqSource(LearningClicksPerHqSource: string | undefined): void {
		this['Learning Clicks Per Hq Source'] = LearningClicksPerHqSource;
	}

	setLearningClicksPerRqSource(LearningClicksPerRqSource: string | undefined): void {
		this['Learning Clicks Per Rq Source'] = LearningClicksPerRqSource;
	}

	setMaximumLearningBid(MaximumLearningBid: string | undefined): void {
		this['Maximum Learning Bid'] = MaximumLearningBid;
	}

	setMaximumOptimizingBid(MaximumOptimizingBid: string | undefined): void {
		this['Maximum Optimizing Bid'] = MaximumOptimizingBid;
	}

	setCampaignLevelOptimization(CampaignLevelOptimization: string | undefined): void {
		this['Campaign Level Optimization'] = CampaignLevelOptimization;
	}

	setStatisticsWindow1(StatisticsWindow1: string | undefined): void {
		this['Statistics Window1'] = StatisticsWindow1;
	}

	setStatisticsWindow2(StatisticsWindow2: string | undefined): void {
		this['Statistics Window2'] = StatisticsWindow2;
	}

	setMaxFirstConversions(MaxFirstConversions: string | undefined): void {
		this['Max First Conversions'] = MaxFirstConversions;
	}

	setMinSecondFirstRate(MinSecondFirstRate: string | undefined): void {
		this['Min Second First Rate'] = MinSecondFirstRate;
	}

	setMinROAS(MinROAS: string | undefined): void {
		this['Min ROAS'] = MinROAS;
	}

	setMaxCpa(MaxCpa: string | undefined): void {
		this['Max Cpa'] = MaxCpa;
	}

	getErrors(): Record<string, string[]> | undefined {
		return this.Errors;
	}
}
