import { generateStorageKey } from '@monorepo/controlled/src/stores/helper';
import { hash } from '@monorepo/tools/src/lib/tools/hash';
import { Logger } from '@monorepo/tools/src/lib/tools/loggers/logger';
import { SESSION_ID } from '../../../../lib/consts/storage';
import { DEBUG_KEY } from '../../../hooks/tools/use-debug';

interface IMFPOptions {
	payload?: unknown;
	traces?: Traces;
}

type Traces = { sessionId?: string; requestId?: string };

class MFPLogger {
	logger: Logger;
	traces: Traces;

	constructor(options?: { traces: Traces }) {
		let sessionId = options?.traces?.sessionId;
		if (!sessionId) {
			sessionId = hash.uuid();
			sessionStorage.setItem(generateStorageKey(SESSION_ID), sessionId);
		}

		this.logger = new Logger({ level: this.isDebug() ? 'log' : undefined });
		this.traces = {
			sessionId,
		};
	}

	log(message = '', mfpOptions?: IMFPOptions) {
		mfpOptions = { ...mfpOptions, traces: { ...this.traces, ...mfpOptions?.traces } };
		this.logger.consoleLogger.log(message, mfpOptions);
	}

	warn(message = '', mfpOptions?: IMFPOptions) {
		mfpOptions = { ...mfpOptions, traces: { ...this.traces, ...mfpOptions?.traces } };
		this.logger.consoleLogger.warn(message, mfpOptions);
	}

	error(message = '', mfpOptions?: IMFPOptions) {
		mfpOptions = { ...mfpOptions, traces: { ...this.traces, ...mfpOptions?.traces } };
		this.logger.consoleLogger.error(message, mfpOptions);
	}

	private isDebug(): boolean {
		return Boolean(new URLSearchParams(location?.search)?.get(DEBUG_KEY));
	}
}

export const mfpLogger = new MFPLogger({
	traces: {
		sessionId: sessionStorage.getItem(generateStorageKey(SESSION_ID)) || '',
	},
});
