import styles from './signup.module.scss';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { observer } from 'mobx-react-lite';
import { Checkbox } from '@monorepo/base/src/components/form/checkbox/checkbox';
import { FC, useEffect, useState } from 'react';
import routes from '../../../modules/routes/index.routes';
import { ThirdPartyAuth } from '../../../modules/components/third-party-auth/third-party-auth';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../../modules/stores';
import { UserModel } from '../../../modules/models/user.model';
import { HttpHeaders } from '@monorepo/tools/src/lib/enums/headers';
import { passwordValidation } from '@monorepo/tools/src/lib/utils/string';
import { FullResponse } from '@monorepo/tools/src/lib/interfaces/url';
import { IUserRes } from '../../../modules/stores/users/user-crud.store';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { LinkWithParams } from '@monorepo/base/src/components/link-with-params/link-with-params';
import { Helmet } from 'react-helmet-async';

export const SignUp: FC = observer(() => {
	const { userStore, authStore } = useStores<IMFPStore>();
	const crudStore = userStore.getCrud();
	const formStore = crudStore.getFormStore();
	const user = crudStore.getData();
	const isLoading = crudStore.getIsLoading();
	const error = crudStore.getHttpError();
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [agreeTnc, setAgreeTnc] = useState<boolean>(false);
	const navigate = useNavigateParams();
	const { lengthError, integerError, letterCaseError, specialCharacterError } = passwordValidation(user?.getPassword() || '');

	const onSignUp = async () => {
		const signupProps = await crudStore.signUp();
		if (!signupProps) {
			return;
		}
		const { body, headers } = signupProps as FullResponse<IUserRes>;
		if (!body?.payload?.user) {
			return;
		}
		const userAuthToken = headers?.find(h => h[0] === HttpHeaders.USER_TOKEN)?.[1];
		if (!userAuthToken) {
			return;
		}
		const _user = new UserModel(body.payload.user);
		userStore.setUser(_user);
		authStore.setToken(userAuthToken);

		if (!_user.hasAccounts()) {
			return navigate(routes.accounts.createAccount());
		}

		if (!_user.isVerified()) {
			return navigate(routes.auth.verify());
		}

		return navigate(routes.general.home());
	};

	useEffect(() => {
		// TODO - change it to isSignedIn?
		userStore.getCrud().reset();
		// userStore.setUser(new UserModel({ id: '0' }));
	}, []);

	return (
		<div className={styles.signupContent}>
			<Helmet>
				<title>Manifesto - Signup</title>
			</Helmet>
			<div className={styles.title}>Sign up</div>
			<div className={styles.subtitle}>Welcome to Manifesto Platform!</div>
			<ThirdPartyAuth />
			<div className={styles.lineSeperator}>
				<span className={styles.lineText}>or Sign up with Email</span>
			</div>
			<div className={styles.formWrapper}>
				<div className={styles.inputWrapper}>
					<div className={styles.formText}>First name</div>
					<Input
						className={styles.formInput}
						placeholder={'Enter your first name'}
						onValue={v => {
							user?.setFirstName(v);
						}}
						value={user?.getFirstName()}
						error={formStore.getErrors().get('firstName')?.getMsg()}
						errorClassName={styles.errorInput}
					/>
				</div>
				<div className={styles.inputWrapper}>
					<div className={styles.formText}>Last name</div>
					<Input
						className={styles.formInput}
						placeholder={'Enter your last name'}
						onValue={v => {
							user?.setLastName(v);
						}}
						value={user?.getLastName()}
						error={formStore.getErrors().get('lastName')?.getMsg()}
						errorClassName={styles.errorInput}
					/>
				</div>
				<div className={styles.inputWrapper}>
					<div className={styles.formText}>Business Email*</div>
					<Input
						className={styles.formInput}
						placeholder={'e.g john@doe.com'}
						onValue={v => user?.setEmail(v)}
						value={user?.getEmail()}
						required={false}
						error={formStore.getErrors().get('email')?.getMsg()}
						errorClassName={styles.errorInput}
					/>
				</div>
				<div className={styles.passwordWrapper}>
					<div className={styles.formText}>Password</div>
					<Input
						className={styles.formInput}
						placeholder={'Enter your Password'}
						value={user?.getPassword()}
						onValue={v => {
							user?.setPassword(v);
						}}
						requiredAsterisk={false}
						type={!showPassword ? 'password' : 'text'}
						after={
							<Icon onClick={() => setShowPassword(!showPassword)} className={styles.passwordVisibilityIcon} isMFP={true}>
								{!showPassword ? 'eye' : 'eye-off'}
							</Icon>
						}
						error={formStore.getErrors().get('password')?.getMsg()}
						errorClassName={styles.errorInput}
					/>
					<div className={styles.passwordErr}>
						<Icon isMFP={true} className={lengthError ? styles.xIcon : styles.checkIcon}>
							{lengthError ? 'x-close' : 'check'}
						</Icon>
						Minimum 8 characters
					</div>
					<div className={styles.passwordErr}>
						<Icon isMFP={true} className={integerError ? styles.xIcon : styles.checkIcon}>
							{integerError ? 'x-close' : 'check'}
						</Icon>
						At least 1 number (0-9)
					</div>
					<div className={styles.passwordErr}>
						<Icon isMFP={true} className={letterCaseError ? styles.xIcon : styles.checkIcon}>
							{letterCaseError ? 'x-close' : 'check'}
						</Icon>
						At least one lower case & one upper case letter (a-Z)
					</div>
					<div className={styles.passwordErr}>
						<Icon isMFP={true} className={specialCharacterError ? styles.xIcon : styles.checkIcon}>
							{specialCharacterError ? 'x-close' : 'check'}
						</Icon>
						At least one special character (e.g. !@#$%^&*)
					</div>
				</div>
				<div className={styles.agreeTncWrapper}>
					<Checkbox checked={agreeTnc} onChange={() => setAgreeTnc(!agreeTnc)} />
					<div className={styles.termsAndConditionText}>
						I agree to Intango’s{' '}
						<a className={styles.termsAndConditionTextLink} href={routes.general.termsAndCondition()} target={'_blank'}>
							Terms & Conditions
						</a>
					</div>
				</div>
				<PrimaryButton
					disabled={lengthError || integerError || letterCaseError || specialCharacterError || !agreeTnc}
					onClick={onSignUp}
					isRotateIcon={isLoading}
					iconSize={'20px'}
					icon={isLoading ? 'loading-01' : ''}>
					Get Started
				</PrimaryButton>
				{error ? <ErrorMsg error={error} isIcon={false} classes={{ error: styles.error }} /> : null}
				<div className={styles.signInWrapper}>
					<div>Already have an account?</div>
					<LinkWithParams to={routes.auth.login()} className={styles.signIn}>
						Sign in
					</LinkWithParams>
				</div>
			</div>
		</div>
	);
});
