import {
	SortingState,
	getSortedRowModel,
	getCoreRowModel,
	useReactTable,
	ColumnDef,
	flexRender,
	getPaginationRowModel,
	Row,
	ColumnSort,
	PaginationState,
	ExpandedState,
	VisibilityState,
	getFilteredRowModel,
	getExpandedRowModel,
	FilterFn,
	Column,
} from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { ElementType, Fragment, memo, useEffect, useRef, useState } from 'react';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { ActionLiner } from './action-liner/action-liner';
import { PageSize } from './page-size/page-size';
import { Pagination } from './pagination/pagination';
import { TableInfo } from './table-info/table-info';
import { TableSkeleton } from './table-skeleton/table-skeleton';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import styles from './table.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { snakeCase } from 'change-case';

export interface IRowProps<T> {
	subRows?: T[];
}

export interface InjectedTableActionsProps<T> {
	rows: Row<T>[];
	sortedRows: Row<T>[];
}

// TODO - change to ITableActions
export interface ITableLiners {
	IndexActions?: ElementType | null;
	SelectedActions?: ElementType | null;
	FiltersActions?: ElementType | null;
	SegmentActions?: ElementType | null;
}

export interface ISegmentActions {
	debugProps?: IDebugProps;
}

export interface ITableProps<T> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	columns: ColumnDef<T, any>[]; // TODO - fix this any https://github.com/TanStack/table/issues/4382
	data: T[];
	// subComponent?: (args0: { row: Row }) => JSX.Element; // TODO
	children?(props: InjectedTableActionsProps<T>): ITableLiners;
	classes?: {
		wrapper?: string;
	};
	isLoading?: boolean;
	renderCell?: (row: Row<T>, options: { className: string }) => JSX.Element[];
	isBarLoader?: boolean;
	isSkeleton?: boolean;
	defaultSortBy?: ColumnSort[];
	columnVisibility?: VisibilityState;
	pagination?: PaginationState;
	isSummary?: boolean;
	globalFilter?: string;
	setGlobalFilter?: (val: string) => void;
	onPageChange?: (args: PaginationState) => void;
	onPageSize?: (args: PaginationState) => void;
	totalRows?: string | number;
	totalFilteredRows?: string | number;
	label?: string;
	debugProps?: IDebugProps;
	emptyState?: JSX.Element;
	isError?: boolean;
	isActionLiner?: boolean;
	isEmptyAndDisabled?: boolean;
	isGlobalFilter?: boolean;
	onExportToCsv?: () => void;
}

export function isSubRow<T>(row: Row<T>) {
	return row.depth > 0;
}

// TODO! - when moving to reporting api do controlled pagination
// TODO - table store should be per table, the table store is for saving in sessionstorage part of the current state
// TODO - global filter should be from reporting api for the summary line
function _Table<T extends IRowProps<T>>(props: ITableProps<T>) {
	const {
		columns,
		data,
		isLoading,
		isBarLoader = true,
		isSkeleton = true,
		children,
		renderCell,
		columnVisibility: initialColumnVisibility,
		pagination: externalPagination,
		defaultSortBy,
		isSummary,
		globalFilter = '',
		setGlobalFilter,
		onPageChange,
		onPageSize,
		totalRows,
		label = 'rows',
		debugProps,
		emptyState,
		classes,
		isError,
		isActionLiner = true,
		isEmptyAndDisabled,
		isGlobalFilter,
		onExportToCsv,
	} = props;

	const didMount = useDidMount();
	const { dataAttrs } = debugProps || {};

	const [sorting, setSorting] = useState<SortingState>(defaultSortBy || []);
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
	const [internalPagination, setInternalPagination] = useState<PaginationState>({
		pageIndex: externalPagination?.pageIndex || 0,
		pageSize: externalPagination?.pageSize || 20,
	});
	const [expanded, setExpanded] = useState<ExpandedState>({ expanded: true });

	const tableWrapperElement = useRef<null | HTMLDivElement>(null);

	const scrollToTableWrapperElement = () => {
		if (tableWrapperElement.current) {
			tableWrapperElement.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const globalFilterFn: FilterFn<T> = (row, columnId, filterValue: string) => {
		const search = filterValue.toLowerCase();
		let value = row.getValue(columnId) as string;

		if (isSubRow(row)) {
			return true;
		}

		if (typeof value === 'number') {
			value = String(value);
		}
		return value?.toLowerCase().includes(search);
	};

	const tableProps = useReactTable<T>({
		data,
		columns,
		initialState: {
			expanded,
			pagination: externalPagination || internalPagination,
			columnVisibility,
			globalFilter: globalFilter || '',
			sorting,
		},
		state: {
			expanded,
			pagination: externalPagination || internalPagination,
			columnVisibility,
			globalFilter: globalFilter || '',
			sorting,
		},
		globalFilterFn,
		onExpandedChange: setExpanded,
		getSubRows: row => row.subRows,
		onPaginationChange: setInternalPagination,
		onGlobalFilterChange: setGlobalFilter,
		onColumnVisibilityChange: setColumnVisibility,
		getFilteredRowModel: getFilteredRowModel(),
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
	});

	const {
		getHeaderGroups,
		getFooterGroups,
		getRowModel,
		getState,
		setPageSize,
		getVisibleFlatColumns,
		getSelectedRowModel,
		toggleAllRowsExpanded,

		getCanPreviousPage,
		getCanNextPage,
		getPageOptions,
		setPageIndex,
		//
	} = tableProps;

	const { rows } = getRowModel();
	const { flatRows: selectedRows } = getSelectedRowModel();
	const { rows: sortedRows } = tableProps.getSortedRowModel();
	const [filterTermPrevValue, setFilterTermPrevValue] = useState<string>('');

	const location = useLocation();

	const onSetPageIndex = (_pageIndex: number, isScrollToTableWrapper = false) => {
		if (!externalPagination) {
			setPageIndex(_pageIndex);
		}

		if (isScrollToTableWrapper) {
			scrollToTableWrapperElement();
		}
		if (onPageChange) {
			onPageChange({ pageIndex: _pageIndex, pageSize: statePageSize });
		}
	};

	const onSetPageSize = (_pageSize: number) => {
		if (!externalPagination) {
			setPageSize(_pageSize);
		}

		if (onPageSize) {
			onPageSize({ pageSize: _pageSize, pageIndex: statePageIndex });
		}
	};

	const onSetGlobalFilter = (_filterTerm: string) => {
		if (_filterTerm !== filterTermPrevValue) {
			setFilterTermPrevValue(_filterTerm);
			onSetPageIndex(0);
			setGlobalFilter?.(_filterTerm);
		}
	};

	useEffect(() => {
		toggleAllRowsExpanded(true);
		return () => {
			// TODO - @MFP need eventually to remove this, we want to save the table page size in the url.
			onSetPageIndex(0);
			onSetGlobalFilter('');
		};
	}, [location]);

	useEffect(() => {
		setColumnVisibility({ ...initialColumnVisibility });
	}, [initialColumnVisibility]);

	const tableState = getState();
	const statePageSize = tableState.pagination.pageSize;
	const statePageIndex = tableState.pagination.pageIndex;
	const stateGlobalFilter = tableState.globalFilter;
	const visibleColumns = getVisibleFlatColumns();

	const isEmptyResults = !isLoading && rows?.length === 0 && data?.length === 0 && didMount;
	const _isSummary = (() => {
		if (stateGlobalFilter) {
			return false;
		}
		if (isLoading) {
			return false;
		}
		if (isEmptyResults) {
			return false;
		}
		return isSummary;
	})();

	let actions: ITableLiners = {};
	if (children) {
		actions = children({ rows: selectedRows, sortedRows });
	}
	const { SelectedActions } = actions;

	const _renderCell = (row: Row<T>) => {
		if (renderCell) {
			return renderCell(row, { className: styles.cell });
		}

		return row.getVisibleCells().map(cell => {
			const columnHeader = cell.column.columnDef.header;
			const columnName = typeof columnHeader === 'string' ? [new DataAttribute('id', `${snakeCase(columnHeader)}_cell`)] : undefined;
			return (
				<td
					{...{
						className: styles.cell,
						key: cell.id,
						style: getCellStyles(cell.column),
					}}
					{...generateDataAttrs(columnName)}>
					{flexRender(cell.column.columnDef.cell, cell.getContext())}
				</td>
			);
		});
	};

	const _renderTable = () => {
		if (isEmptyAndDisabled) {
			return (
				<Fragment>
					<table className={styles.table}>
						<EmptyAndDisabledTable />
					</table>
				</Fragment>
			);
		}

		if (isError) {
			return (
				<Fragment>
					<div className={`${styles.noRows} ${styles.error}`}>Oops! Something went wrong...</div>
					<div className={styles.barLoader}>
						<BarLoader is={isBarLoader || false} />
					</div>
					<table className={styles.table}>
						<TableHead />
						<TableBody />
					</table>
				</Fragment>
			);
		}

		if (isEmptyResults) {
			return (
				<Fragment>
					{!emptyState ? <div className={`${styles.noRows}`}>No Results Found</div> : null}
					<div className={styles.barLoader}>
						<BarLoader is={isBarLoader || false} />
					</div>
					<table className={styles.table}>
						<TableHead />
						{!emptyState ? <TableBody /> : null}
					</table>
					{emptyState ? emptyState : null}
				</Fragment>
			);
		}

		return (
			<Fragment>
				<div className={styles.barLoader}>
					<BarLoader is={isBarLoader || false} />
				</div>
				<table className={styles.table}>
					<TableHead />
					<TableBody />
				</table>
			</Fragment>
		);
	};

	const getCellStyles = (column: Column<T>) => {
		return {
			// The default is 150, but we dont want it because it forces all column to be in min width of 150
			width: column.getSize() === 150 ? undefined : column.getSize(),
			// maxWidth: header.column.columnDef.maxSize,
			// minWidth: header.column.columnDef.minSize,
		};
	};

	const TableHead = () => {
		return (
			<thead className={`${styles.head} ${isEmptyAndDisabled ? styles.disabled : ''}`}>
				{getHeaderGroups().map(headerGroup => (
					<tr key={headerGroup.id} className={styles.row}>
						{headerGroup.headers.map(header => {
							return (
								<th
									{...{
										className: styles.header,
										key: header.id,
										style: getCellStyles(header.column),
									}}>
									{header.isPlaceholder ? null : (
										<div
											className={styles.thContent}
											onClick={header.column.getToggleSortingHandler()}
											{...generateDataAttrs([
												new DataAttribute('id', `${snakeCase(header.column.id)}_table_column`),
											])}>
											{flexRender(header.column.columnDef.header, header.getContext())}
											{(!isEmptyAndDisabled &&
												{
													asc: (
														<Icon className={styles.arrowDirection} isMFP={true} size={'14px'}>
															arrow-up
														</Icon>
													),
													desc: (
														<Icon className={styles.arrowDirection} isMFP={true} size={'14px'}>
															arrow-down
														</Icon>
													),
												}[header.column.getIsSorted() as string]) ??
												null}
										</div>
									)}
								</th>
							);
						})}
					</tr>
				))}
			</thead>
		);
	};

	const TableBody = () => {
		return (
			<tbody className={styles.body}>
				{isSkeleton ? (
					<TableSkeleton
						isLoading={isLoading || isEmptyResults || false}
						columnsAmount={visibleColumns.length}
						rowsAmount={statePageSize}
					/>
				) : null}
				{_isSummary ? TotalLine() : null}

				{!isLoading
					? rows.map(row => {
							// TODO - need to move it to headers files, we want to take the name column and put it in data-id on the tr
							// i map all cells because row.getValue('name') when there is no column name is just crashing everything
							const cell = row.getAllCells().filter(c => c.id.includes('name'))?.[0];
							let _dataAttrs = {};
							if (cell && typeof cell.getValue() === 'string') {
								_dataAttrs = {
									...generateDataAttrs(suffixToDataAttr(`_${snakeCase(cell.getValue() as string)}`, dataAttrs)),
								};
							}
							return (
								<tr
									key={row.id}
									className={`${styles.row} ${row.getIsSelected() ? styles.selectedRow : ''} ${
										isSubRow(row) ? styles.subRow : styles.nsRow
									}`}
									{..._dataAttrs}>
									{_renderCell(row)}
								</tr>
							);
					  })
					: null}
			</tbody>
		);
	};

	const EmptyAndDisabledTable = () => {
		const disabledRows = [];

		while (disabledRows.length < 6) {
			disabledRows.push(
				<tr key={disabledRows.length} className={`${styles.row} ${styles.disabled}`}>
					{getHeaderGroups().map(headerGroup =>
						headerGroup.headers.map((header, i) => <td key={i} className={styles.cell}></td>)
					)}
				</tr>
			);
		}

		return (
			<Fragment>
				<TableHead />
				<tbody className={styles.body}>{disabledRows}</tbody>
			</Fragment>
		);
	};

	const TotalLine = () => {
		return getFooterGroups().map(group => {
			return (
				<tr key={group.id} className={`${_isSummary ? styles.summary : ''} ${styles.row}`}>
					{group.headers.map((header, index) => {
						if (index === (initialColumnVisibility?.['id'] ? 2 : 1)) {
							return (
								<td
									className={`${styles.summaryOverall} ${styles.cell}`}
									{...{
										key: header.id,
										style: getCellStyles(header.column),
									}}>
									<div className={styles.tdContent}>
										<span>Overall total {totalRows || data.length.toLocaleString()} rows</span>
									</div>
									<div className={styles.absoluteBorder}></div>
								</td>
							);
						}

						return (
							<td
								{...{
									className: styles.cell,
									key: header.id,
									style: getCellStyles(header.column),
								}}>
								<div className={styles.tdContent}>{flexRender(header.column.columnDef.footer, header.getContext())}</div>
								{/* <div className={styles.absoluteBorder}></div> */}
							</td>
						);
					})}
				</tr>
			);
		});
	};

	const canNextPage = () => {
		const totalInt = parseInt(`${totalRows}`.replace(/\,/g, ''));
		return getCanNextPage() || totalInt > data.length;
	};

	return (
		<div
			className={`${styles.wrapper} ${SelectedActions ? styles.withSelections : ''} ${classes?.wrapper}`}
			{...generateDataAttrs(dataAttrs)}
			ref={tableWrapperElement}>
			{isActionLiner ? (
				<ActionLiner<T>
					tableProps={tableProps}
					tableActions={actions}
					onSetGlobalFilter={onSetGlobalFilter}
					debugProps={debugProps}
					isGlobalFilter={isGlobalFilter}
					onExportToCsv={onExportToCsv}
				/>
			) : null}
			<div className={styles.tableWrapper}>
				<div className={styles.tableResponsive}>{_renderTable()}</div>
			</div>
			<div className={styles.belowTable} {...generateDataAttrs([new DataAttribute('id', 'below_table_panel')])}>
				<Flex justifyContent={'flex-start'} gap={'20px'}>
					<TableInfo total={totalRows || data.length} label={label} />
					<PageSize pageSize={statePageSize} setPageSize={onSetPageSize} setPage={onSetPageIndex} />
				</Flex>
				{/* TODO - when subrows calc total */}
				<Pagination
					pageSize={statePageSize}
					total={totalRows || data.length}
					canPreviousPage={getCanPreviousPage() && !isLoading}
					canNextPage={canNextPage() && !isLoading}
					// canNextPage={getCanNextPage() || state}
					pageOptions={getPageOptions()}
					gotoPage={onSetPageIndex}
					pageIndex={statePageIndex}
				/>
			</div>
		</div>
	);
}

export const Table = memo(_Table) as typeof _Table;
