import { Fragment } from 'react';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { QuickActionButton } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { IEntityActions, IUseEntityActions, useDefaultActions } from './default.actions';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { BulkCampaignsModel } from '../../models/bulk-campaigns.model';
import { useDownload } from '@monorepo/tools/src/lib/hooks/tools/use-download';
import { CampaignsApi } from '../../apis/campaigns.api';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { id } from '@monorepo/tools/src/lib/types/primitives';

const theme = getTheme();

export function useBulkCampaignActions(props: IUseEntityActions<BulkCampaignsModel> = {}) {
	const { debugProps } = props;
	const { dataAttrs } = debugProps || {};

	const EntityActions = (props: IEntityActions<BulkCampaignsModel>) => {
		const { entity } = props;
		const { buttonClassName } = useDefaultActions({ entities: [entity] });

		const entityId = entity.getId();
		const fileName = entity.getFileName();
		const amountOfErrors = entity.getErrorsAmount();
		if (!entityId) {
			// log error
			return null;
		}

		const onDownloadOriginalFile = (id: id, fileName: string) => {
			useDownload({
				httpStore: new HttpStore<undefined, File | null>({
					httpFunc: () => CampaignsApi.downloadReviewedCampaigns(id),
				}),
				fileName: fileName.replace('.csv', ''),
				extension: 'csv',
			});
		};

		const onDownloadErrorsFile = (id: id, fileName: string) => {
			useDownload({
				httpStore: new HttpStore<undefined, File | null>({
					httpFunc: () => CampaignsApi.downloadFileWithErrors(id),
				}),
				fileName: `${fileName.replace('.csv', '')}-errors`,
				extension: 'csv',
			});
		};

		const onDownloadPublishedFile = (id: id, fileName: string) => {
			useDownload({
				httpStore: new HttpStore<undefined, File | null>({
					httpFunc: () => CampaignsApi.downloadPublishedStatusFile(id),
				}),
				fileName: `${fileName.replace('.csv', '')}-published`,
				extension: 'csv',
			});
		};

		return (
			<Fragment>
				<QuickActionButton>
					<SecondaryText
						icon={'file-05'}
						onClick={() => entityId && fileName && onDownloadOriginalFile(entityId, fileName)}
						className={buttonClassName}
						textColor={theme.titleColor}
						iconColor={theme.titleColor}
						font={IconFonts.Outlined}
						debugProps={{ dataAttrs: suffixToDataAttr('_download_original', dataAttrs) }}>
						Download reviewed
					</SecondaryText>
				</QuickActionButton>
				<QuickActionButton>
					<SecondaryText
						icon={'file-download-02'}
						onClick={() => entityId && fileName && onDownloadPublishedFile(entityId, fileName)}
						textColor={theme.titleColor}
						className={buttonClassName}
						iconColor={theme.titleColor}
						font={IconFonts.Outlined}
						debugProps={{ dataAttrs: suffixToDataAttr('_download_published_file', dataAttrs) }}>
						Download with status
					</SecondaryText>
				</QuickActionButton>
				{amountOfErrors ? (
					<QuickActionButton>
						<SecondaryText
							icon={'file-x-02'}
							onClick={() => entityId && fileName && onDownloadErrorsFile(entityId, fileName)}
							textColor={theme.titleColor}
							className={buttonClassName}
							iconColor={theme.titleColor}
							font={IconFonts.Outlined}
							debugProps={{ dataAttrs: suffixToDataAttr('_download_errors_file', dataAttrs) }}>
							Download errors
						</SecondaryText>
					</QuickActionButton>
				) : null}
			</Fragment>
		);
	};

	return {
		EntityActions,
	};
}
