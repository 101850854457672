import { Outlet } from 'react-router-dom';
import styles from './split-layout.module.scss';
import demoImage from '@monorepo/base/src/assets/demo.png';
import rightPattern from '@monorepo/base/src/assets/line-pattern-right.png';
import leftPattern from '@monorepo/base/src/assets/line-pattern-left.png';
import dayjs from 'dayjs';
import { ManifestoLogo } from '../../logo/logo';

interface ISplitLayout {
	isSticky?: boolean;
}
export const SplitLayout = (props: ISplitLayout) => {
	const { isSticky = true } = props;

	return (
		<div className={`${styles.wrapper} ${isSticky ? styles.sticky : ''}`}>
			<div className={styles.outletWrapper}>
				<ManifestoLogo className={styles.manifestoLogo} width={'173'} height={'36'} />
				<div className={styles.card}>
					<Outlet />
				</div>
				<div className={styles.credits}>@ Intango {dayjs().year()}</div>
			</div>
			<div className={styles.manifestoExample}>
				<img className={styles.rightPattern} src={rightPattern} />
				<img className={styles.leftPattern} src={leftPattern} />
				<img className={styles.demoImage} src={demoImage} />
			</div>
		</div>
	);
};
