import { createColumnHelper } from '@tanstack/react-table';
import { IEntityHeaders, IMergedHeadersReturn } from './index.headers';
import { BulkCampaignsModel, IBulkCampaigns } from '../../models/bulk-campaigns.model';
import dayjs from 'dayjs';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import styles from './headers.module.scss';
import reviewStyles from './review-campaigns.module.scss';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';

const columnHelper = createColumnHelper<IBulkCampaigns>();

export const useBulkCampaignsHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<BulkCampaignsModel> => {
	const { EntityActions } = props;

	return {
		columnVisibility: {
			id: false,
			publishedBy: false,
		},
		columns: [
			columnHelper.accessor('id', {
				header: 'ID',
				cell: ({ row }) => {
					return <div>{row.original.id}</div>;
				},
				enableHiding: true,
			}),
			columnHelper.accessor('fileName', {
				header: 'File',
				cell: ({ row }) => {
					const bulkCampaign = new BulkCampaignsModel(row.original);

					if (!EntityActions) {
						return null;
					}
					return (
						<div className={styles.downloadDropdown}>
							<QuickActionsMenu
								classes={{ actionsMenu: styles.bulkCampaignsActionsMenu }}
								icon={'download-01'}
								iconSize={'14px'}
								forceShow>
								<EntityActions entity={bulkCampaign} />
							</QuickActionsMenu>
							<div className={styles.filename}>
								<span>{row.original.fileName}</span>
							</div>
						</div>
					);
				},
			}),
			columnHelper.accessor('uploadedDate', {
				header: 'Uploaded',
				cell: ({ row }) => {
					return <div>{dayjs(row.original.updatedAt).format('DD/MM/YYYY HH:MM')}</div>;
				},
			}),
			columnHelper.accessor('publishedDate', {
				header: 'Published',
				cell: ({ row }) => {
					if (!row.original.publishedDate) {
						return <div></div>;
					}
					return <div>{dayjs(row.original.publishedDate).format('DD/MM/YYYY HH:MM')}</div>;
				},
			}),
			columnHelper.accessor('createdAmount', {
				header: 'New',
				cell: ({ row }) => row.original.createdAmount || 0,
			}),
			columnHelper.accessor('updatedAmount', {
				header: 'Updated',
				cell: ({ row }) => row.original.updatedAmount || 0,
			}),
			columnHelper.accessor('totalCampaigns', {
				header: 'Total',
			}),
			columnHelper.accessor('publishedBy', {
				header: 'Published By',
			}),
			columnHelper.accessor('strategyType', {
				header: 'Type',
				cell: ({ row }) => {
					return <div>{row.original.strategyType as unknown as string}</div>; // causes cause it's an enum
				},
			}),
			columnHelper.accessor('errors', {
				header: 'Errors',
				cell: ({ row }) => {
					const hasErrors = row.original?.errorsAmount || 0;
					return (
						<div className={reviewStyles.errorColumnWrapper}>
							<Icon
								font={IconFonts.Filled}
								className={`${reviewStyles.reviewStatus} ${hasErrors ? reviewStyles.error : reviewStyles.success}`}
								isMFP={true}>
								{hasErrors > 0 ? 'alert-circle' : 'check-circle'}
							</Icon>
							<span className={reviewStyles.errorsCount}>{hasErrors ? row.original?.errorsAmount : ''}</span>
						</div>
					);
				},
			}),
		],
	};
};
