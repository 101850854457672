import { mapToObject } from '@monorepo/tools/src/lib/utils/map';
import { makeObservable, observable, action } from 'mobx';
import { ChannelsEnums, ChannelModel } from '../channel.model';

export interface IManualStrategyModel {
	channels?: Map<ChannelsEnums, ChannelModel>;
}

export interface IManualStrategyForm {
	channels?: Record<ChannelsEnums, ChannelModel>;
}

export class ManualStrategyModel implements IManualStrategyModel {
	channels?: Map<ChannelsEnums, ChannelModel>;

	constructor(strategy?: IManualStrategyModel) {
		this.channels = strategy?.channels || new Map();

		makeObservable(this, {
			channels: observable,
			addChannel: action,
			removeChannel: action,
			hasChannel: action,
		});
	}

	public getFormData(): IManualStrategyForm {
		return {
			channels: this.channels ? mapToObject<ChannelsEnums, ChannelModel>(this.channels) : {},
		};
	}

	public getChannels() {
		return this.channels;
	}

	public getChannel(channelType: ChannelsEnums) {
		return this.channels?.get(channelType);
	}

	public addChannel(channelType: ChannelsEnums, channel: ChannelModel) {
		this.channels?.set(channelType, channel);
	}

	public hasChannel(channelType: ChannelsEnums) {
		return this.channels?.has(channelType);
	}

	public removeChannel(channelType: ChannelsEnums) {
		return this.channels?.delete(channelType);
	}
}
