import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { makeAutoObservable, observable } from 'mobx';
import { filterPrototypesByProp } from '../../components/filters';
import { IPerformanceOptions } from '../../hooks/apis/use-reports-list';
import { PerformancePayload } from '../../models/performance.model';
import { SourcesApi } from '../../apis/sources.api';

export enum SourcesFiltersStoreKeys {
	Campaign = 'campaignssources',
}

export class SourcesStore {
	reports = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: SourcesApi.reports,
		model: PerformancePayload,
	});
	metrics = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: SourcesApi.metrics,
		model: PerformancePayload,
	});

	settingsStore: SettingsStore;

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this, {
			// list: observable,
			metrics: observable,
		});
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		Object.values(SourcesFiltersStoreKeys).map((key: SourcesFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const advertiserFilterByKey = new FilterStore({
				key,
				currentFilters: undefined,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, advertiserFilterByKey);
		});
	}

	getReportsStore() {
		return this.reports;
	}

	getMetricsStore() {
		return this.metrics;
	}
}
