import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { IMFPStore } from '../../../../modules/stores';
import {
	Entities,
	IPerformanceOptionsFilterType,
	PerformancesColumns,
	useReportsList,
} from '../../../../modules/hooks/apis/use-reports-list';
import { useMetricsGet } from '../../../../modules/hooks/tools/use-metrics-get';
import { availablePerformanceToMetrics } from '../../../../modules/models/performance.model';
import { useMetrics } from '../../../../modules/hooks/tools/use-metrics';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useCampaignHeaders } from '../../../../modules/hooks/theaders/campaigns.headers';
import { useCampaignActions } from '../../../../modules/hooks/actions/campaign.actions';
import { CampaignModel } from '../../../../modules/models/campaign.model';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useAccountActions } from '../../../../modules/hooks/actions/account.actions';
import { EntityActionsMenu } from '../../../../modules/components/entity-actions-menu/entity-actions-menu';
import { ManifestoChart } from '../../../../modules/components/chart/manifesto-chart';
import styles from './../../../../styles/pages.module.scss';
import { Helmet } from 'react-helmet-async';
import { PaginationState } from '@tanstack/react-table';
import { isFetchFromBackend } from '../../../../modules/helpers/reports.helpers';

export const AccountCampaigns = observer(() => {
	const { campaignStore, accountStore } = useStores<IMFPStore>();
	const { accountId } = useParams();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const metricsStore = campaignStore.getMetricsStore();
	const reportsStore = campaignStore.getReportsStore();
	const reportData = reportsStore.getData();
	const currentSegments = campaignStore.getSegments();
	const accountName = accountStore.getCrud().getData().getName();

	if (!accountId) {
		return null;
	}

	const { reportsError, fetchReports } = useReportsList<CampaignModel>(
		reportsStore,
		{
			entity: Entities.Campagins,
			filters: [
				{
					column: PerformancesColumns.AccountId,
					filterType: IPerformanceOptionsFilterType.Include,
					value: [accountId],
				},
			],
			groupBys: [PerformancesColumns.CampaignId],
		},
		undefined,
		currentSegments
	);

	const { metricsError, fetchMetrics } = useMetricsGet(metricsStore, {
		entity: Entities.Chart,
		groupBys: [PerformancesColumns.Date],
		filters: [
			{
				column: PerformancesColumns.AccountId,
				filterType: IPerformanceOptionsFilterType.Include,
				value: [accountId],
			},
		],
	});

	const { columns } = useCampaignHeaders({ summary: reportData?.getSummary() });
	const { SelectedActions, FiltersActions, SegmentActions } = useCampaignActions({ currentSegments });
	const { EntityActions } = useAccountActions();
	const account = accountStore.getCrud().getData();

	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page unstyled={true} className={styles.article}>
			<Helmet>
				<title>{accountName ? accountName : 'Account'} - Campaigns</title>
			</Helmet>
			<Pagebar classes={{ pagebar: styles.padding }}>
				<PagebarTitle>Campaigns</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
					<EntityActionsMenu title={'Account Actions'}>
						<EntityActions entity={account} />
					</EntityActionsMenu>
				</PagebarActions>
			</Pagebar>
			<ManifestoChart
				isLoading={metricsStore.getIsLoading()}
				metrics={metrics}
				labels={xLabels}
				legendOptions={Object.keys(availablePerformanceToMetrics)}
				isError={Boolean(metricsError)}
			/>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				columns={columns}
				classes={{ wrapper: styles.padding }}
				data={reportData?.getData() || []}
				isLoading={reportsStore.getIsLoading()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'campaigns_account_table')] }}
				isWithPerformance={true}
				isLoadingPerformance={reportsStore.getIsLoading()}
				defaultSortBy={[{ id: 'revenue', desc: true }]}
				fetchReports={fetchReports}
				fetchMetrics={fetchMetrics}
				onPageChange={(state: PaginationState) => {
					if (isFetchFromBackend(reportData, state) && !reportsStore.getIsLoading()) {
						fetchReports({ appendData: true, resetOffset: false });
					}
				}}
				isError={Boolean(reportsError)}>
				{({ sortedRows }) => {
					campaignStore.setSortedRows(sortedRows);

					return {
						FiltersActions,
						SelectedActions,
						SegmentActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
