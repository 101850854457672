import { Menu } from '@monorepo/base/src/components/menu/menu';
import { SecondaryButton, SecondaryText, PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { useEffect, useRef, useState } from 'react';
import styles from './calendar.module.scss';
import { CalendarIcon } from '../../../assets/calendar.icon';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import dayjs from 'dayjs';
import Calendar from '../internal-components/Calendar';
import { FormError } from '../../form/form-error/form-error';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

interface ICalendar {
	date?: Date;
	setOpen: (isOpen: boolean) => void;
	isOpen: boolean;
	onChange: (ranges: Date) => void;
	onApply: () => void;
	fromDate?: string;
	toDate?: string;
	minDate?: Date;
	maxDate?: Date;
	label?: string;
	placeholder?: string;
	error?: string;
	disabled?: boolean;
	debugProps?: IDebugProps;
}

export const DaterCalendar = (props: ICalendar) => {
	const { placeholder, setOpen, isOpen, onChange, onApply, date, label, error, disabled, debugProps, ...rest } = props;
	const [errorMsg, setErrorMsg] = useState<string | undefined>(error);

	useEffect(() => {
		setErrorMsg(error);
	}, [error]);

	const _onChange = (date: Date) => {
		if (onChange) {
			onChange(date);
		}
	};

	const daterRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(daterRef, () => {
		setOpen(false);
	});

	const dateFormatted = date ? dayjs(date).format('MMM D, YYYY') : null;

	return (
		<div ref={daterRef} style={{ width: '100%' }}>
			{label ? <div className={styles.label}>{label}</div> : null}
			<SecondaryButton
				disabled={disabled}
				className={`${styles.btn} ${errorMsg ? styles.error : ''}`}
				onClick={() => setOpen(!isOpen)}
				debugProps={debugProps}>
				<CalendarIcon /> &nbsp;
				{dateFormatted ? `${dateFormatted}` : `${placeholder}`}
			</SecondaryButton>
			<Menu isOpen={isOpen} alignToRight={true}>
				<Calendar date={date} onChange={_onChange} className={'calendarWrapper'} {...rest} />
				<div className={styles.actions}>
					{/* TODO - dater actions component */}
					<SecondaryText
						className={styles.cancelBtn}
						onClick={() => setOpen(false)}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'calendar_cancel')] }}>
						Cancel
					</SecondaryText>
					<SpacerX />
					<PrimaryButton
						className={styles.applyBtn}
						onClick={onApply}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'calendar_apply')] }}>
						Apply
					</PrimaryButton>
				</div>
			</Menu>
			<FormError msg={errorMsg} />
		</div>
	);
};
