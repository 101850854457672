import { FullResponse } from './../../../../../tools/src/lib/interfaces/url';
import { UsersApi } from './../../apis/users.api';
import { makePersistable } from 'mobx-persist-store';
import { UserModel } from '../../models/user.model';
import { makeAutoObservable, action } from 'mobx';
import { generateStorageKey } from '@monorepo/controlled/src/stores/helper';
import { UserCrudStore } from './user-crud.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';

export class UserStore {
	user?: UserModel;
	crud = new UserCrudStore();
	verifyEmailHttpStore = new HttpStore<undefined, FullResponse<void>>({
		httpFunc: UsersApi.sendVerifyEmail,
	});

	constructor() {
		makeAutoObservable(this, {
			removeUser: action,
		});
		// TODO - save it in somewhere else
		makePersistable(this, {
			name: generateStorageKey('userstore'),
			properties: ['user'],
			storage: {
				setItem: (key, data) => window.localStorage.setItem(key, data),
				removeItem: key => window.localStorage.removeItem(key),
				getItem: key => {
					const data = JSON.parse(window.localStorage.getItem(key) || '{}');
					return {
						...data,
						user: data.user ? new UserModel(data.user) : undefined,
					};
				},
			},
		});
	}

	setUser(user: UserModel) {
		this.user = user;
	}

	getUser(): UserModel | undefined {
		return this.user;
	}

	removeUser() {
		this.user = undefined;
	}

	getCrud() {
		return this.crud;
	}

	getVerifyEmailHttpStore() {
		return this.verifyEmailHttpStore;
	}
}
