import { url } from '@monorepo/tools/src/lib/types/url';
import { email } from '@monorepo/tools/src/lib/types/email';
import { makeAutoObservable } from 'mobx';
import { UserStatusEnum } from '../enums/user.enum';
import { capitalCase } from 'change-case';
import { permissionsDomain } from '../hooks/tools/use-guard';
import { PermissionActions } from '../enums/permissions';

export interface IUser {
	id?: string;
	firstName?: string;
	lastName?: string;
	company?: string;
	picture?: string;
	email?: string;
	isInternal?: boolean;
	password?: string;
	code?: string; // 2fa code
	status?: UserStatusEnum;
	rememberMe?: boolean;
	domainPermissions?: Record<permissionsDomain, Set<PermissionActions>>;
}

export interface IUserCreateForm {
	id?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	password?: string;
	company?: string;
}

export interface IUserEditForm {
	id?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	password?: string;
	company?: string;
}

export class UserModel implements IUser {
	id?: string;
	firstName?: string;
	lastName?: string;
	company?: string;
	picture?: url;
	email?: email;
	isInternal?: boolean;
	password?: string;
	code?: string;
	status?: UserStatusEnum;
	rememberMe?: boolean;
	domainPermissions?: Record<permissionsDomain, Set<PermissionActions>>;
	permissionsSet: Set<PermissionActions>;

	constructor(user?: IUser) {
		this.id = user?.id;
		this.firstName = capitalCase(user?.firstName || '');
		this.lastName = capitalCase(user?.lastName || '');
		this.company = user?.company;
		this.picture = user?.picture;
		this.email = user?.email;
		this.isInternal = Boolean(user?.isInternal);
		this.status = user?.status;
		this.rememberMe = user?.rememberMe;
		this.domainPermissions = {};
		this.permissionsSet = new Set<PermissionActions>([PermissionActions.Public]);

		if (user === Object(user)) {
			this.permissionsSet.add(PermissionActions.UsersOnline);
		}

		if (user?.domainPermissions && user.domainPermissions === Object(user.domainPermissions)) {
			const permissionsEntries = Object.entries(user.domainPermissions);
			for (const [domain, permissionsArr] of permissionsEntries) {
				const permissionsSet = new Set(permissionsArr);
				this.domainPermissions[domain as permissionsDomain] = permissionsSet;
				this.permissionsSet = new Set([...this.permissionsSet, ...permissionsSet]);
			}
		}

		makeAutoObservable(this);
	}

	getId(): string | undefined {
		return this.id;
	}

	setEmail(email: email | undefined): void {
		this.email = email;
	}

	getEmail(): email | undefined {
		return this.email;
	}

	getFullName(): string | undefined {
		if (!this.lastName) {
			return this.firstName;
		} else if (!this.firstName) {
			return this.lastName;
		}
		return `${this.firstName} ${this.lastName}`;
	}

	getCompany(): string | undefined {
		return this.company;
	}

	setCompany(company: string): void {
		this.company = company;
	}

	getPassword(): string | undefined {
		return this.password;
	}

	setPassword(password: string): void {
		this.password = password;
	}

	setFirstName(firstName: string): void {
		this.firstName = firstName;
	}

	getFirstName(): string | undefined {
		return this.firstName;
	}

	setLastName(lastName: string): void {
		this.lastName = lastName;
	}

	getLastName(): string | undefined {
		return this.lastName;
	}

	setStatus(status: UserStatusEnum): void {
		this.status = status;
	}

	getStatus(): UserStatusEnum | undefined {
		return this.status;
	}

	getPicture(): url | undefined {
		return this.picture;
	}

	hasAccounts(): boolean {
		return !this.email?.includes('+1');
	}

	isVerified(): boolean {
		return this.status === UserStatusEnum.ENABLED;
	}

	setRememberMe(rememberMe: boolean) {
		this.rememberMe = rememberMe;
	}

	getRememberMe(): boolean | undefined {
		return this.rememberMe;
	}

	setDomainPermissions(permissions: Record<permissionsDomain, Set<PermissionActions>>) {
		this.domainPermissions = permissions;
	}

	getDomainPermissions() {
		return this.domainPermissions;
	}

	setCode(code: string) {
		this.code = code;
	}

	getCode() {
		return this.code;
	}
}
