import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useAccountActions } from '../../../modules/hooks/actions/account.actions';
import { Outlet } from 'react-router-dom';
import { useAccountHeaders } from '../../../modules/hooks/theaders/accounts.headers';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { IMFPStore } from '../../../modules/stores';
import { Entities, PerformancesColumns, useReportsList } from '../../../modules/hooks/apis/use-reports-list';
import styles from './../../../styles/pages.module.scss';
// import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import routes from '../../../modules/routes/index.routes';
import { useMetricsGet } from '../../../modules/hooks/tools/use-metrics-get';
import { useMetrics } from '../../../modules/hooks/tools/use-metrics';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { availablePerformanceToMetrics } from '../../../modules/models/performance.model';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ManifestoChart } from '../../../modules/components/chart/manifesto-chart';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { Helmet } from 'react-helmet-async';
import { PaginationState } from '@tanstack/react-table';
import { isFetchFromBackend } from '../../../modules/helpers/reports.helpers';

export const Accounts: FC = observer(() => {
	const { accountStore, userStore } = useStores<IMFPStore>();
	const metricsStore = accountStore.getMetricsStore();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const reportsStore = accountStore.getReportsStore();
	const reportData = reportsStore.getData();
	const navigate = useNavigateParams();

	useEffect(() => {
		if (!userStore?.getUser()?.isInternal) {
			navigate(`${routes.general.home()}`);
		}
	}, [userStore]);

	const { reportsError, fetchReports } = useReportsList(
		reportsStore,
		{
			entity: Entities.Accounts,
			groupBys: [PerformancesColumns.AccountId],
		},
		undefined,
		accountStore.getSegments()
	);

	const { metricsError, fetchMetrics } = useMetricsGet(metricsStore, {
		entity: Entities.Chart,
		groupBys: [PerformancesColumns.Date],
	});

	const { SelectedActions, EntityActions, FiltersActions, SegmentActions } = useAccountActions({
		currentSegments: accountStore.getSegments(),
	});
	const { columns } = useAccountHeaders({ EntityActions, summary: reportData?.getSummary() });

	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page unstyled={true} className={styles.article}>
			<Helmet>
				<title>Manifesto - Accounts</title>
			</Helmet>
			<Pagebar classes={{ pagebar: styles.padding }}>
				<PagebarTitle>Accounts</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
					{/* <PrimaryLink
						icon={'plus'}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'create_account')] }}
						to={`${routes.accounts.create()}`}
						width={'148px'}>
						New Account
					</PrimaryLink> */}
				</PagebarActions>
			</Pagebar>
			<ManifestoChart
				isLoading={metricsStore.getIsLoading()}
				metrics={metrics}
				labels={xLabels}
				legendOptions={Object.keys(availablePerformanceToMetrics)}
				isError={Boolean(metricsError)}
			/>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				totalRows={reportData?.getTotalRows() ? reportData?.getTotalRows()?.toLocaleString() : undefined}
				totalFilteredRows={reportData?.getTotalFilteredRows() ? reportData?.getTotalFilteredRows()?.toLocaleString() : undefined}
				classes={{ wrapper: styles.padding }}
				columns={columns}
				data={reportData?.getData() || []}
				isLoading={reportsStore.getIsLoading()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'accounts_table')] }}
				isWithPerformance={true}
				isLoadingPerformance={reportsStore.getIsLoading()}
				defaultSortBy={[{ id: 'revenue', desc: true }]}
				fetchReports={fetchReports}
				fetchMetrics={fetchMetrics}
				onPageChange={(state: PaginationState) => {
					if (isFetchFromBackend(reportData, state) && !reportsStore.getIsLoading()) {
						fetchReports({ appendData: true, resetOffset: false });
					}
				}}
				isError={Boolean(reportsError)}>
				{({ sortedRows }) => {
					accountStore.setSortedRows(sortedRows);
					return {
						SelectedActions,
						FiltersActions,
						SegmentActions,
					};
				}}
			</ControlledTable>
			<Outlet />
		</Page>
	);
});
