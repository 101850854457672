import { Chart, IChart } from '@monorepo/base/src/components/chart/chart';
import styles from './manifesto-chart.module.scss';
import { generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';

export const ManifestoChart = (props: IChart) => {
	const { isError, debugProps } = props;
	const { dataAttrs } = debugProps || {};
	return (
		<div className={styles.chartWrapper} {...generateDataAttrs(dataAttrs)}>
			<Chart {...props} />
			{isError && (
				<div className={styles.errorWrapper}>
					<div className={styles.error}>Oops! Something went wrong...</div>
				</div>
			)}
		</div>
	);
};
