import { BaseAndCrudRoutes } from '@monorepo/tools/src/lib/routes/base.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export class AccountsRoutes extends BaseAndCrudRoutes {
	constructor() {
		super('accounts');
	}

	overview(id: id) {
		return `/${this.baseRoute}/${id}/overview`;
	}

	advertisers(id: id) {
		return `/${this.baseRoute}/${id}/advertisers`;
	}

	campaigns(id: id) {
		return `/${this.baseRoute}/${id}/campaigns`;
	}

	// landing pages
	pages(id: id) {
		return `/${this.baseRoute}/${id}/pages`;
	}

	activityLog(id: id) {
		return `/${this.baseRoute}/${id}/activity-log`;
	}

	settings(id: id) {
		return `/${this.baseRoute}/${id}/settings`;
	}

	channels(id: id) {
		return `/${this.baseRoute}/${id}/channels`;
	}

	sources(id: id) {
		return `/${this.baseRoute}/${id}/sources`;
	}

	createAdvertiser(id: id) {
		return `/${this.baseRoute}/${id}/advertisers/create`;
	}
	editAdvertiser(accountId: id, advertiserId: id) {
		return `/${this.baseRoute}/${accountId}/advertisers/${advertiserId}/edit`;
	}
	createAccount() {
		return '/create-account';
	}
}
