import { BulkCampaignsApi } from '../../../apis/bulk-campaigns.api';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { ErrorPayloadType, HttpError, IHttpError } from '../../../models/http-error.model';
import { BulkCampaignsModel, IBulkCampaignsCreateForm, IBulkCampaignsEditForm } from '../../../models/bulk-campaigns.model';

export class BulkCampaignsCrudStore extends BaseCrudStore<BulkCampaignsModel, IBulkCampaignsCreateForm, IBulkCampaignsEditForm, HttpError> {
	constructor() {
		super({
			apiLayer: BulkCampaignsApi,
			model: BulkCampaignsModel,
			errorModel: HttpError,
		});
	}

	/**
	 * Must call isValidCampaign before calling this function
	 * @returns
	 */
	public getCreateFormData(): IBulkCampaignsCreateForm {
		const bulkCampaigns = this.getData();

		return {
			id: bulkCampaigns.getId(),
			fileName: bulkCampaigns.getFileName(),
		};
	}

	/**
	 * Must call isValidCampaign before calling this function
	 * @returns
	 */
	public getEditFormData(): IBulkCampaignsEditForm {
		const bulkCampaigns = this.getData();

		return {
			id: bulkCampaigns.getId(),
			fileName: bulkCampaigns.getFileName(),
		};
	}

	public isValid(): boolean {
		this.formStore.reset();
		const bulkCampaigns = this.getData();

		const bulkCampaignsFileName = bulkCampaigns.getFileName();
		if (!bulkCampaignsFileName) {
			this.formStore.addError(new FormError('fileName', 'Please provide a bulk campaign file name'));
		}

		return this.formStore.getIsValid();
	}

	public onError(error: IHttpError) {
		if (error.errorPayload?.type === ErrorPayloadType.Properties) {
			Object.keys(error.errorPayload?.data).forEach(key => {
				this.formStore.addError(new FormError(key, error.errorPayload?.data[key] as string));
			});
		}
	}
}
