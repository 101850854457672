import { ReactNode } from 'react';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './card.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';

export interface ICard {
	children: JSX.Element | JSX.Element[] | ReactNode;
	title?: string;
	icon?: string;
	id?: string;
	className?: string;
	border?: boolean;
	full?: boolean;
	debugProps?: IDebugProps; // TODO - not working because of babel-plugin-react-generate-property
}

export const Card = (props: ICard) => {
	const { children, icon, title, id = '', className = '', border = true, full = false, debugProps } = props;
	const { dataAttrs } = debugProps || {};

	return (
		<div
			id={id}
			className={`${className} ${styles.wrapper} ${border ? styles.border : ''} ${full ? styles.full : ''}`}
			{...generateDataAttrs(dataAttrs)}>
			<h2 className={styles.title}>
				{icon ? (
					<Icon isMFP={true} className={styles.titleIcon}>
						{icon}
					</Icon>
				) : null}
				{title}
			</h2>
			{children}
		</div>
	);
};
