/* eslint-disable @typescript-eslint/ban-ts-comment */
import { capitalCase } from 'change-case';
import { FilterModel, IFilterComponentProps, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { FilterOptions } from '@monorepo/base/src/components/filters/filter-options/filter-options';
import { observer } from 'mobx-react';

interface IEnumFilterProto {
	cellKey: string;
	columnName: string;
	enumOptions: string[];
	deletable?: boolean;
	entity?: string;
}

interface IEnumFilterComponentProps extends IFilterComponentProps {
	cellKey: string;
	columnName: string;
	enumOptions: string[];
	deletable?: boolean;
	entity?: string;
}

const EnumsFilter = (props: IEnumFilterComponentProps) => {
	const { filter, onCancel, onApply, addFilter, editFilter, columnName, enumOptions, cellKey, deletable = false, entity } = props;
	const protoObj = EnumFilterPrototype({ columnName, enumOptions, deletable, cellKey, entity });

	const onAddEnumFilter = (_value: string | string[]) => {
		const enumFilter = new FilterModel({
			label: `${columnName} is ${typeof _value === 'string' ? _value : _value.join(', ')}`,
			value: _value,
			prototype: protoObj,
		});

		if (filter?.index && editFilter) {
			editFilter(filter.index, enumFilter);
		} else {
			addFilter(enumFilter);
		}

		onApply();
	};

	return (
		<FilterOptions
			placeholder={`Search ${columnName}`}
			value={
				typeof filter?.value === 'string'
					? capitalCase(filter?.value)
					: Array.isArray(filter?.value)
					? filter?.value?.join(', ')
					: ''
			}
			onFilterOption={onAddEnumFilter}
			onCancel={onCancel}
			options={enumOptions.map(_status => capitalCase(_status))}
			isSelectAll={true}
		/>
	);
};

export const EnumFilterPrototype = (props: IEnumFilterProto): IFilterProto => {
	const { columnName, enumOptions, deletable, cellKey, entity } = props;

	return {
		MenuComponent: columnName,
		FilterComponent: observer((props: IFilterComponentProps) =>
			EnumsFilter({ ...props, columnName, enumOptions, cellKey, deletable, entity })
		),
		prop: `${entity}${cellKey}`,
		multi: false,
		deletable,
		filterFunc<T>(models: Array<T>, filter: FilterModel): Array<T> {
			const { value } = filter;
			return models.filter(model => {
				if (typeof value === 'number') {
					return;
				}

				if (typeof value === 'string') {
					//@ts-ignore
					return model[cellKey].toLowerCase() === value.toLowerCase();
				}

				let isEqual = false;
				value.forEach(val => {
					//@ts-ignore
					if (model[cellKey] !== null) {
						//@ts-ignore
						if (typeof model[cellKey] === 'string' && model[cellKey].toLowerCase() === val.toLowerCase()) {
							isEqual = true;
							//@ts-ignore
						} else if (Array.isArray(model[cellKey]) && model[cellKey].includes(val)) {
							isEqual = true;
						}
					}
				});
				return isEqual;
			});
		},
	};
};
