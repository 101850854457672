import { observable, action, makeObservable } from 'mobx';
import { IQueryParams } from '@monorepo/tools/src/lib/interfaces/url';
import { makePersistable } from 'mobx-persist-store';
import { FilterModel, IFilter, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { generateStorageKey } from './helper';

export type FiltersMap = Map<number, FilterModel>;

interface IFilterStore {
	key: string;
	currentFilters?: FiltersMap;
	filterPrototypesByProp: Record<string, IFilterProto>;
}

export class FilterStore {
	key: string;
	currentFilters: FiltersMap;
	filterPrototypesByProp: Record<string, IFilterProto>;

	constructor(options: IFilterStore) {
		const { key, currentFilters, filterPrototypesByProp } = options;
		this.key = key;
		this.currentFilters = currentFilters || new Map();
		this.filterPrototypesByProp = filterPrototypesByProp || {};

		makeObservable(this, {
			currentFilters: observable,
			editFilter: action,
			addFilter: action,
			setCurrentFilters: action,
			clearFilter: action,
			clearAll: action,
		});

		const localStorageKey = generateStorageKey(this.key + 'filterstore');

		let thisFilters: FiltersMap | undefined = undefined;
		let isInitial = false;
		if (!window.localStorage.getItem(localStorageKey) && this.currentFilters) {
			isInitial = true;
			thisFilters = new Map([...this.currentFilters]);
		}

		makePersistable(this, {
			name: localStorageKey,
			stringify: false,
			properties: ['currentFilters'],
			storage: {
				// Map to string
				setItem: (key, data) => {
					const toSave = data || {};
					if (!window.localStorage.getItem(localStorageKey)) {
						if (thisFilters && thisFilters.size > 0) {
							toSave.currentFilters = thisFilters;
						}
					}

					window.localStorage.setItem(
						key,
						JSON.stringify({
							currentFilters: this.readyFiltersToLocalStorage(toSave?.currentFilters),
						})
					);
				},
				removeItem: key => window.localStorage.removeItem(key),
				// String to map
				getItem: key => {
					const data = JSON.parse(window.localStorage.getItem(key) || '{}');
					if (!data?.currentFilters) {
						return {
							currentFilters: new Map(),
						};
					}
					const filtersFromLocalStorage: FiltersMap = this.addPrototypesToFilters(data.currentFilters || []);

					return {
						...data,
						currentFilters: filtersFromLocalStorage,
					};
				},
			},
		}).then(() => {
			if (isInitial && thisFilters) {
				thisFilters.forEach(filter => filter.setPrototype(this.filterPrototypesByProp[filter.prototype.prop]));
				this.setCurrentFilters(thisFilters);
			}
		});
	}

	addPrototypesToFilters(filtersArray: Array<IFilter>): FiltersMap {
		const filtersFromLocalStorage: FiltersMap = new Map();
		(filtersArray || []).forEach((currentFilter: IFilter) => {
			const { index } = currentFilter;
			const newFilterModel = new FilterModel({ ...currentFilter });
			if (newFilterModel?.prototype?.prop) {
				newFilterModel.setPrototype(this.filterPrototypesByProp[currentFilter.prototype.prop]);
				if (index) {
					filtersFromLocalStorage.set(index, newFilterModel);
				}
			}
		});

		return filtersFromLocalStorage;
	}

	readyFiltersToLocalStorage(value: FiltersMap) {
		const currentFilters = new Map([...value]);

		currentFilters.forEach(filter => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			filter.prototype = {
				prop: filter.prototype.prop,
			};
		});

		return Array.from(currentFilters.values());
	}

	addFilter(filter: FilterModel) {
		if (!filter) {
			throw new Error('No filter added to addFilter function');
		}

		// this.currentFilters.set(filter.label, filter);
		filter.index = this.currentFilters.size + 1;
		this.currentFilters.set(filter.index, filter);
	}

	// Only for non multi currentFilters
	editFilter(index: number, filter: FilterModel) {
		if (!filter || !index) {
			throw new Error('No filter added to editFilter function');
		}

		const filterToEdit = this.currentFilters.get(index);
		if (!filterToEdit) {
			console.error('No filter found in editFilter function');
			return null;
		}

		filter.index = index;
		this.currentFilters.set(index, filter);
	}

	clearFilter(index: number) {
		this.currentFilters.delete(index);
	}

	clearAll() {
		const undeletableFilters = new Map();
		this.currentFilters.forEach((filter, index) => {
			if (!filter.prototype.deletable) {
				undeletableFilters.set(index, filter);
			}
		});

		this.currentFilters.clear();
		this.setCurrentFilters(undeletableFilters);
	}

	setCurrentFilters(currentFilters: FiltersMap) {
		this.currentFilters = currentFilters;
	}

	getCurrentFilters(): FiltersMap {
		return this.currentFilters;
	}

	getFiltersAsQueryParams(): IQueryParams {
		return Array.from(this.currentFilters).reduce((acc: IQueryParams, [label, currentFilter]) => {
			acc[label] = currentFilter.value;
			return acc;
		}, {});
	}
}
