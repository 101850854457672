import { Soon } from '@monorepo/base/src/components/soon/soon';
import { Accounts } from '../../pages/accounts/index/accounts.page';
import { AccountView } from '../../pages/accounts/view/account-view.page';
import { AccountAdvertisers } from '../../pages/accounts/view/advertisers/advertisers.account';
import { FullLayout } from '../components/layouts/full-layout/full-layout';
import { PageLayout } from '../components/layouts/page-layout/page-layout';
import routes from '../routes/index.routes';
import { CreateAdvertiser } from '../../pages/advertisers/create/create-advertiser.page';
import { EditAccount } from '../../pages/accounts/edit/edit-account.page';
import { AccountCampaigns } from '../../pages/accounts/view/campaigns/campaigns.account';
import { AccountChannels } from '../../pages/accounts/view/channels/channels.account';
import { EditAdvertiser } from '../../pages/advertisers/edit/edit-advertiser.page';
import { GuardRoute } from '../components/guard/guard-route';
import { PermissionActions } from '../enums/permissions';
import { validateAction } from '../hooks/tools/use-guard';

export const AccountsRouter = [
	{
		element: <FullLayout />,
		children: [
			{
				path: routes.accounts.create(),
				element: <Soon />,
			},
		],
	},
	{
		element: <PageLayout />,
		children: [
			{
				path: routes.accounts.index(),
				element: <Accounts />,
			},
			{
				path: routes.accounts.edit(':accountId'),
				element: (
					<GuardRoute validate={validateAction([PermissionActions.AccountWrite])}>
						<EditAccount />
					</GuardRoute>
				),
			},
			{
				element: <AccountView />,
				children: [
					{
						path: routes.accounts.view(':accountId'),
						element: (
							<GuardRoute validate={validateAction([PermissionActions.AccountRead])}>
								<Soon />
							</GuardRoute>
						),
					},
					{
						path: routes.accounts.advertisers(':accountId'),
						element: (
							<GuardRoute validate={validateAction([PermissionActions.AdvertiserRead])}>
								<AccountAdvertisers />
							</GuardRoute>
						),
						children: [
							{
								path: routes.accounts.createAdvertiser(':accountId'),
								element: (
									<GuardRoute validate={validateAction([PermissionActions.AdvertiserWrite])}>
										<CreateAdvertiser />
									</GuardRoute>
								),
							},
							{
								path: routes.accounts.editAdvertiser(':accountId', ':advertiserId'),
								element: (
									<GuardRoute validate={validateAction([PermissionActions.AdvertiserWrite])}>
										<EditAdvertiser />
									</GuardRoute>
								),
							},
						],
					},
					{
						path: routes.accounts.campaigns(':accountId'),
						element: (
							<GuardRoute validate={validateAction([PermissionActions.CampaignRead])}>
								<AccountCampaigns />
							</GuardRoute>
						),
					},
					{
						path: routes.accounts.channels(':accountId'),
						element: <AccountChannels />,
					},
				],
			},
		],
	},
];
