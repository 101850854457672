import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import styles from './drag-and-drop.module.scss';
import { Icon } from '../../icon/icon';
import { getTheme } from '@monorepo/tools/src/lib/get-config';

interface IDragAndDrop {
	onDrop: (files: File | FileList) => void;
	fileSize?: number;
	filesCountLimit?: number;
	fileFormat?: string;
	children: ReactNode;
	isMultiple?: boolean;
	onDragEnter?: () => void;
	onDragLeave?: () => void;
	isLoading?: boolean;
}

const theme = getTheme();

export const DragAndDrop = (props: IDragAndDrop) => {
	//uncomment this shit after using these vars
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { fileSize, filesCountLimit, fileFormat, children, onDrop, isMultiple, onDragEnter, onDragLeave, isLoading } = props;
	const drop = useRef<HTMLDivElement>(null);
	const drag = useRef<HTMLDivElement>(null);
	const [dragging, setDragging] = useState(false);
	const force = true;

	useEffect(() => {
		if (drop.current !== null) {
			drop.current.addEventListener('dragover', handleDragOver);
			drop.current.addEventListener('drop', handleDrop);
			drop.current.addEventListener('dragenter', handleDragEnter);
			drop.current.addEventListener('dragleave', handleDragLeave);
		}

		return () => {
			if (drop.current !== null) {
				drop.current.removeEventListener('dragover', handleDragOver);
				drop.current.removeEventListener('drop', handleDrop);
				drop.current.removeEventListener('dragenter', handleDragEnter);
				drop.current.removeEventListener('dragleave', handleDragLeave);
			}
		};
	}, []);

	const handleDragEnter = (e: DragEvent) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.target !== drag.current) {
			setDragging(true);

			onDragEnter && onDragEnter();
		}
	};

	const handleDragLeave = (e: DragEvent) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.target === drag.current) {
			setDragging(false);

			onDragLeave && onDragLeave();
		}
	};

	const handleDragOver = (e: DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: DragEvent) => {
		e.preventDefault();
		e.stopPropagation();

		setDragging(false);

		const files = e.dataTransfer ? [...e.dataTransfer.files] : [];

		if (files && files.length) {
			onDrop && onDrop(isMultiple ? (files as unknown as FileList) : files[0]);
		}
	};

	return (
		<div ref={drop} className={styles.refWrapper}>
			{
				<Fragment>
					{isLoading ? (
						<div className={styles.iconWrapper}>
							<Icon color={theme.primaryColor} size={'40px'} className={styles.loader} isMFP>
								loading-01
							</Icon>
							<span className={styles.loaderText}>This might take a minute.</span>
						</div>
					) : (
						children
					)}
				</Fragment>
			}
		</div>
	);
};
