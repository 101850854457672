import { AccountsEndpoint } from './accounts.endpoint';
import { AdvertisersEndpoint } from './advertisers.endpoint';
import { BulkCampaignsEndpoint } from './bulk-campaigns.endpoint';
import { CampaignsEndpoint } from './campaigns.endpoint';
import { ChannelsEndpoint } from './channels.endpoint';
import { ReviewCampaignsEndpoint } from './review-campaigns.endpoint';
import { UsersEndpoint } from './users.endpoint';
import { SourcesEndpoint } from './sources.endpoint';

const endpoints = {
	campaigns: new CampaignsEndpoint(),
	advertisers: new AdvertisersEndpoint(),
	accounts: new AccountsEndpoint(),
	channels: new ChannelsEndpoint(),
	sources: new SourcesEndpoint(),
	users: new UsersEndpoint(),
	bulkCampaigns: new BulkCampaignsEndpoint(),
	reviewCampaigns: new ReviewCampaignsEndpoint(),
};

export default endpoints;
