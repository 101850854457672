import { IQueryParams } from '../interfaces/url';

export const isValidUrl = (url: string) => {
	try {
		// if (!url.includes('http')) {
		// 	url = 'https://' + url;
		// }
		new URL(url);
	} catch (e) {
		return false;
	}
	return true;
};

// IIFE to make regexChecks only available to the function without creating them on every call
export const whatIsWrongWithMyUrl = (() => {
	const regexChecks = {
		http: new RegExp('^(http://)', 'i'),
		https: new RegExp('^(https://)', 'i'),
		www: new RegExp('^www.', 'i'),
		/* tslint:disable */
		url: new RegExp(
			'^(?:(?:(?:https):)?\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z0-9\\u00a1-\\uffff][a-z0-9\\u00a1-\\uffff_-]{0,62})?[a-z0-9\\u00a1-\\uffff]\\.)+(?:[a-z\\u00a1-\\uffff]{2,}\\.?))(?::\\d{2,5})?(?:[/?#]\\S*)?$',
			'i'
		),
	};

	return function isValid(url: string) {
		//TODO: add more url tests
		try {
			if (regexChecks.http.test(url)) {
				return {
					error: 'HTTP urls are not supported please specify an HTTPS address',
				};
			}
			url = url.replace('https://', '');
			url = 'https://' + url;

			if (!regexChecks.url.test(url)) {
				return {
					error: 'Invalid URL',
				};
			}

			new URL(url);

			return {
				validatedUrl: url,
			};
		} catch (e) {
			return {
				error: 'Please provide a valid url',
			};
		}
	};
})();

export const queryParamsToSearchParams = (queryParams?: IQueryParams): URLSearchParams => {
	const params: URLSearchParams = new URLSearchParams();
	if (queryParams) {
		Object.entries(queryParams).forEach(keyValue => {
			const [key, value] = keyValue;
			if (value) {
				params.append(key, value.toString());
			}
		});
	}

	return params;
};

export function getUrlLevelUp({ pathname, levelsUp = 1 }: { pathname: string; levelsUp?: number }): string {
	const parts = pathname.split('/');
	let levelUp = parts.splice(0, parts.length - (levelsUp < 1 ? 1 : levelsUp)).join('/');

	if (!levelUp.length) {
		levelUp = '/';
	}
	return levelUp;
}
