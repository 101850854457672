import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { CSSProperties, ReactElement, ReactNode } from 'react';
import { Icon } from '../../icon/icon';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import styles from './input-item.module.scss';

interface IInputItem {
	children: ReactNode;
	flex?: boolean;
	max?: boolean;
	id?: string;
	maxWidth?: string;
	alignItems?: string;
	gap?: string;
	flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
	debugProps?: IDebugProps;
}
export const InputItem = (props: IInputItem) => {
	const { children, id, flex = true, max = false, maxWidth, alignItems, flexDirection, gap, debugProps } = props;
	const { dataAttrs } = debugProps || {};

	const style: CSSProperties = {};
	if (maxWidth) {
		style.maxWidth = maxWidth;
	}

	if (alignItems) {
		style.alignItems = alignItems;
	}

	if (flexDirection) {
		style.flexDirection = flexDirection;
	}

	if (gap) {
		style.gap = gap;
	}

	return (
		<div
			id={id}
			className={`${styles.inputItem} ${!flex ? styles.disableFlex : ''} ${max ? styles.max : ''}`}
			style={{ ...style }}
			{...generateDataAttrs(suffixToDataAttr('_input_item', dataAttrs))}>
			{children}
		</div>
	);
};

interface IInputInfo {
	children: ReactNode;
	className?: string;
}
export const InputInfo = (props: IInputInfo) => {
	const { children, className } = props;
	return <div className={`${styles.inputInfo} ${className}`}>{children}</div>;
};

interface IInputTitle {
	children: ReactNode;
	debugProps?: IDebugProps;
}
export const InputTitle = (props: IInputTitle) => {
	const { children, debugProps } = props;
	const { dataAttrs } = debugProps || {};

	return (
		<div className={styles.inputTitle} {...generateDataAttrs(dataAttrs)}>
			{children}
		</div>
	);
};

interface IInputDesc {
	children: ReactNode;
}
export const InputDesc = (props: IInputDesc) => {
	const { children } = props;
	return <div className={styles.inputDesc}>{children}</div>;
};

interface IInputTooltip {
	content: ReactElement | string;
	size?: string;
	color?: string;
}
export const InputTooltip = (props: IInputTooltip) => {
	const { content, size = '16px', color = '#98A2B3' } = props;
	return (
		<Tooltip content={content}>
			<Icon isMFP={true} size={size} color={color}>
				help-circle
			</Icon>
		</Tooltip>
	);
};
