import { Menu } from '@monorepo/base/src/components/menu/menu';
import { Directions, SecondaryButton, SecondaryText, PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { useRef } from 'react';
import DateRangePicker from '@monorepo/base/src/components/dater/internal-components/DateRangePicker';
import styles from './dater.module.scss';
import { IDateSelection } from '@monorepo/controlled/src/stores/date.store';
import { SelectionRanges, IDaterOptions } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { CalendarIcon } from '../../../assets/calendar.icon';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

const theme = getTheme();

interface IDater {
	setOpen: (isOpen: boolean) => void;
	isOpen: boolean;
	dateRange: IDateSelection;
	handleSelect: (ranges: SelectionRanges, options: IDaterOptions) => void;
	onApply: () => void;
	dateLabel?: string;
	fromDate?: string;
	toDate?: string;
	minDate?: Date;
	maxDate?: Date;
}

export const Dater = (props: IDater) => {
	const { dateLabel, fromDate, toDate, minDate, maxDate, setOpen, isOpen, dateRange, handleSelect, onApply } = props;
	const daterRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(daterRef, () => {
		setOpen(false);
	});

	return (
		<div ref={daterRef}>
			<div className={styles.btnWrapper}>
				<span className={styles.label}>{dateLabel}</span>
				<SecondaryButton
					debugProps={{ dataAttrs: [new DataAttribute('id', 'dater_expend_button')] }}
					icon={'arrow-dropdown'}
					iconColor={theme.primaryColor}
					iconDirection={Directions.FromRight}
					className={styles.btn}
					onClick={() => setOpen(!isOpen)}>
					<CalendarIcon className={styles.calendarIcon} /> &nbsp;
					{fromDate} - {toDate}
				</SecondaryButton>
			</div>

			<Menu isOpen={isOpen} alignToRight={true}>
				<DateRangePicker
					ranges={[dateRange]}
					onChange={handleSelect}
					months={1.1}
					scroll={{ enabled: true }}
					direction={'vertical'}
					minDate={minDate}
					maxDate={maxDate}
					color={theme.primaryColor}
					rangeColors={[theme.primaryColor]}
				/>
				<div className={styles.actions}>
					<SecondaryText
						className={styles.cancelBtn}
						onClick={() => setOpen(false)}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'dater-menu-cancel')] }}>
						Cancel
					</SecondaryText>
					<SpacerX x={0.5} />
					<PrimaryButton
						className={styles.applyBtn}
						onClick={onApply}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'dater_menu_apply')] }}>
						Apply
					</PrimaryButton>
				</div>
			</Menu>
		</div>
	);
};
