import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { action, makeAutoObservable, observable } from 'mobx';
import { ChannelsApi } from '../../apis/channels.api';
import { filterPrototypesByProp } from '../../components/filters';
import { IPerformanceOptions, PerformancesColumns } from '../../hooks/apis/use-reports-list';
import { ChannelModel } from '../../models/channel.model';
import { PerformancePayload, PerformanceModel } from '../../models/performance.model';

// every enum here is the path withtout / and ids
// for example, advertiser/1/ will transform to ChannelFiltersStoreKeys.Advertiser
export enum ChannelFiltersStoreKeys {
	Campaign = 'campaignschannels',
	Advertiser = 'advertiserschannels',
	Account = 'accountschannels',
}

export class ChannelStore {
	// list = new BaseListStore<ChannelModel>({
	// 	listFunc: ChannelsApi.list,
	// 	model: ChannelModel,
	// });
	reports = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: ChannelsApi.reports,
		model: PerformancePayload,
	});
	metrics = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: ChannelsApi.metrics,
		model: PerformancePayload,
	});

	insights = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: ChannelsApi.insights,
		model: PerformancePayload,
	});

	settingsStore: SettingsStore;

	segments: Set<ISegment<ChannelModel, PerformanceModel, PerformancesColumns>>;

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this, {
			// list: observable,
			metrics: observable,
			insights: observable,
			segments: observable,
			setSegments: action.bound, // https://mobx.js.org/actions.html#actionbound prevents cannot set property of undefined
		});
		this.segments = new Set<ISegment<ChannelModel, PerformanceModel, PerformancesColumns>>([]);
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		Object.values(ChannelFiltersStoreKeys).map((key: ChannelFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const advertiserFilterByKey = new FilterStore({
				key,
				currentFilters: undefined,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, advertiserFilterByKey);
		});
	}

	// getListStore() {
	// 	return this.list;
	// }

	getReportsStore() {
		return this.reports;
	}

	getMetricsStore() {
		return this.metrics;
	}
	getInsightsStore() {
		return this.insights;
	}

	setSegments(segments: Set<ISegment<ChannelModel, PerformanceModel, PerformancesColumns>>): void {
		this.segments = segments;
	}

	getSegments(): Set<ISegment<ChannelModel, PerformanceModel, PerformancesColumns>> {
		return this.segments;
	}
}
