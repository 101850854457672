import { observer } from 'mobx-react';
import { FC } from 'react';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useAdvertiserActions } from '../../../modules/hooks/actions/advertiser.actions';
import { Outlet } from 'react-router-dom';
import { useAdvertiserHeaders } from '../../../modules/hooks/theaders/advertisers.headers';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { IMFPStore } from '../../../modules/stores';
import { Entities, PerformancesColumns, useReportsList } from '../../../modules/hooks/apis/use-reports-list';
import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import routes from '../../../modules/routes/index.routes';
import styles from './../../../styles/pages.module.scss';
import { useMetricsGet } from '../../../modules/hooks/tools/use-metrics-get';
import { useMetrics } from '../../../modules/hooks/tools/use-metrics';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { AdvertiserFiltersStoreKeys } from '../../../modules/stores/advertisers/advertiser.store';
import { availablePerformanceToMetrics } from '../../../modules/models/performance.model';
import { useStateBetweenPages } from '@monorepo/tools/src/lib/hooks/tools/use-state-between-pages';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ManifestoChart } from '../../../modules/components/chart/manifesto-chart';
import { Helmet } from 'react-helmet-async';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { PaginationState } from '@tanstack/react-table';
import { isFetchFromBackend } from '../../../modules/helpers/reports.helpers';

const actionsDebugProps = { dataAttrs: [new DataAttribute('id', 'advertisers_actions')] };
const tableDebugProps = { dataAttrs: [new DataAttribute('id', 'advertisers_table')] };

export const Advertisers: FC = observer(() => {
	const { advertiserStore } = useStores<IMFPStore>();
	const metricsStore = advertiserStore.getMetricsStore();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const reportsStore = advertiserStore.getReportsStore();
	const reportData = reportsStore.getData();
	const stateBetweenPages = useStateBetweenPages(() => Boolean(reportData?.getData()) && reportData?.getData().length !== 0);

	const { reportsError, fetchReports } = useReportsList(
		reportsStore,
		{
			entity: Entities.Advertisers,
			groupBys: [PerformancesColumns.AdvertiserId],
		},
		stateBetweenPages,
		advertiserStore.getSegments()
	);

	const { metricsError, fetchMetrics } = useMetricsGet(
		metricsStore,
		{
			entity: Entities.Chart,
			groupBys: [PerformancesColumns.Date],
		},
		stateBetweenPages
	);

	const { SelectedActions, EntityActions, FiltersActions, SegmentActions } = useAdvertiserActions({
		currentSegments: advertiserStore.getSegments(),
		debugProps: actionsDebugProps,
	});
	const { columns } = useAdvertiserHeaders({ EntityActions, summary: reportData?.getSummary() });

	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page unstyled={true} className={styles.article}>
			<Helmet>
				<title>Manifesto - Advertisers</title>
			</Helmet>
			<Pagebar classes={{ pagebar: styles.padding }} debugProps={{ dataAttrs: [new DataAttribute('id', 'pagebar')] }}>
				<PagebarTitle>Advertisers</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
					<PrimaryLink
						icon={'plus'}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'create_advertiser')] }}
						to={`${routes.advertisers.create()}`}
						width={'160px'}
						state={{ isBlockRequest: true } as BetweenPagesStateProps}>
						New Advertiser
					</PrimaryLink>
				</PagebarActions>
			</Pagebar>
			<ManifestoChart
				debugProps={{ dataAttrs: [new DataAttribute('id', 'chart')] }}
				isLoading={metricsStore.getIsLoading()}
				metrics={metrics}
				labels={xLabels}
				legendOptions={Object.keys(availablePerformanceToMetrics)}
				isError={Boolean(metricsError)}
			/>
			<SpacerY y={2} />
			<ControlledTable
				totalRows={reportData?.getTotalRows() ? reportData?.getTotalRows()?.toLocaleString() : undefined}
				totalFilteredRows={reportData?.getTotalFilteredRows() ? reportData?.getTotalFilteredRows()?.toLocaleString() : undefined}
				columns={columns}
				classes={{ wrapper: styles.padding }}
				data={reportData?.getData() || []}
				debugProps={tableDebugProps}
				isLoading={reportsStore.getIsLoading()}
				isWithPerformance={true}
				isLoadingPerformance={reportsStore.getIsLoading()}
				defaultSortBy={[{ id: 'revenue', desc: true }]}
				isError={Boolean(reportsError)}
				fetchReports={fetchReports}
				fetchMetrics={fetchMetrics}
				onPageChange={(state: PaginationState) => {
					if (isFetchFromBackend(reportData, state) && !reportsStore.getIsLoading()) {
						fetchReports({ appendData: true, resetOffset: false });
					}
				}}
				currentRoute={
					currentRouteWithoutSpecialChars === 'advertiserscreate' || currentRouteWithoutSpecialChars === 'advertisersedit'
						? ('advertisers' as AdvertiserFiltersStoreKeys)
						: (currentRouteWithoutSpecialChars as AdvertiserFiltersStoreKeys)
				}>
				{({ sortedRows }) => {
					advertiserStore.setSortedRows(sortedRows);
					return {
						SelectedActions,
						FiltersActions,
						SegmentActions,
					};
				}}
			</ControlledTable>
			<Outlet />
		</Page>
	);
});
