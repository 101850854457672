import { HttpStore } from '@monorepo/controlled/src/stores/http.store';

interface IUseDownload {
	httpStore: HttpStore<undefined, Blob | null>;
	fileName: string;
	extension: string;
}

export const useDownload = (props: IUseDownload) => {
	const { httpStore, fileName, extension } = props;

	httpStore.fetch(undefined).then((blob: Blob | null) => {
		if (blob !== null) {
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${fileName}.${extension}`);
			link.style.display = 'none';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	});
};
