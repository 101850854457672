import { Divider } from '@monorepo/base/src/components/divider/divider';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { Fragment, useEffect, useState } from 'react';
import { CampaignDelivery } from './delivery/campaign-delivery';
import { CampaignProfile } from './profile/campaign-profile';
import { CampaignTargeting } from './targeting/campaign-targeting';
import { CampaignStrategy } from './strategy/campaign-strategy';
import { SiderSlider } from '@monorepo/base/src/components/sider-slider/sider-slider';
import { AdvertiserForm } from '../advertiser/advertiser.form';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { ErrorMsg } from '../../error-msg/error-msg';
import { PrimaryButton, SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import styles from './campaign-form.module.scss';
import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../../stores';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { AdvertiserModel } from '../../../models/advertiser.model';
import { useList } from '../../../hooks/use-list';

export const SpaceBetweenFormControls = () => {
	return (
		<Fragment>
			<SpacerY />
			<Divider />
			<SpacerY y={1.5} />
		</Fragment>
	);
};

export const SpaceBetweenInputControls = () => {
	return <SpacerY y={2} />;
};

export const toolTipDisabledContent = 'This property can not be changed while a campaign has been published';

export const CampaignForm = observer(() => {
	const { advertiserStore, campaignStore, accountStore } = useStores<IMFPStore>();
	const { dispatchLog } = useLogs();
	const [isSiderSlider, setSiderSlider] = useState<boolean>(false);
	const [isSiderSliderTouched, setIsSiderSliderTouched] = useState<boolean>(false);
	const advertiserCrudStore = advertiserStore.getCrud();
	const campaignCrudStore = campaignStore.getCrud();
	const isSuccess = advertiserCrudStore.getIsSuccess();
	const error = advertiserCrudStore.getHttpError();

	useList(accountStore.getListStore()); // Because there are no breadcrumbs in create/edit campaign layout

	// const advertiser = advertiserStore.getCrud().getData();

	useEffect(() => {
		if (isSuccess) {
			onCloseAdvertiser();
			dispatchLog({
				msg: `Advertiser ${name} was successfully added`,
				type: DispatchLogsTypes.Success,
			});
			advertiserCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onAddAdvertiser = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		campaignCrudStore.getData().setAdvertiser(new AdvertiserModel());
		advertiserCrudStore.setData(new AdvertiserModel());
		setIsSiderSliderTouched(true);
		setSiderSlider(true);
	};

	const _onAdvertiserSelect = () => {
		setSiderSlider(false);
	};

	const onCloseAdvertiser = () => {
		setSiderSlider(false);
		advertiserCrudStore.reset();
	};

	const onCreateAdvertiser = async () => {
		advertiserStore
			.getCrud()
			.create()
			.then(_advertiser => {
				if (_advertiser) {
					const advertiserObj = new AdvertiserModel(_advertiser);
					campaignCrudStore.getData().setAdvertiser(advertiserObj);
					advertiserStore.getListStore().setList([...advertiserStore.getListStore().getList(), advertiserObj]);
				}
			});
	};

	return (
		<Fragment>
			<SiderSlider
				isOverlay={false}
				isScroll={true}
				isOpen={isSiderSlider}
				onClose={onCloseAdvertiser}
				isDisableCloseAnimation={!isSiderSlider && !isSiderSliderTouched}>
				<Page unstyled className={styles.wrapperSs}>
					<Pagebar>
						<PagebarTitle classes={{ title: styles.titleSs }} unstyled>
							Create new Advertiser
						</PagebarTitle>
					</Pagebar>
					<AdvertiserForm />
					<BottomBar
						classes={{ barClassses: styles.advertiserBottomBarSs, wrapperClassses: styles.advertiserBottomBarWrapperSs }}>
						{error ? <ErrorMsg error={error} /> : null}
						<BottomBarActions>
							<SecondaryButton onClick={() => onCloseAdvertiser()}>Cancel</SecondaryButton>
							<PrimaryButton onClick={() => onCreateAdvertiser()}>Save</PrimaryButton>
						</BottomBarActions>
					</BottomBar>
				</Page>
			</SiderSlider>
			<CampaignProfile onCreateAdvertiser={onAddAdvertiser} onAfterAdvertiserSelect={_onAdvertiserSelect} />
			<Divider />
			<SpacerY y={1.5} />

			<CampaignDelivery />
			<Divider />
			<SpacerY y={1.5} />

			<CampaignTargeting />
			<Divider />
			<SpacerY y={1.5} />

			<CampaignStrategy />
			<Divider />
		</Fragment>
	);
});
