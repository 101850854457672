import { CSSProperties } from 'react';
import styles from './divider.module.scss';

// TODO - DividerY

interface IDivider {
	classNames?: string;
}

export const Divider = (props: IDivider) => {
	const { classNames } = props;
	return <div className={`${styles.wrapper} ${classNames}`}></div>;
};

interface IDividerX {
	height?: string;
	marginRight?: string;
	marginLeft?: string;
	alignSelf?: string;
	color?: string;
	width?: string;
	dotted?: boolean;
	dashed?: boolean;
}

export const DividerX = (props: IDividerX) => {
	const { height, marginRight, marginLeft, alignSelf, color, dotted, width, dashed } = props;
	const style: CSSProperties = {};
	if (height) {
		style.height = height;
	}
	if (width) {
		style.width = width;
	}
	if (marginRight) {
		style.marginRight = marginRight;
	}
	if (marginLeft) {
		style.marginLeft = marginLeft;
	}
	if (alignSelf) {
		style.alignSelf = alignSelf;
	}
	if (color) {
		style.color = color;
	}
	if (dashed || dotted) {
		if (width) {
			style.borderWidth = width;
		}
		if (color) {
			style.borderColor = color;
		}
	}
	if (dashed) {
		style.borderStyle = 'dashed';
	}
	if (dotted) {
		style.borderStyle = 'dotted';
	}
	return <div className={`${styles.vertical} ${dotted ? styles.dotted : ''} ${dashed ? styles.dashed : ''}`} style={{ ...style }}></div>;
};
