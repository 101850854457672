import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect } from 'react';
import { PrimaryButton, SecondaryButton, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { Nav, NavPage } from '@monorepo/base/src/components/nav/nav';
import { route } from '@monorepo/tools/src/lib/types/url';
import { IMFPStore } from '../../../modules/stores';
import { CampaignForm } from '../../../modules/components/forms/campaign/campaign.form';
import { useCampaignTableOfContents } from '../../../modules/hooks/toc/campaign.toc';
import { TableOfContents } from '@monorepo/base/src/components/table-of-contents/table-of-contents';
import { useLayoutContext } from '../../../modules/hooks/useLayoutContext';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { useList } from '../../../modules/hooks/use-list';
import styles from '../../../modules/components/forms/campaign/campaign-form.module.scss';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useCampaignGet } from '../../../modules/hooks/apis/use-campaign-get';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { Helmet } from 'react-helmet-async';

interface IDuplicateCampaign {
	parentRoute?: route;
}

const genNextCampaignName = (str: string): string => {
	return `${str} Copy`;
};

export const DuplicateCampaign = observer((props: IDuplicateCampaign) => {
	const { parentRoute } = props;
	const { campaignStore, advertiserStore } = useStores<IMFPStore>();
	const { dispatchLog } = useLogs();
	const { setIsTableOfContents } = useLayoutContext();
	const campaignCrudStore = campaignStore.getCrud();
	const { tableOfContents } = useCampaignTableOfContents();
	const campaign = campaignCrudStore.getData();
	// Fixes not rerendering table of content
	campaignCrudStore.getFormStore().getErrors();

	useList(advertiserStore.getListStore());

	const navigate = useNavigateParams();
	const params = useParams();
	if (params.campaignId) {
		useCampaignGet({ id: params.campaignId });
	}

	useEffect(() => {
		campaign.id = undefined;
		const campaignName = campaign.getName();
		if (campaignName) {
			campaign.setName(genNextCampaignName(campaignName));
		}
	}, [campaign]);

	useEffect(() => {
		return () => campaignCrudStore.reset();
	}, [location]);

	useEffect(() => {
		if (parentRoute) {
			setIsTableOfContents(true);
		}
	}, []);

	const name = campaign.getName();
	const isLoading = campaignCrudStore.getIsLoading();
	const isSuccess = campaignCrudStore.getIsSuccess();
	const isSavedToDraft = campaignCrudStore.getIsEdited();

	useEffect(() => {
		if (isSuccess) {
			if (!isSavedToDraft) {
				navigate(-1);
			}
			dispatchLog({
				msg: `Campaign ${name} was successfully ${isSavedToDraft ? 'saved to draft' : 'added'}`,
				type: DispatchLogsTypes.Success,
			});
			campaignCrudStore.setIsSuccess(false);
		}
	}, [isSuccess, isSavedToDraft]);

	const onPublishCampaign = () => {
		campaignCrudStore.setIsEdited(false);
		campaignCrudStore.create();
	};

	return (
		<Disable is={isLoading}>
			<Helmet>
				<title>Duplicate Campaign {campaign.getName() || ''}</title>
			</Helmet>
			<BottomBar>
				<Flex height={'100%'}>
					<SecondaryText onClick={() => navigate(-1)} icon={'chevron-left'} iconSize={'20px'} width={'70px'}>
						Back
					</SecondaryText>
					<BottomBarActions>
						<Flex gap={'16px'}>
							<SecondaryButton
								isRotateIcon={!isSavedToDraft && isLoading}
								icon={!isSavedToDraft && isLoading ? 'sync' : ''}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'save_draft_campaign_button')] }}
								onClick={() => campaignCrudStore.saveDraft()}>
								Save Draft
							</SecondaryButton>
							<PrimaryButton
								onClick={() => onPublishCampaign()}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'publish_campaign_button')] }}>
								Create
							</PrimaryButton>
						</Flex>
					</BottomBarActions>
				</Flex>
			</BottomBar>
			<NavPage>
				<Nav classes={{ nav: styles.tableOfContentsWrapper }}>
					<TableOfContents
						heads={tableOfContents}
						offset={100}
						observerOptions={{ threshold: 0.1, rootMargin: '-70px 0px -50% 0px', intersectionRatio: 0.1 }}
					/>
				</Nav>
				<Page>
					<Pagebar>
						<PagebarTitle>Create a campaign</PagebarTitle>
					</Pagebar>
					<CampaignForm />
				</Page>
			</NavPage>
		</Disable>
	);
});
