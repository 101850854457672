import { getConfig } from '@monorepo/tools/src/lib/get-config';
import { CrudEndpoint } from './crud.endpoint';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { url } from '@monorepo/tools/src/lib/types/url';

export class UsersEndpoint extends CrudEndpoint {
	sherrifRoute: string;
	bouncerRoute: url;

	constructor() {
		super('users');
		this.sherrifRoute = getConfig().env.sheriffRoot;
		this.bouncerRoute = `${this.sherrifRoute}/bouncer/${this.component}`;
	}

	get(id: id) {
		return `${this.sherrifRoute}/bouncer/${this.component}/${id}`;
	}

	profile() {
		// Token necessary
		return `${this.sherrifRoute}/bouncer/profile/me`;
	}

	create() {
		return `${this.sherrifRoute}/bouncer/${this.component}`;
	}

	signUp() {
		return `${this.sherrifRoute}/bouncer/sign/${this.component}/up`;
	}

	signIn() {
		return `${this.sherrifRoute}/bouncer/sign/${this.component}/in`;
	}

	verify(token: string) {
		return `${this.sherrifRoute}/bouncer/${this.component}/verify/${token}`;
	}

	sendVerifyEmail() {
		return `${this.sherrifRoute}/bouncer/${this.component}/send-verify-email`;
	}

	validate2fa() {
		return `${this.sherrifRoute}/bouncer/${this.component}/validate2fa`;
	}
}
