import { IEntityFilters } from '.';

export const performanceFilters: IEntityFilters = {
	numberFilters: {
		clicks: { columnName: 'Clicks', cellKey: 'clicks' },
		conversions: { columnName: 'Conversions', cellKey: 'conversions' },
		impressions: { columnName: 'Impressions', cellKey: 'impressions' },
		cost: { columnName: 'Cost', cellKey: 'cost' },
		revenue: { columnName: 'Revenue', cellKey: 'revenue' },
		roas: { columnName: 'Roas', isPercentage: true, cellKey: 'roas' },
		cpa: { columnName: 'CPA', cellKey: 'cpa' },
		cpc: { columnName: 'CPC', cellKey: 'cpc' },
		cpm: { columnName: 'CVM', cellKey: 'cpm' },
		cvr: { columnName: 'CVR', cellKey: 'cvr' },
		epc: { columnName: 'EPC', cellKey: 'epc' },
		conversion_val: { columnName: 'Conversion Value', isPercentage: true, cellKey: 'conv_val' },
		win_rate: { columnName: 'Win Rate', cellKey: 'win_rate' },
	},

	stringFilters: {},

	enumFilters: {},
};
