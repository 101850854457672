import { action, makeObservable, observable } from 'mobx';
import { AccountsApi } from '../../apis/accounts.api';
import { AccountModel } from '../../models/account.model';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { AccountCrudStore } from './account-crud.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { IPerformanceOptions, PerformancesColumns } from '../../hooks/apis/use-reports-list';
import { PerformancePayload, PerformanceModel } from '../../models/performance.model';
import { FilterModel } from '@monorepo/controlled/src/models/filter.model';
import { capitalCase } from 'change-case';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { filterPrototypesByProp } from '../../components/filters';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { StatusEnum } from '../../enums/status';
import { StatusFilterPrototype } from '../../components/filters/status.filter';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { Row } from '@tanstack/react-table';

export enum AccountFiltersStoreKeys {
	Accounts = 'accounts',
	Clicks = 'clicksaccounts',
}

export class AccountStore {
	crud = new AccountCrudStore();
	list = new BaseListStore<AccountModel>({
		listFunc: AccountsApi.list,
		model: AccountModel,
	});
	reports = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: AccountsApi.reports,
		model: PerformancePayload,
	});
	metrics = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: AccountsApi.metrics,
		model: PerformancePayload,
	});
	sortedRows: Row<AccountModel>[] = [];
	settingsStore: SettingsStore;
	segments: Set<ISegment<AccountModel, PerformanceModel, PerformancesColumns>>;

	constructor(settingsStore: SettingsStore) {
		makeObservable(this, {
			crud: observable,
			list: observable,
			segments: observable,
			setSegments: action.bound, // https://mobx.js.org/actions.html#actionbound prevents cannot set property of undefined
		});

		this.segments = new Set<ISegment<AccountModel, PerformanceModel, PerformancesColumns>>([]);
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		// set filters for all campaigns pages
		const defaultFilters = new Map([
			[
				1,
				new FilterModel({
					index: 1,
					label: `Status is ${capitalCase(StatusEnum.ACTIVE)}`,
					value: StatusEnum.ACTIVE,
					prototype: StatusFilterPrototype,
				}),
			],
		]);

		Object.values(AccountFiltersStoreKeys).map((key: AccountFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const advertiserFilterByKey = new FilterStore({
				key,
				currentFilters: defaultFilters,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, advertiserFilterByKey);
		});
	}

	getListStore() {
		return this.list;
	}

	getCrud() {
		return this.crud;
	}

	getReportsStore() {
		return this.reports;
	}

	getMetricsStore() {
		return this.metrics;
	}

	setSegments(segments: Set<ISegment<AccountModel, PerformanceModel, PerformancesColumns>>): void {
		this.segments = segments;
	}

	getSegments(): Set<ISegment<AccountModel, PerformanceModel, PerformancesColumns>> {
		return this.segments;
	}
	getSortedRows(): Row<AccountModel>[] {
		return this.sortedRows;
	}

	setSortedRows(sortedRows: Row<AccountModel>[]): void {
		this.sortedRows = sortedRows;
	}
}
