import { PrimaryText } from '@monorepo/base/src/components/buttons/buttons';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { InputInfo, InputItem, InputTitle, InputTooltip } from '@monorepo/base/src/components/form/input-item/input-item';
import { IInput, Input } from '@monorepo/base/src/components/form/input/input';
import { Radio } from '@monorepo/base/src/components/form/radio/radio';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { Text } from '@monorepo/base/src/components/text/text';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { observer } from 'mobx-react';
import { Fragment, memo, useState } from 'react';
import { ChannelModel, ChannelsEnums } from '../../../models/channel.model';
import styles from './campaign-form.module.scss';

interface ICampaignFormCommon extends IInput {
	setValue: (value: string) => void;
	value?: string;
	errorMsg?: string;
	afterTxt?: string;
	afterTooltip?: string;
}

export const BudgetInput = (props: ICampaignFormCommon) => {
	const { value, setValue, errorMsg, debugProps, afterTooltip, afterTxt = 'USD', placeholder = 'Unlimited', ...rest } = props;

	return (
		<Input
			before={'$'}
			after={
				<Flex alignSelf={'center'}>
					<Text color={getTheme().titleColor}>{afterTxt}</Text>
					<SpacerX />
					{afterTooltip && <InputTooltip content={afterTooltip} />}
				</Flex>
			}
			type={'positiveNumber'}
			step={'0.1'}
			className={styles.inputWrapper}
			placeholder={placeholder}
			value={value}
			onValue={setValue}
			required={true}
			requiredAsterisk={false}
			error={errorMsg}
			{...rest}
			debugProps={debugProps}
		/>
	);
};

interface IChannel {
	name: ChannelsEnums;
	hasChannel: boolean;
	onAdd: (name: ChannelsEnums, isActive: boolean) => void;
	channel?: ChannelModel;
	bidErrorMsg?: string;
	dailyBudgetErrorMsg?: string;
	onValue?: () => void;
	suggestedBid?: number;
}

const _Channel = observer((props: IChannel) => {
	const { name, channel, onAdd, hasChannel, bidErrorMsg, dailyBudgetErrorMsg, onValue, suggestedBid } = props;

	const [isChannel, setIsChannel] = useState<boolean>(Boolean(hasChannel));
	const [isChannelCaps, setIsChannelCaps] = useState<boolean>(Boolean(channel?.getClickCap() || channel?.getConvCap()));

	const onToggleChannel = (_isActive: boolean) => {
		setIsChannel(_isActive);
		onAdd(name, _isActive);
	};

	return (
		<InputItem maxWidth={'1200px'}>
			<InputInfo className={styles.inputInfo}>
				<Flex gap={'16px'} justifyContent={'flex-start'}>
					<Radio
						isActive={isChannel}
						multi={true}
						onChecked={_isActive => onToggleChannel(_isActive)}
						debugProps={{
							dataAttrs: [
								new DataAttribute('id', `${name.toLowerCase().replace(' ', '_').replace('/', '_')}_channel_button`),
							],
						}}
					/>
					<InputTitle>{name}</InputTitle>
					<div>
						<InputTooltip content={'This is a tooltip'} />
					</div>
				</Flex>
			</InputInfo>
			<Disable is={!isChannel}>
				<Flex alignItems={'flex-start'} gap={'20px'}>
					<Input
						{...generateDataAttrs([
							new DataAttribute('id', `${name.toLowerCase().replace(' ', '_').replace('/', '_')}_channel_bid`),
						])}
						before={'$'}
						topLabel={'Bid'}
						bottomLabel={
							channel?.getSuggestedBid() ? `Suggested Bid: ${channel?.getSuggestedBid()}` : `Suggested Bid: ${suggestedBid}`
						}
						placeholder={channel?.getSuggestedBid() ? `${channel?.getSuggestedBid()}` : `${suggestedBid}`}
						after={
							<Flex>
								<Text color={getTheme().titleColor}>CPC</Text>
								<SpacerX />
								<InputTooltip content={'This is a tooltip'} />
							</Flex>
						}
						className={styles.inputWrapper}
						wrapperStyle={{ flex: '1 0 28%' }}
						type={'positiveNumber'}
						step={'0.1'}
						value={channel?.getDefaultBid()?.toString()}
						onValue={value => {
							channel?.setDefaultBid(parseFloat(value));
							onValue && onValue();
							// campaignCrudStore.forcePersist();
						}}
						required={true}
						requiredAsterisk={false}
						error={bidErrorMsg}
					/>
					<Input
						before={'$'}
						topLabel={'Channel daily budget'}
						step={'0.1'}
						type={'positiveNumber'}
						after={
							<Flex>
								<Text color={getTheme().titleColor}>USD</Text>
								<SpacerX />
								<InputTooltip content={'This is a tooltip'} />
							</Flex>
						}
						className={styles.inputWrapper}
						wrapperStyle={{ flex: '1 0 28%' }}
						placeholder={'Unlimited'}
						value={channel?.getDailyCap()?.toString()}
						onValue={value => {
							channel?.setDailyCap(parseFloat(value));
							// campaignCrudStore.forcePersist();
						}}
						required={true}
						requiredAsterisk={false}
						error={dailyBudgetErrorMsg}
						{...generateDataAttrs([
							new DataAttribute('id', `${name.toLowerCase().replace(' ', '_').replace('/', '_')}_channel_daily_budget`),
						])}
						debugProps={{ dataAttrs: [new DataAttribute('id', `${name}_daily_cap`)] }}
					/>
					{isChannelCaps ? (
						<Fragment>
							<Input
								topLabel={'Click Cap'}
								type={'positiveNumber'}
								className={styles.inputWrapper}
								placeholder={'Unlimited'}
								value={channel?.getClickCap()?.toString()}
								onValue={value => {
									channel?.setClickCap(parseInt(value));
								}}
								required={true}
								requiredAsterisk={false}
								{...generateDataAttrs([
									new DataAttribute('id', `${name.toLowerCase().replace(' ', '_').replace('/', '_')}_channel_click_cap`),
								])}
							/>
							<Input
								topLabel={'Conv. Cap'}
								type={'positiveNumber'}
								className={styles.inputWrapper}
								placeholder={'Unlimited'}
								value={channel?.getConvCap()?.toString()}
								onValue={value => {
									channel?.setConvCap(parseInt(value));
								}}
								required={true}
								requiredAsterisk={false}
								{...generateDataAttrs([
									new DataAttribute('id', `${name.toLowerCase().replace(' ', '_').replace('/', '_')}_channel_conv_cap`),
								])}
							/>
						</Fragment>
					) : (
						<div className={styles.addChannelcaps}>
							<PrimaryText
								icon={'plus'}
								onClick={() => setIsChannelCaps(true)}
								className={`${styles.button} ${!isChannel ? styles.disabledBtn : ''}`}
								debugProps={{
									dataAttrs: [
										new DataAttribute(
											'id',
											`${name.toLowerCase().replace(' ', '_').replace('/', '_')}_add_channel_caps`
										),
									],
								}}>
								Add channel caps
							</PrimaryText>
						</div>
					)}
				</Flex>
			</Disable>
		</InputItem>
	);
});
export const Channel = memo(_Channel);
