import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { number } from '@monorepo/tools/src/lib/utils/number';
import { Row } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { Icon } from '../icon/icon';
import styles from './entity-navigation.module.scss';

interface IEntityNavigation<T> {
	sortedRows: Row<T>[];
	entityId: id;
	entityName: string;
	navigationRouteFunc: (id: id) => string;
}

export const EntityNavigation = <T extends { id?: id }>(props: IEntityNavigation<T>) => {
	const { sortedRows, entityId, navigationRouteFunc, entityName } = props;
	const [currentCampaignIndex, setCurrentCampaignIndex] = useState<number>(0);
	const [clickable, setClickable] = useState<boolean>(true);
	const navigate = useNavigateParams();

	const delayClickable = () => {
		setClickable(false);
		setTimeout(() => setClickable(true), 1000);
	};

	useEffect(() => {
		setCurrentCampaignIndex(sortedRows?.findIndex(row => row.original.id == entityId) || 0);
	}, [sortedRows]);

	if (sortedRows.length === 0) {
		return null;
	}

	return (
		<div className={styles.prevNextWrapper}>
			<div className={styles.indexOfSum}>{`${number(currentCampaignIndex + 1, false, false, true)} of ${number(
				sortedRows.length,
				false,
				false,
				true
			)} ${entityName}`}</div>
			<Icon
				isMFP={true}
				className={`${styles.btn} ${currentCampaignIndex === 0 || !clickable ? styles.disabled : ''}`}
				onClick={() => {
					if (currentCampaignIndex !== 0 || clickable) {
						delayClickable();
						setCurrentCampaignIndex(prev => prev - 1);
						navigate(navigationRouteFunc(sortedRows[currentCampaignIndex - 1].original.id || 0));
					}
				}}>
				chevron-left
			</Icon>
			<Icon
				isMFP={true}
				className={`${styles.btn} ${currentCampaignIndex === sortedRows.length - 1 || !clickable ? styles.disabled : ''}`}
				onClick={() => {
					if (currentCampaignIndex < sortedRows.length - 1 || clickable) {
						delayClickable();
						setCurrentCampaignIndex(prev => prev + 1);
						navigate(navigationRouteFunc(sortedRows[currentCampaignIndex + 1].original.id || 0));
					}
				}}>
				chevron-right
			</Icon>
		</div>
	);
};
