import { FullResponse } from './../../../../tools/src/lib/interfaces/url';
import { UserModel, IUserCreateForm, IUserEditForm } from '../models/user.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IPerformanceOptions } from '../hooks/apis/use-reports-list';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { PerformancePayload } from '../models/performance.model';
import { ICreateRes } from '@monorepo/tools/src/lib/interfaces/crud';

export interface ICredentials {
	email: string;
	password: string;
	rememberMe?: boolean;
}
export interface IVerify {
	token: string;
	userId: string | number;
}

export const UsersApi = {
	list: (params?: URLSearchParams): Promise<UserModel[]> => {
		return ask.get(endpoints.users.list(), params);
	},
	reports: (params?: URLSearchParams): Promise<UserModel[]> => {
		return ask.get(endpoints.campaigns.reports(), params);
	},
	metrics: (params?: IPerformanceOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask.get(endpoints.campaigns.reports(), queryParamsToSearchParams({ p: hashParams })).then(res => res?.payload?.report);
	},
	create: (user: IUserCreateForm, options?: IRequestOptions): Promise<UserModel> => {
		return ask.post<IUserCreateForm>(endpoints.users.create(), user, options);
	},
	signUp: (user: IUserCreateForm, options?: IRequestOptions): Promise<{ body: IUserCreateForm; headers: string[][] }> => {
		return ask.postWithHeaders<IUserCreateForm>(endpoints.users.signUp(), user, options);
	},
	signIn: (signIn: ICredentials, options?: IRequestOptions): Promise<{ body: IUserCreateForm; headers: string[][] }> => {
		return ask.postWithHeaders<IUserCreateForm>(endpoints.users.signIn(), signIn, options);
	},
	validate2fa: (verify: IVerify, options?: IRequestOptions): Promise<{ body: IVerify; headers: string[][] }> => {
		return ask.postWithHeaders<IVerify>(endpoints.users.validate2fa(), verify, options);
	},
	edit: (userId: id, user: IUserEditForm, options?: IRequestOptions): Promise<null> => {
		return ask.patch<IUserEditForm>(endpoints.users.edit(userId), user, options);
	},
	get: (userId: id, options?: URLSearchParams): Promise<UserModel> => {
		return ask.get(endpoints.users.get(userId), options).then(res => res.payload.item);
	},
	profile: (options?: URLSearchParams): Promise<UserModel> => {
		return ask.get(endpoints.users.profile(), options).then(res => res.payload.item);
	},
	delete: (userId: id) => {
		return ask.delete(endpoints.users.delete(userId));
	},
	verify: (verifyToken: string, options?: IRequestOptions): Promise<FullResponse<ICreateRes<UserModel>>> => {
		return ask.postWithHeaders(endpoints.users.verify(verifyToken), {}, options);
	},
	sendVerifyEmail: (params: undefined, options?: IRequestOptions): Promise<FullResponse<void>> => {
		return ask.postWithHeaders<void>(endpoints.users.sendVerifyEmail(), params, options);
	},
	// login: (email: string, password: string) => {
	// 	return ask.post(endpoints.users.baseUrl + '/login', { email, password });
	// },
};
