import { Fragment } from 'react';
import { SelectedLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { QuickActionButton } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityActions, ITableActions, useDefaultActions } from './default.actions';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IMFPStore } from '../../stores/index';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { PerformanceModel } from '../../models/performance.model';
import { PerformancesColumns } from '../apis/use-reports-list';
import { channelFilterPrototypes } from '../../components/filters';
import { SourceModel } from '../../models/sources.model';
import { SourcesFiltersStoreKeys } from '../../stores/sources/source.store';

const theme = getTheme();

interface ISourcesActions {
	availableFilters?: IFilterProto[];
	currentSegments?: Set<ISegment<SourceModel, PerformanceModel, PerformancesColumns>>;
}

export const useSourcesActions = (props: ISourcesActions = {}) => {
	const { availableFilters } = props;
	const { settingsStore } = useStores<IMFPStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(currentRouteWithoutSpecialChars as SourcesFiltersStoreKeys);
	const currentFilters = filterStore?.getCurrentFilters();

	const DefaultAvailableFilters = [...channelFilterPrototypes];

	const SelectedActions = (props: ITableActions<SourceModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new SourceModel(row.original));
		const { EditAction, DeleteAction } = useDefaultActions({ entities });

		return (
			<Fragment>
				<SelectedLinerButton>
					<EditAction />
				</SelectedLinerButton>
				<SelectedLinerButton>
					<DeleteAction />
				</SelectedLinerButton>
			</Fragment>
		);
	};

	const EntityActions = (props: IEntityActions<SourceModel>) => {
		const { entity } = props;
		const { EditAction, DeleteAction } = useDefaultActions({ entities: [entity] });
		return (
			<Fragment>
				<QuickActionButton>
					<EditAction iconColor={theme.titleColor} textColor={theme.titleColor} />
				</QuickActionButton>
				<QuickActionButton>
					<DeleteAction iconColor={theme.titleColor} textColor={theme.titleColor} iconSize={'14px'} />
				</QuickActionButton>
			</Fragment>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={availableFilters || DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	return {
		SelectedActions,
		EntityActions,
		FiltersActions,
	};
};
