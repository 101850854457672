import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { makeAutoObservable, observable } from 'mobx';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { Row } from '@tanstack/react-table';
import { BulkCampaignsModel } from '../../../models/bulk-campaigns.model';
import { BulkCampaignsApi } from '../../../apis/bulk-campaigns.api';
import { BulkCampaignsCrudStore } from './bulk-campaign-crud.store';

export class BulkCampaignsStore {
	crud = new BulkCampaignsCrudStore();
	list = new BaseListStore<BulkCampaignsModel>({
		listFunc: BulkCampaignsApi.list,
		model: BulkCampaignsModel,
	});

	sortedRows: Row<BulkCampaignsModel>[] = [];

	settingsStore: SettingsStore;

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this, {
			crud: observable,
			list: observable,
			sortedRows: observable,
		});
		this.settingsStore = settingsStore;
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}

	getSortedRows(): Row<BulkCampaignsModel>[] {
		return this.sortedRows;
	}

	setSortedRows(sortedRows: Row<BulkCampaignsModel>[]): void {
		this.sortedRows = sortedRows;
	}
}
