import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { FormControl, FormTitle, FormDesc } from '@monorepo/base/src/components/form/form-control/form-control';
import { InputItem, InputInfo, InputTitle, InputDesc } from '@monorepo/base/src/components/form/input-item/input-item';
import { CampaignDomIds } from '../../../../hooks/toc/campaign.toc';
import countriesJson from '@monorepo/tools/src/lib/consts/geos.json';
import styles from '../campaign-form.module.scss';
import { SpaceBetweenFormControls, SpaceBetweenInputControls } from '../campaign.form';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { ManifestoGButton } from '../../../base-wrappers/g-button/g-button';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../../../stores';
import { observer } from 'mobx-react';
import { Devices } from '../../../../enums/devices';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { getKeyByValue } from '@monorepo/tools/src/lib/utils/object';

const campaignTargetingDebugProps = { dataAttrs: [new DataAttribute('id', 'campaign_targeting')] };

export const CampaignTargeting = observer(() => {
	const { campaignStore } = useStores<IMFPStore>();
	const campaignCrud = campaignStore.getCrud();
	const campaign = campaignCrud.getData();
	const formStore = campaignCrud.getFormStore();
	const defaultGeo = campaign?.getGeo() || 'US';
	const contriesObj = countriesJson as { [key: string]: string };

	return (
		<div {...generateDataAttrs([new DataAttribute('id', 'campaign_targeting_section')])}>
			<FormControl>
				<FormTitle id={CampaignDomIds.Targeting}>Campaign Targeting</FormTitle>
				<FormDesc>Specify the geographic locations, device types, and browser languages of the people you want to target</FormDesc>
			</FormControl>

			<SpaceBetweenFormControls />

			<InputItem id={CampaignDomIds.Devices}>
				<Flex alignItems={'flex-start'} justifyContent={'flex-start'}>
					<InputInfo className={styles.inputInfo}>
						<InputTitle>Device</InputTitle>
						<InputDesc>Select the types of devices you want to appear on</InputDesc>
					</InputInfo>
					<ButtonGroup
						gap={'34px'}
						required={true}
						multi
						defaultValues={campaign.getDevices()}
						error={formStore.getErrors().get(CampaignDomIds.Devices)?.getMsg()}
						onChange={setValues => {
							campaign.setDevices(setValues as Set<Devices>);
							formStore.clearError(CampaignDomIds.Devices);
						}}>
						<ManifestoGButton
							mini
							value={'Mobile'}
							title={'Mobile'}
							icon={'phone-android'}
							debugProps={campaignTargetingDebugProps}
						/>
						<ManifestoGButton
							mini
							value={'Desktop'}
							title={'Desktop'}
							icon={'monitor-01'}
							debugProps={campaignTargetingDebugProps}
						/>
					</ButtonGroup>
				</Flex>
			</InputItem>

			<SpaceBetweenInputControls />

			<InputItem id={CampaignDomIds.Geos}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Country</InputTitle>
					<InputDesc>Select the country you want to target</InputDesc>
				</InputInfo>
				<Dropdown
					defaultOption={contriesObj[defaultGeo]}
					required={true}
					requiredAsterisk={false}
					autocomplete={true}
					autocompletePlaceholder={'Search Country'}
					options={Object.values(contriesObj)}
					onSelect={(geos: Set<option> | undefined) => {
						const geo = getKeyByValue(contriesObj, geos?.values().next().value);
						campaign?.setGeo(geo);
						formStore.clearError(CampaignDomIds.Geos);
					}}
					error={formStore.getErrors().get(CampaignDomIds.Geos)?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'geos')] }}
				/>
			</InputItem>

			<SpaceBetweenInputControls />

			{/* <InputItem id={CampaignDomIds.BrowserLanguages}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Browser Language</InputTitle>
					<InputDesc>Choose the browser language(s) you want to target.</InputDesc>
				</InputInfo>
				<Dropdown
					defaultOptions={campaign?.getLanguages()}
					required={true}
					multi={true}
					requiredAsterisk={false}
					autocomplete={true}
					autocompletePlaceholder={'Search Browser Language'}
					options={['All', 'English', 'Spanish', 'French', 'German', 'Arabic']}
					onSelect={(languages: Set<option> | undefined) => {
						const lastBrowserLanguage = Array.from(languages || []).pop();
						// In case of selecting All remove all other options,
						// In case of selection geo when All is selected, remove All
						if (languages?.has('All') && languages.size > 1) {
							languages.delete('All');
							if (lastBrowserLanguage === 'All') {
								languages.clear();
								languages.add('All');
							}
						}
						campaign?.setLanguages(languages);
						formStore.clearError(CampaignDomIds.BrowserLanguages);
					}}
					disabled={isEditPage && !isDraftStatus}
					error={formStore.getErrors().get(CampaignDomIds.BrowserLanguages)?.getMsg()}
				/>
			</InputItem>

			<SpaceBetweenInputControls /> */}
		</div>
	);
});
